import { type CSSProperties } from 'react';
import {
  MdArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import * as SelectPrimitive from '@radix-ui/react-select';

import { type ISelectFilterData, type ISelectFilterListData } from '.';
import { BaseLoading } from '../BaseLoading';

export const Container = styled.div`
  background: ${colors.background};
  display: flex;
  flex-direction: column;
  height: 100%;

  & > button {
    min-width: 15rem;
  }
`;

export const Fieldset = styled.fieldset`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};
  }
`;

export const Input = styled.input`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
`;

export const ArrowUpContainer = styled(SelectPrimitive.ScrollUpButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 1.625rem;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
  height: 1rem;
  width: 1rem;
`;

export const Viewport = styled(SelectPrimitive.Viewport)`
  overflow: hidden;
  padding: 0.315rem;
`;

const ItemStyle = css`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.text};
  display: block;
  font-size: 1rem;
  line-height: 1.1;
  overflow-x: hidden;
  padding: 0.375rem 5px 0.375rem 25px;
  pointer-events: all;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 92%;
`;

export const Item = styled(SelectPrimitive.Item)`
  ${ItemStyle}

  &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
  }

  &[data-highlighted],
  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ItemScrolling = styled.div`
  ${ItemStyle}
`;

const IndicatorStyle = css`
  align-items: center;
  color: ${colors.blue300};
  display: inline-flex;
  justify-content: center;
  left: 0px;
  position: absolute;
  width: 25px;
`;

export const IndicatorContainer = styled(SelectPrimitive.SelectItemIndicator)`
  ${IndicatorStyle}
`;

export const IndicatorContainerScrolling = styled.span`
  ${IndicatorStyle}
`;

export const SelectIcon = styled(MdArrowRight)`
  height: 1.125rem;
  width: 1.125rem;
`;

export const ArrowDownContainer = styled(SelectPrimitive.ScrollDownButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 25px;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

// eslint-disable-next-line prettier/prettier
export const ListContainer = styled(FixedSizeList<ISelectFilterListData<ISelectFilterData>>)`
  max-height: 100%;
  min-width: 400px;
  overflow-x: hidden;

  ${scrollbar};
`;

interface ISelectItemProps {
  $itemOffset?: number;
  $top?: CSSProperties['top'];
}

const selectableStyle = css<ISelectItemProps>`
  top: ${({ $top = 0, $itemOffset = 0 }) =>
    parseInt($top.toString()) + $itemOffset}px;
`;

export const SelectDataItemContainer = styled.div<ISelectItemProps>`
  box-sizing: border-box;

  ${selectableStyle}
`;

export const LoadingContainer = styled.div<ISelectItemProps>`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  ${selectableStyle}
`;

interface ISizeProps {
  $height?: number;
  $width?: number;
}

export const Loading = styled(BaseLoading)<ISizeProps>`
  ${({ $height = 30, $width = 30 }) => css`
    height: ${$height}px;
    width: ${$width}px;
  `}
`;
