export const getStorage = <T = any>(
  key: string,
  defaultValue?: T
): NonNullable<T> | null => {
  const saved = localStorage.getItem(key);
  const initial: T | null = JSON.parse(saved ?? 'null');
  return initial ?? defaultValue ?? null;
};

export const setStorage = <T = any>(key: string, value: T): void => {
  const valueStr = JSON.stringify(value);
  if (valueStr !== undefined) localStorage.setItem(key, valueStr);
};

export const removeStorage = (key: string): void => {
  localStorage.removeItem(key);
};
