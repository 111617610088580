import { type AxiosError } from 'axios';

import {
  type IResponseAccountWithCodeDTO,
  type IResponseWithCode,
} from '@gbs-monorepo-packages/auth';

import { api } from './api';

const DashboardRedirect = 'api/refresh';

export const redirectDashboard =
  async (): Promise<IResponseAccountWithCodeDTO> => {
    const result = await api
      .get<IResponseAccountWithCodeDTO>(`${DashboardRedirect}`)
      .then((resp) => {
        localStorage.setItem('JWT_TOKEN', resp.data.token);
        return resp.data;
      })
      .catch((err: AxiosError<IResponseWithCode>) => {
        throw err;
      });

    return result;
  };
