import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  api,
} from './api';
import { type ICourseWithAnalyticsDTO } from './courses';

export interface IAnalyticsReportData {
  date: string;
  pagePath: string;
  city: string;
  browser: string;
  screenPageViews: string;
  averageSessionDuration: string;
  engagedSessions: string;
}

export interface IAnalyticsEntryCardProps {
  pagePath: string;
  totalViewCount: number;
}

export interface IGetAnalyticsByCourseViewUrlProps {
  viewUrl: string;
  page: number;
  limit: number;
}

export interface IGetAnalyticsByClientProps {
  clientId: string;
  page: number;
  limit: number;
}

export interface IPaginationAnalyticsByCourseDTO {
  data: ICourseWithAnalyticsDTO;
  meta: IPaginationMetaProps;
}

export interface IPaginationAnalyticsByClientDTO {
  data: ICourseWithAnalyticsDTO[];
  meta: IPaginationMetaProps;
}

export const getAnalyticsByCourseViewUrl = async ({
  viewUrl,
  page,
  limit,
}: IGetAnalyticsByCourseViewUrlProps): Promise<IPaginationAnalyticsByCourseDTO> => {
  const result = await api
    .get<IPaginationResponseData<ICourseWithAnalyticsDTO>>(
      `api/analytics/course/${viewUrl}?page=${page}&limit=${limit}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getAnalyticsByClient = async ({
  clientId,
  page,
  limit,
}: IGetAnalyticsByClientProps): Promise<IPaginationAnalyticsByClientDTO> => {
  const result = await api
    .get<IPaginationResponseData<ICourseWithAnalyticsDTO[]>>(
      `api/analytics/client/${clientId}?page=${page}&limit=${limit}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
