import { useCallback, useState } from 'react';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { type ICoursePageDTO } from '../../../../services/coursePages';
import {
  CustomCheckBoxGroup,
  CustomCheckbox,
  Flex,
  ModalText,
  SelectContainer,
  Title,
  TitleSelectAll,
} from './styles';

interface IDeletePageModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (pageIndex: number[]) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  coursePages: ICoursePageDTO[];
}

export const MultiplePagesDelete = ({
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  loading,
  coursePages,
  ...props
}: IDeletePageModalProps): JSX.Element | null => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [addedPages, setAddedPages] = useState<number[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const isDisabledConfirm = addedPages.length === 0;

  const handleSelectPage = useCallback((pageId: number) => {
    setAddedPages((prev) =>
      prev.includes(pageId)
        ? prev.filter((id) => id !== pageId)
        : [...prev, pageId]
    );
  }, []);

  const sel = selectedAll ? 'true' : 'false';

  const reset = useCallback(() => {
    setAddedPages([]);
    setSelectedAll(false);
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedAll((prev) => !prev);
    for (const page of coursePages) {
      setAddedPages((prev) =>
        prev.includes(page.id) ? prev : [...prev, page.id]
      );
    }
  }, [coursePages]);

  const handleDeselectAll = useCallback(() => {
    setSelectedAll((prev) => !prev);
    setAddedPages([]);
  }, []);

  const handleDeletePage = useCallback(() => {
    setLoadingDelete(true);
    setOpenConfirm(false);

    onAccept?.(addedPages)
      .then(() => {
        reset();
        onDecline?.();
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  }, [addedPages, onAccept, onDecline]);

  const handleDeclineDeletePage = useCallback(() => {
    reset();
    onDecline?.();
  }, [onDecline]);

  const handleConfirmDelete = useCallback(() => {
    setOpenConfirm((prev) => !prev);
  }, []);

  return !open ? null : (
    <>
      <DialogModal
        acceptText="Delete"
        dataCy="delete-course-multiple-page-dialog-modal"
        declineText="Cancel"
        mainText={`Select pages to delete`}
        {...props}
        loading={loadingDelete || loading}
        onAccept={() => {
          handleConfirmDelete();
        }}
        disabled={isDisabledConfirm}
        onDecline={handleDeclineDeletePage}
        onOpenChange={handleDeclineDeletePage}
        open={!waitToOpen}
      >
        <Flex>
          <CustomCheckbox
            id="selectAll"
            value={sel}
            onClick={selectedAll ? handleDeselectAll : handleSelectAll}
          />
          <TitleSelectAll>Select all</TitleSelectAll>
        </Flex>

        <SelectContainer>
          <CustomCheckBoxGroup
            type="multiple"
            data-cy="select-delete-container"
          >
            {coursePages.map((page, index) => (
              <Flex key={index}>
                <CustomCheckbox
                  key={page.id}
                  value={page.id.toString()}
                  dataCy={`checkbox-card-${index}`}
                  onClick={() => {
                    handleSelectPage(page.id);
                  }}
                  checked={addedPages.includes(page.id)}
                />
                <Title>{page.title}</Title>
              </Flex>
            ))}
          </CustomCheckBoxGroup>
        </SelectContainer>
      </DialogModal>

      <DialogModal
        dataCy="delete-course-multiple-page-dialog-modal"
        acceptText="Confirm"
        declineText="Cancel"
        mainText={`Are you sure you want to delete?`}
        {...props}
        onAccept={() => {
          handleDeletePage();
        }}
        loading={loadingDelete || loading}
        onDecline={handleConfirmDelete}
        onOpenChange={handleConfirmDelete}
        open={openConfirm}
      >
        <ModalText data-cy="text-informationDeleteMultiplePages">
          Existing pages in the database will be deleted.
        </ModalText>
      </DialogModal>
    </>
  );
};
