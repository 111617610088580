import { type CanvasSpot, type Editor } from 'grapesjs';
import { type CSSProperties, type ReactNode, useEffect, useState } from 'react';

export interface ITargetSpotProps {
  children?: ReactNode;
  spot: CanvasSpot;
  dataCy?: string;
  editor: Editor;
}

export const TargetSpot = ({
  children,
  editor,
  spot,
  dataCy = 'target-spot',
}: ITargetSpotProps): JSX.Element => {
  const [style, setStyle] = useState<CSSProperties>({});
  useEffect(() => {
    setStyle(spot.getStyle() as CSSProperties);

    const handleStyleChange = (): void => {
      setStyle(spot.getStyle() as CSSProperties);
    };

    editor.on('canvas:tools:update', handleStyleChange);
    return () => {
      editor.off('canvas:tools:update', handleStyleChange);
    };
  }, [spot, editor]);
  return (
    <div data-cy={dataCy} style={style}>
      {children}
    </div>
  );
};
