import { type ComponentProperties } from 'grapesjs';

import { tableLayoutType } from '../..';
import { type IComponentProperties } from '../../../../interfaces';
import { DefaultDesktopColumn } from '../../../DesktopOnlyColumn/components/DefaultDesktopOnlyColumn';
import { DefaultMobileOnlyColumn } from '../../../MobileOnlyColumn/components/DefaultMobileOnlyColumn';
import {
  DefaultTableLayoutContent,
  type IDefaultTableLayoutContentOptions,
} from '../DefaultTableContent';
import TableLayoutStyle from './styles.css?inline';

export const tableLayoutClass = 'table-layout';
export const tableLayoutResponsiveClass = 'table-layout-responsive';

interface IDefaultTableLayoutOptions extends IDefaultTableLayoutContentOptions {
  initWithText?: boolean;
  responsive?: boolean;
  showOnDesktop?: boolean;
  showOnMobile?: boolean;
}

export const DefaultTableLayout = (
  cellsByRow: number[],
  options: IDefaultTableLayoutOptions = {}
): IComponentProperties => {
  const {
    responsive = true,
    showOnDesktop = true,
    showOnMobile = true,
  } = options;
  const onlyMobile = !showOnDesktop && showOnMobile;
  const onlyDesktop = showOnDesktop && !showOnMobile;

  const toExtendResponsiveClassName = responsive
    ? tableLayoutResponsiveClass
    : '';

  const { attributes, styles = '' } = (
    onlyMobile
      ? DefaultMobileOnlyColumn()
      : onlyDesktop
      ? DefaultDesktopColumn()
      : { attributes: {} }
  ) as Pick<ComponentProperties, 'attributes' | 'styles'>;

  const toExtendDeviceClassName =
    typeof attributes?.class === 'string' ? attributes.class : '';
  const toExtendDeviceStyles = styles;

  const defaultClass = [tableLayoutClass];
  if (toExtendDeviceClassName) {
    defaultClass.push(toExtendDeviceClassName);
  }
  if (toExtendResponsiveClassName) {
    defaultClass.push(toExtendResponsiveClassName);
  }

  const defaultStyles = [TableLayoutStyle];
  if (toExtendDeviceStyles) {
    defaultStyles.unshift(toExtendDeviceStyles);
  }

  const defaultTable = {
    type: tableLayoutType,
    attributes: {
      'custom-type': tableLayoutType,
      class: defaultClass.join(' '),
    },
    styles: defaultStyles.join(' '),
    components: DefaultTableLayoutContent(cellsByRow, options),
  } satisfies IComponentProperties;

  return defaultTable;
};
