import { type ReactNode, createContext, useCallback, useState } from 'react';
import {
  DragDropContext as DragDropContextPrimitive,
  type DropResult,
} from 'react-beautiful-dnd';
import { useImmer } from 'use-immer';

interface IDragDropHandlerProps {
  from: number;
  to: number;
  droppableId: string;
}
export type IDragDropHandler = (props: IDragDropHandlerProps) => void;

export interface IDragDropContextData {
  addDragDropListener: (droppableId: string, handler: IDragDropHandler) => void;
  isDragging: boolean;
  removeDragDropListener: (droppableId: string) => void;
}

interface IDragDropProps {
  children: ReactNode;
}

export const DragDropContext = createContext<IDragDropContextData>(
  {} as IDragDropContextData
);

export function DragDropProvider({ children }: IDragDropProps): JSX.Element {
  const [isDragging, setIsDragging] = useState(false);
  const [listeners, setListeners] = useImmer(
    new Map<string, IDragDropHandler>()
  );

  const addDragDropListener = useCallback(
    (droppableId: string, handler: IDragDropHandler) => {
      setListeners((draft) => {
        draft.set(droppableId, handler);
      });
    },
    [setListeners]
  );
  const removeDragDropListener = useCallback(
    (droppableId: string) => {
      setListeners((draft) => {
        draft.delete(droppableId);
      });
    },
    [setListeners]
  );

  const handleDragCardStart = useCallback(() => {
    setIsDragging(true);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }, []);

  const handleDragCardEnd = useCallback(
    ({ source, destination = null }: DropResult) => {
      setIsDragging(() => false);
      if (destination === null) return;

      const { index: from, droppableId: fromDroppableId } = source;
      const { index: to, droppableId: toDroppableId } = destination;

      if (fromDroppableId !== toDroppableId || !listeners.has(toDroppableId))
        return;

      if (to < 0 || to === from) return;

      listeners.get(toDroppableId)?.({ from, to, droppableId: toDroppableId });
    },
    [listeners]
  );

  return (
    <DragDropContext.Provider
      value={{
        addDragDropListener,
        isDragging,
        removeDragDropListener,
      }}
    >
      <DragDropContextPrimitive
        onDragStart={handleDragCardStart}
        onDragEnd={handleDragCardEnd}
      >
        {children}
      </DragDropContextPrimitive>
    </DragDropContext.Provider>
  );
}
