import { type InputHTMLAttributes, forwardRef } from 'react';

import { Fieldset, Input, Label } from './styles';

interface IUploadFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  dataCy?: string;
  label?: string;
}

export const UploadField = forwardRef<HTMLInputElement, IUploadFieldProps>(
  function UploadField(
    { dataCy = 'input-file', label, ...props },
    ref
  ): JSX.Element {
    return (
      <Fieldset hasLabel={!!label}>
        <Input {...props} ref={ref} data-cy={dataCy} type="file" />
        {label && <Label>{label}</Label>}
      </Fieldset>
    );
  }
);
