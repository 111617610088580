import { type Component, type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';
import ModalUploadStyle from './styles.css?inline';

export const modalUploadId = 'modal-upload';
const customImageType = 'custom-image';
const customSvgType = 'custom-svg';
const CHANGING_IMAGE = true;

export const cmdOpenUploadModalComponents = 'open-upload-modal-components';

export interface IUploadModalComponentsPluginOptions {
  handlerOpenUploadComponents: (
    editor: Editor,
    changeImage?: boolean,
    idImage?: string
  ) => void;
  handlerCloseUploadComponents: () => void;
}

export const ModalUploadComponentsPlugin = (
  editor: Editor,
  { handlerOpenUploadComponents }: IUploadModalComponentsPluginOptions
): void => {
  const { BlockManager, DomComponents } = editor;
  DomComponents.addType(customImageType, {
    isComponent: (el) => el.tagName === 'img',
    model: {
      defaults: {
        tagName: 'img',
        type: customImageType,
        draggable: true,
        stylable: true,
        copyable: true,
        editable: true,
        resizable: true,
        attributes: {
          class: 'image-container',
        },
      },
    },
    view: {
      events: {
        // @ts-expect-error: ype '{ dblclick: string; }' is not assignable to type '() => EventsHash'.
        dblclick: 'openModal',
      },
      // eslint-disable-next-line no-restricted-syntax
      openModal: function (e: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const targetId = String(e?.target?.id ?? '');
        if (targetId) {
          handlerOpenUploadComponents(editor, CHANGING_IMAGE, targetId);
        }
      },
    },
  });

  DomComponents.addType(customSvgType, {
    model: {
      defaults: {
        tagName: 'div',
        type: customSvgType,
        draggable: true,
        stylable: true,
        copyable: true,
        resizable: true,
        attributes: {
          class: 'svg-container',
        },
      },
    },
  });

  DomComponents.addType('svg', {
    model: {
      defaults: {
        droppable: false,
      },
    },
    view: {
      events: {
        // @ts-expect-error: ype '{ dblclick: string; }' is not assignable to type '() => EventsHash'.
        dblclick: 'openModal',
      },
      // eslint-disable-next-line no-restricted-syntax
      openModal: function (e: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const targetId = String(e?.target?.id ?? '');
        if (targetId) {
          handlerOpenUploadComponents(editor, CHANGING_IMAGE, targetId);
        }
      },
    },
  });

  DomComponents.addType('svg-in', {
    model: {
      defaults: {
        draggable: false,
        droppable: false,
      },
    },
  });

  const ModalUploadContent: IBlockContent = {
    attributes: {
      type: 'ImageContainer',
      class: 'image-container',
    },
    droppable: false,
    styles: ModalUploadStyle,
    tagName: 'div',
  };

  BlockManager.add(modalUploadId, {
    attributes: { id: 'modal-upload' },
    category: 'Basic',
    content: ModalUploadContent,
    label: 'Images',
    media:
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" version="1.1"><path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z"></path></svg>',
    select: true,
  });

  editor.on('component:add', (model: Component) => {
    if (model.attributes.tagName !== 'img') return;
    if (
      model.get('type') === 'image' ||
      model.get('type') === 'img' ||
      model.attributes.tagName === 'img'
    ) {
      const componentView = model.view;

      componentView?.undelegateEvents();
      if (componentView) {
        // @ts-expect-error: openModal was defined above by us
        componentView.openModal = (e: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const targetId = String(e?.target?.id ?? '');
          if (targetId) {
            handlerOpenUploadComponents(editor, CHANGING_IMAGE, targetId);
          }
        };
        componentView?.delegateEvents({
          dblclick: 'openModal',
        });
      }
    }
  });

  editor.on('component:update', (model: Component) => {
    if (model.get('type') === 'svg' || model.get('type') === 'svg-in') {
      const parent = model.parent() as Component;
      if (parent.get('type') === 'svg' || parent.get('type') === 'svg-in') {
        model.removed();
      }
    }
  });
};
