import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 3.25rem;
  width: 100%;
  & button {
    height: 3.25rem;
    min-width: 15rem;
  }
`;

export const Loading = styled.img`
  height: 3.25rem;
  width: 3.25rem;
`;

export const Center = styled.div`
  text-align: center;
`;
