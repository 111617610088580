import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';
import TwoColumnsSideBarResponsiveStyle from './styles.css?inline';

export const twoColumnsSideBarResponsiveId = 'two-columns-responsive-sidebar';

const TwoColumnsSideBarResponsivePlugin = (editor: Editor): void => {
  const TwoColumnsSideBarResponsiveContent: IBlockContent = {
    attributes: { class: 'column-gbs-2-37' },
    content: '',
    editable: true,
    styles: TwoColumnsSideBarResponsiveStyle,
    tagName: 'div',
  };

  const CenterContainer: IBlockContent = {
    components: [
      TwoColumnsSideBarResponsiveContent,
      TwoColumnsSideBarResponsiveContent,
    ],
    editable: true,
    attributes: { class: 'left-sidebar' },
    tagName: 'div',
  };

  editor.BlockManager.add(twoColumnsSideBarResponsiveId, {
    category: 'Responsive',
    content: CenterContainer,
    label: '2 Columns Responsive 3/7',
    media:
      '<svg viewBox="0 0 24 24"> <path fill="currentColor" d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z"></path> </svg>',
    select: true,
  });
};

export default TwoColumnsSideBarResponsivePlugin;
