import { type ReactNode, type Ref, forwardRef } from 'react';

import { type ICheckBoxCardProps } from '@gbs-monorepo-packages/common';

import { type ICourseWithAnalyticsDTO } from '../../../../services/courses';
import {
  AnalyticsEntryContainer,
  AnalyticsTitle,
  AnalyticsTotalViews,
  CheckBoxCardCustom,
} from './styles';

export interface IAnalyticsCardProps
  extends Omit<ICheckBoxCardProps, 'children' | 'value'> {
  data: ICourseWithAnalyticsDTO;
  dataCy?: string;
  value?: string;
  children?: ReactNode;
}

const AnalyticsEntryCardComponent = (
  {
    data,
    dataCy = data.viewURL ?? `${data.id}-${data.title}`,
    value = data.viewURL ?? `${data.id}-${data.title}`,
    ...props
  }: IAnalyticsCardProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element => {
  let totalViewCount = 0;

  data.analytics?.forEach((item) => {
    totalViewCount += Number(item.screenPageViews);
  });

  if (data.oldAnalytics) {
    for (const oldAnalyticsKey of Object.keys(data.oldAnalytics)) {
      const oldViewCount = data.oldAnalytics[oldAnalyticsKey];

      totalViewCount += oldViewCount;
    }
  }

  return (
    <CheckBoxCardCustom
      {...props}
      ref={ref}
      dataCy={dataCy}
      isNotButton
      value={value}
      defaultValue={[value]}
    >
      <AnalyticsEntryContainer>
        <AnalyticsTitle data-cy={data.title}>{data.title}</AnalyticsTitle>
        <AnalyticsTotalViews data-cy={totalViewCount}>
          {totalViewCount} Views
        </AnalyticsTotalViews>
      </AnalyticsEntryContainer>
    </CheckBoxCardCustom>
  );
};

export const AnalyticsEntryCard = forwardRef<
  HTMLButtonElement,
  IAnalyticsCardProps
>(AnalyticsEntryCardComponent);
