import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type ColorResult } from 'react-color';

import {
  BaseCollapsibleMenu,
  type IFormModalProps,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  fontUnits as fontUnitsData,
  fonts as fontsGrapes,
} from '../../../../constants/Fonts';
import {
  type IGlobalStyle,
  globalStyleDefault,
  maxHueRotate,
  maxValuePageWidth,
  maxValuePercentagePageWidth,
  minHueRotate,
  minValuePageWidth,
} from '../../../../constants/GlobalStyles';
import { useCourse } from '../../../../hooks/useCourse';
import { type IFontDTO } from '../../../../services/courses';
import {
  ColorPickerContainer,
  ColorPickerIcon,
  ColorPreview,
  Container,
  CustomChromePicker,
  ErrorMessage,
  ErrorMessageContainer,
  Fieldset,
  FormModalCustom,
  Input,
  Label,
  LabelSelectData,
  Loading,
  LoadingContainer,
  OverlayDiv,
  OverlayText,
  SelectDataCustom,
  SliderCustom,
  TemplateImagesColorsSection,
  TemplateImagesColorsTitle,
} from './styles';

type TemplateImageColorType =
  | 'accentColor'
  | 'additionalColors'
  | 'backgroundColor'
  | 'primaryColor'
  | 'secondaryColor';

export interface IFont extends IFontDTO {
  index: number;
  origin?: string;
  selected?: boolean;
}

interface IGlobalStyleModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  courseId: number;
  loading: boolean;
  onAccept: (data: IGlobalStyle) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  needSave?: boolean;
}

interface IError {
  buttonBackgroundColor?: string;
  buttonFontColor?: string;
  headlineFont?: string;
  headlineFontColor?: string;
  headlineFontSize?: string;
  headlineFontSizeUnit?: string;
  hueRotate?: string;
  pageWidth?: string;
  pageWidthUnit?: string;
  paragraphFont?: string;
  paragraphFontColor?: string;
  paragraphFontSize?: string;
  paragraphFontSizeUnit?: string;
  primaryColor?: string;
  secondaryColor?: string;
  templatePrimaryColor?: string;
  templateSecondaryColor?: string;
  templateBackgroundColor?: string;
  templateAccentColor?: string;
  templateAdditionalColors?: Record<string, string>;
  other?: string;
}

export const GlobalStyleModal = ({
  courseId,
  loading,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  needSave = false,
  ...props
}: IGlobalStyleModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const { selectedCourse } = useCourse();

  const { addToast } = useToast();
  const [loadingFonts, setLoadingFonts] = useState(true);
  const [fonts, setFonts] = useState<IFont[]>([]);
  const loadedFontsOnce = useRef(false);

  const additionalColorsBottomRef = useRef<HTMLDivElement>(null);

  const [paragraphFont, setParagraphFont] = useState<IFont | null>(
    fonts.at(0) ?? null
  );

  const [pageWidth, setPageWidth] = useState<number>(
    globalStyleDefault.pageWidth
  );
  const [pageWidthUnit, setPageWidthUnit] = useState<string>(
    globalStyleDefault.pageWidthUnit
  );

  const [paragraphFontSize, setParagraphFontSize] = useState<number>(
    globalStyleDefault.paragraphFontSize
  );
  const [paragraphFontSizeUnit, setParagraphFontSizeUnit] = useState<string>(
    globalStyleDefault.paragraphFontSizeUnit
  );
  const [headlineFontSizeUnit, setHeadlineFontSizeUnit] = useState<string>(
    globalStyleDefault.headlineFontSizeUnit
  );
  const [headlineFontSize, setHeadlineFontSize] = useState<number>(
    globalStyleDefault.headlineFontSize
  );
  const [headlineFont, setHeadlineFont] = useState<IFont | null>(
    fonts.at(0) ?? null
  );
  const [error, setError] = useState<IError>({});

  // COLORS STATES - BEGIN
  const [buttonColor, setButtonColor] = useState(
    globalStyleDefault.buttonColor
  );
  const [buttonFontColor, setButtonFontColor] = useState(
    globalStyleDefault.buttonFontColor
  );
  const [headlineFontColor, setHeadlineColor] = useState(
    globalStyleDefault.headlineFontColor
  );
  const [paragraphFontColor, setParagraphFontColor] = useState(
    globalStyleDefault.paragraphFontColor
  );
  const [hueRotateBaseColor, setHueRotateBaseColor] = useState(
    globalStyleDefault.secondaryColor
  );
  const [primaryColor, setPrimaryColor] = useState(
    globalStyleDefault.primaryColor
  );
  const [secondaryColor, setSecondaryColor] = useState(
    globalStyleDefault.secondaryColor
  );
  // COLORS STATES - END

  const [hueRotate, setHueRotate] = useState(globalStyleDefault.hueRotate);
  const hueRotateSlider = useMemo(() => [hueRotate], [hueRotate]);

  const [templateColors, setTemplateColors] = useState(
    globalStyleDefault.templateColors
  );

  const [templatePrimaryColor, setTemplatePrimaryColor] = useState(
    globalStyleDefault.templateColors.primaryColor
  );

  const [templateSecondaryColor, setTemplateSecondaryColor] = useState(
    globalStyleDefault.templateColors.secondaryColor
  );

  const [templateBackgroundColor, setTemplateBackgroundColor] = useState(
    globalStyleDefault.templateColors.backgroundColor
  );

  const [templateAccentColor, setTemplateAccentColor] = useState(
    globalStyleDefault.templateColors.accentColor
  );

  const [templateAdditionalColors, setTemplateAdditionalColors] = useState<
    Record<string, string>
  >(globalStyleDefault.templateColors.additionalColors);

  const setStyleFromSelectedCourse = useCallback(() => {
    const globalStyle = selectedCourse?.globalStyle ?? globalStyleDefault;
    const defaultFont = fonts.at(0) ?? null;

    setButtonColor(globalStyle.buttonColor ?? globalStyleDefault.buttonColor);
    setButtonFontColor(
      globalStyle.buttonFontColor ?? globalStyleDefault.buttonFontColor
    );

    setHeadlineColor(
      globalStyle.headlineFontColor ?? globalStyleDefault.headlineFontColor
    );
    setHeadlineFont(globalStyle.headlineFont ?? defaultFont);
    setHeadlineFontSize(
      globalStyle.headlineFontSize ?? globalStyleDefault.headlineFontSize
    );
    setHeadlineFontSizeUnit(
      globalStyle.headlineFontSizeUnit ??
        globalStyleDefault.headlineFontSizeUnit
    );

    setHueRotate(globalStyle.hueRotate ?? globalStyleDefault.hueRotate);
    setHueRotateBaseColor(
      globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor
    );

    setPageWidth(globalStyle.pageWidth ?? globalStyleDefault.pageWidth);
    setPageWidthUnit(
      globalStyle.pageWidthUnit ?? globalStyleDefault.pageWidthUnit
    );

    setParagraphFont(globalStyle.paragraphFont ?? defaultFont);
    setParagraphFontColor(
      globalStyle.paragraphFontColor ?? globalStyleDefault.paragraphFontColor
    );
    setParagraphFontSize(
      globalStyle.paragraphFontSize ?? globalStyleDefault.paragraphFontSize
    );
    setParagraphFontSizeUnit(
      globalStyle.paragraphFontSizeUnit ??
        globalStyleDefault.paragraphFontSizeUnit
    );

    setPrimaryColor(
      globalStyle.primaryColor ??
        globalStyle.buttonColor ??
        globalStyleDefault.primaryColor
    );
    setSecondaryColor(
      globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor
    );
    setTemplateColors(
      globalStyle.templateColors ?? globalStyleDefault.templateColors
    );

    setTemplatePrimaryColor(
      globalStyle.templateColors?.primaryColor ??
        globalStyleDefault.templateColors.primaryColor
    );

    setTemplateSecondaryColor(
      globalStyle.templateColors?.secondaryColor ??
        globalStyleDefault.templateColors.secondaryColor
    );

    setTemplateBackgroundColor(
      globalStyle.templateColors?.backgroundColor ??
        globalStyleDefault.templateColors.backgroundColor
    );

    setTemplateAccentColor(
      globalStyle.templateColors?.accentColor ??
        globalStyleDefault.templateColors.accentColor
    );

    let additionalColors = globalStyle.templateColors?.additionalColors;
    if (Array.isArray(additionalColors)) {
      additionalColors = (additionalColors as string[]).reduce<
        Record<string, string>
      >((acc, color) => {
        acc[color] = color;
        return acc;
      }, {});
    }

    setTemplateAdditionalColors(additionalColors ?? {});
  }, [fonts, selectedCourse]);

  const hasAnyTemplateColors = useMemo(
    () =>
      Boolean(
        templatePrimaryColor ||
          templateSecondaryColor ||
          templateBackgroundColor ||
          templateAccentColor ||
          Object.keys(templateAdditionalColors).length > 0
      ),
    [
      templateAccentColor,
      templateAdditionalColors,
      templateBackgroundColor,
      templatePrimaryColor,
      templateSecondaryColor,
    ]
  );

  const resetForm = useCallback(() => {
    setStyleFromSelectedCourse();
  }, [setStyleFromSelectedCourse]);

  const handleDeclineSaveGlobalStyle = useCallback(() => {
    if (!isSelectOpen.current.size) {
      loadedFontsOnce.current = false;
      onDecline?.();
      setError({});
      resetForm();
    }
  }, [onDecline, resetForm]);

  const handleButtonFontColorChange = useCallback((color: string) => {
    setButtonFontColor(color);
  }, []);

  const handleParagraphFontSelectChange = useCallback(
    (value: string) => {
      const index = fonts.findIndex((font) => {
        return font.family === value;
      });

      setParagraphFont(fonts[index]);
    },
    [fonts]
  );

  const handleHeadlineFontSelectChange = useCallback(
    (value: string) => {
      const index = fonts.findIndex((font) => {
        return font.family === value;
      });

      setHeadlineFont(fonts[index]);
    },
    [fonts]
  );

  const handleParagraphFontUnitChange = useCallback((value: string) => {
    setParagraphFontSizeUnit(value);
  }, []);

  const handleHeadlineFontUnitChange = useCallback((value: string) => {
    setHeadlineFontSizeUnit(value);
  }, []);

  const handleParagraphFontSizeChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    let { value } = target;
    if (Number(value) > 100) {
      value = '100';
    }

    setParagraphFontSize(value.length ? Number(value) : NaN);
  };

  const handlePageWidthChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let { valueAsNumber } = target;

    if (valueAsNumber < minValuePageWidth) {
      valueAsNumber = minValuePageWidth;
    }

    if (valueAsNumber > maxValuePageWidth) {
      valueAsNumber = maxValuePageWidth;
    }

    if (pageWidthUnit === '%' && valueAsNumber > maxValuePercentagePageWidth) {
      valueAsNumber = maxValuePercentagePageWidth;
    }

    setPageWidth(Number.isInteger(valueAsNumber) ? valueAsNumber : NaN);
  };

  const handlePageWidthUnitChange = useCallback((value: string) => {
    setPageWidth(minValuePageWidth);
    setPageWidthUnit(value);
  }, []);

  const handleHeadlineFontSizeChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    let { value } = target;
    if (Number(value) > 100) {
      value = '100';
    }

    setHeadlineFontSize(value.length ? Number(value) : NaN);
  };

  const handleHeadlineFontColorChange = useCallback((color: string) => {
    setHeadlineColor(color);
  }, []);

  const handleButtonBackgroundColorChange = useCallback((color: string) => {
    setButtonColor(color);
  }, []);

  const handleParagraphFontColorChange = useCallback((color: string) => {
    setParagraphFontColor(color);
  }, []);

  const handlePrimaryColorChange = useCallback((color: string) => {
    setPrimaryColor(color);
  }, []);

  const handleSecondaryColorChange = useCallback((color: string) => {
    setSecondaryColor(color);
  }, []);

  const handleHueRotateBaseColorChange = useCallback((color: string) => {
    setHueRotateBaseColor(color);
  }, []);

  const handleHueRotateChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      let newValue = parseInt(value);
      if (newValue > maxHueRotate) {
        newValue = maxHueRotate;
      } else if (newValue < minHueRotate) {
        newValue = minHueRotate;
      }
      setHueRotate(newValue);
    },
    []
  );
  const handleHueRotateSliderChange = useCallback((value: number[]) => {
    const newValue = value.at(0);
    setHueRotate(newValue ?? globalStyleDefault.hueRotate);
  }, []);

  const handleTemplateColorsChange = (
    color: string,
    colorKey: string | null
  ) => {
    const newTemplateAddColors = structuredClone(templateAdditionalColors);
    newTemplateAddColors[colorKey ?? 'currentColor'] = color;
    setTemplateAdditionalColors(newTemplateAddColors);
  };

  const handleTemplatePrimaryColorChange = (color: string) => {
    setTemplatePrimaryColor(color);
  };

  const handleTemplateSecondaryColorChange = (color: string) => {
    setTemplateSecondaryColor(color);
  };

  const handleTemplateBackgroundColorChange = (color: string) => {
    setTemplateBackgroundColor(color);
  };

  const handleTemplateAccentColorChange = (color: string) => {
    setTemplateAccentColor(color);
  };

  useEffect(() => {
    loadedFontsOnce.current = false;
  }, [courseId]);

  useEffect(() => {
    let mount = true;
    const loadFontsOnce = () => {
      loadedFontsOnce.current = true;
      setLoadingFonts(true);

      const resultWithIndex: IFont[] = selectedCourse?.fonts
        ? selectedCourse?.fonts.map((data, index) => ({
            ...data,
            index,
            origin: 'course',
          }))
        : [];

      const grapesFontsWithIndex: IFont[] = fontsGrapes.map((data, index) => ({
        ...data,
        index,
      }));

      let currentIndex = 0;
      const allFontsWithIndex = [
        ...grapesFontsWithIndex.map((obj) => ({
          ...obj,
          index: currentIndex++,
        })),
        ...resultWithIndex.map((obj) => ({
          ...obj,
          index: currentIndex++,
        })),
      ];

      if (mount) {
        setFonts(allFontsWithIndex);
      }

      setLoadingFonts(false);
    };

    if (open && courseId && !loadedFontsOnce.current) {
      loadFontsOnce();
    }

    if (!headlineFont) {
      setHeadlineFont(fonts[0]);
    }

    if (!paragraphFont) {
      setParagraphFont(fonts[0]);
    }

    return () => {
      mount = false;
    };
  }, [
    addToast,
    courseId,
    fonts,
    open,
    handleParagraphFontSelectChange,
    selectedCourse,
    headlineFont,
    paragraphFont,
  ]);

  const handlerSubmit = useCallback(async () => {
    let errors = 0;
    const errorsMessage = {
      headlineFont: '',
      headlineFontColor: '',
      headlineFontSize: '',
      headlineFontSizeUnit: '',
      pageWidth: '',
      pageWidthUnit: '',
      paragraphFont: '',
      paragraphFontColor: '',
      paragraphFontSize: '',
      paragraphFontSizeUnit: '',
      other: '',
    };

    if (paragraphFontSize <= 0 || !paragraphFontSize) {
      errorsMessage.paragraphFontSize = 'Text font size is required.';
      errors++;
    }

    if (headlineFontSize <= 0 || !headlineFontSize) {
      errorsMessage.headlineFontSize = 'Headline font size is required.';
      errors++;
    }

    if (errors > 0) {
      setError(errorsMessage);
      return;
    }

    await onAccept?.({
      buttonColor,
      buttonFontColor,
      headlineFont,
      headlineFontColor,
      headlineFontSize,
      headlineFontSizeUnit,
      hueRotate,
      pageWidth,
      pageWidthUnit,
      paragraphFont,
      paragraphFontColor,
      paragraphFontSize,
      paragraphFontSizeUnit,
      primaryColor,
      secondaryColor,
      templateColors,
    });
  }, [
    buttonColor,
    buttonFontColor,
    headlineFont,
    headlineFontColor,
    headlineFontSize,
    headlineFontSizeUnit,
    hueRotate,
    onAccept,
    pageWidth,
    pageWidthUnit,
    paragraphFont,
    paragraphFontColor,
    paragraphFontSize,
    paragraphFontSizeUnit,
    primaryColor,
    secondaryColor,
    templateColors,
  ]);

  useEffect(() => {
    setStyleFromSelectedCourse();
  }, [setStyleFromSelectedCourse]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const isColorUnique = useCallback(
    (newColor: string, colorType: TemplateImageColorType) => {
      let isColorUnique = true;

      if (colorType === 'additionalColors') {
        const addColors = new Set();
        Object.entries(templateAdditionalColors ?? {}).forEach(([, value]) => {
          if (addColors.has(value)) {
            isColorUnique = false;
          } else {
            addColors.add(value);
          }
        });

        return isColorUnique;
      }

      const colorChecks = {
        primaryColor: newColor !== templatePrimaryColor,
        secondaryColor: newColor !== templateSecondaryColor,
        backgroundColor: newColor !== templateBackgroundColor,
        accentColor: newColor !== templateAccentColor,
      };

      const colorChecksMap = new Map(Object.entries(colorChecks));

      colorChecksMap.delete(colorType);

      colorChecksMap.forEach((value) => {
        if (!value) {
          isColorUnique = false;
        }
      });

      return isColorUnique;
    },
    [
      templateAccentColor,
      templateAdditionalColors,
      templateBackgroundColor,
      templatePrimaryColor,
      templateSecondaryColor,
    ]
  );

  const resetTemplateColorErrors = useCallback(() => {
    let newTemplateAdditionalColorsErrors = {};
    Object.keys(error.templateAdditionalColors ?? {}).forEach((key) => {
      newTemplateAdditionalColorsErrors = {
        ...newTemplateAdditionalColorsErrors,
        [key]: '',
      };
    });

    setError((prevState) => {
      return {
        ...prevState,
        templatePrimaryColor: '',
        templateSecondaryColor: '',
        templateBackgroundColor: '',
        templateAccentColor: '',
        templateAdditionalColors: newTemplateAdditionalColorsErrors,
      };
    });
  }, [error.templateAdditionalColors]);

  useEffect(() => {
    if (hasAnyTemplateColors) {
      if (!isColorUnique(templatePrimaryColor, 'primaryColor')) {
        setError((prevState) => {
          return {
            ...prevState,
            templatePrimaryColor:
              'Color must be unique within the Template Colors',
          };
        });
        return;
      }
      if (!isColorUnique(templateSecondaryColor, 'secondaryColor')) {
        setError((prevState) => {
          return {
            ...prevState,
            templateSecondaryColor:
              'Color must be unique within the Template Colors',
          };
        });
        return;
      }
      if (!isColorUnique(templateBackgroundColor, 'backgroundColor')) {
        setError((prevState) => {
          return {
            ...prevState,
            templateBackgroundColor:
              'Color must be unique within the Template Colors',
          };
        });
        return;
      }
      if (!isColorUnique(templateAccentColor, 'accentColor')) {
        setError((prevState) => {
          return {
            ...prevState,
            templateAccentColor:
              'Color must be unique within the Template Colors',
          };
        });
        return;
      }

      let hasErrorOnAdditionalColors = false;
      Object.entries(templateAdditionalColors ?? {}).forEach(([key, value]) => {
        if (!isColorUnique(value, 'additionalColors')) {
          setError((prevState) => {
            return {
              ...prevState,
              templateAdditionalColors: {
                ...prevState.templateAdditionalColors,
                [key]: 'Color must be unique within the Template Colors',
              },
            };
          });
          hasErrorOnAdditionalColors = true;
        }
      });

      if (hasErrorOnAdditionalColors) {
        return;
      }
    }
    resetTemplateColorErrors();

    setTemplateColors({
      primaryColor: templatePrimaryColor,
      secondaryColor: templateSecondaryColor,
      backgroundColor: templateBackgroundColor,
      accentColor: templateAccentColor,
      additionalColors: templateAdditionalColors,
    });
  }, [
    templateAccentColor,
    templateAdditionalColors,
    templateBackgroundColor,
    templatePrimaryColor,
    templateSecondaryColor,
    isColorUnique,
    hasAnyTemplateColors,
  ]);

  const hasError = useMemo((): boolean => {
    if (needSave) {
      return true;
    }

    let errorFound = false;

    Object.entries(error).forEach(
      ([key, value]: [string, Record<string, string> | string]) => {
        if (key === 'templateAdditionalColors') {
          Object.entries(value ?? {}).forEach(([, value]) => {
            if (value !== '') {
              errorFound = true;
            }
          });
        } else {
          if (value !== '') {
            errorFound = true;
          }
        }
      }
    );

    return errorFound;
  }, [error, needSave]);

  return !open ? null : (
    <FormModalCustom
      acceptText="Save"
      dataCy="global-styles-dialog-modal"
      declineText="Cancel"
      mainText="Global Styles"
      disabled={hasError}
      {...props}
      onAccept={() => {
        void handlerSubmit();
      }}
      loading={loading}
      onDecline={handleDeclineSaveGlobalStyle}
      onOpenChange={handleDeclineSaveGlobalStyle}
      open={!waitToOpen}
      acceptButtonTooltipText={
        needSave
          ? 'Please save your Course before updating the Global Styles.'
          : null
      }
    >
      {loadingFonts ? (
        <LoadingContainer data-cy="loading-members-container">
          <Loading />
        </LoadingContainer>
      ) : (
        <Container>
          <Fieldset
            filled={!!pageWidth || !!pageWidthUnit}
            isInvalid={!!error.pageWidth || !!error.pageWidthUnit}
          >
            <Input
              data-cy="page-width-input"
              id="page-width-input"
              max={maxValuePageWidth}
              min={minValuePageWidth}
              onChange={handlePageWidthChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              required
              type="number"
              value={pageWidth}
            />

            <SelectDataCustom
              id="select-page-width-unit"
              data={fontUnitsData.map((font) => {
                return { key: font.key, value: font.value };
              })}
              dataCy="select-page-width-unit"
              defaultValue={pageWidthUnit}
              name="select-page-width-unit"
              onValueChange={handlePageWidthUnitChange}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'width');
              }}
              zIndex={9999}
            />
            <Label data-cy="text-page-width-unit" htmlFor="page-width-input">
              Page Width
            </Label>
          </Fieldset>
          <ErrorMessageContainer>
            {error.pageWidth && (
              <ErrorMessage data-cy={error.pageWidth}>
                {error.pageWidth}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>

          <Fieldset
            filled={!!headlineFont}
            isInvalid={!!error.headlineFont}
            isSelectData
          >
            <SelectDataCustom
              id="select-headline-font"
              data={fonts.map((font) => {
                return { key: font.family, value: font.family };
              })}
              dataCy="select-headline-font"
              defaultValue={headlineFont?.family ?? fonts[0].family}
              name="select-headline-font"
              onValueChange={handleHeadlineFontSelectChange}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'headlineFont');
              }}
              zIndex={9999}
            />
            <LabelSelectData data-cy="text-headline-font">
              Headline Font
            </LabelSelectData>
          </Fieldset>
          <ErrorMessageContainer>
            {error.headlineFont && (
              <ErrorMessage data-cy={error.headlineFont}>
                {error.headlineFont}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>

          <Fieldset
            filled={!!headlineFontSize || !!headlineFontSizeUnit}
            isInvalid={!!error.headlineFontSize || !!error.headlineFontSizeUnit}
          >
            <Input
              data-cy="headline-font-size"
              id="headline-font-size"
              max={100}
              min={1}
              step={1}
              onChange={handleHeadlineFontSizeChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              required
              type="number"
              value={headlineFontSize}
            />

            <SelectDataCustom
              data={fontUnitsData.map((font) => {
                return { key: font.key, value: font.value };
              })}
              dataCy="select-headline-font-unit"
              defaultValue={headlineFontSizeUnit}
              name="select-headline-font-unit"
              onValueChange={handleHeadlineFontUnitChange}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'headlineFontUnit');
              }}
              zIndex={9999}
            />
            <Label
              data-cy="text-headline-font-unit"
              htmlFor="headline-font-size"
            >
              Headline Font Size
            </Label>
          </Fieldset>
          <ErrorMessageContainer>
            {error.headlineFontSize && (
              <ErrorMessage data-cy={error.headlineFontSize}>
                {error.headlineFontSize}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>

          <ColorPickerInput
            color={headlineFontColor}
            dataCy="headline-font-color"
            errorMessage={error.headlineFontColor}
            label="Headline color"
            onChange={handleHeadlineFontColorChange}
          />

          <Fieldset
            filled={!!paragraphFont}
            isInvalid={!!error.paragraphFont}
            isSelectData
          >
            <SelectDataCustom
              id="sel-paragraph-font"
              data={fonts.map((font) => {
                return { key: font.family, value: font.family };
              })}
              dataCy="select-paragraph-font"
              defaultValue={paragraphFont?.family ?? fonts[0].family}
              name="select-paragraph-font"
              onValueChange={handleParagraphFontSelectChange}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'paragraphFont');
              }}
              zIndex={9999}
            />
            <LabelSelectData data-cy="text-paragraph-font">
              Text Font
            </LabelSelectData>
          </Fieldset>
          <ErrorMessageContainer>
            {error.paragraphFont && (
              <ErrorMessage data-cy={error.paragraphFont}>
                {error.paragraphFont}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>

          <Fieldset
            filled={!!paragraphFontSize || !!paragraphFontSizeUnit}
            isInvalid={
              !!error.paragraphFontSize || !!error.paragraphFontSizeUnit
            }
          >
            <Input
              data-cy="paragraph-font-size"
              id="paragraph-font-size"
              max={100}
              min={1}
              step={1}
              onChange={handleParagraphFontSizeChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              required
              type="number"
              value={paragraphFontSize}
            />

            <SelectDataCustom
              data={fontUnitsData.map((font) => {
                return { key: font.key, value: font.value };
              })}
              dataCy="select-paragraph-font-unit"
              defaultValue={paragraphFontSizeUnit}
              name="select-paragraph-font-unit"
              onValueChange={handleParagraphFontUnitChange}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'paragraphFontUnit');
              }}
              zIndex={9999}
            />
            <Label
              data-cy="text-paragraph-font-unit"
              htmlFor="paragraph-font-size"
            >
              Text Font Size
            </Label>
          </Fieldset>
          <ErrorMessageContainer>
            {error.paragraphFontSize && (
              <ErrorMessage data-cy={error.paragraphFontSize}>
                {error.paragraphFontSize}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>

          <ColorPickerInput
            color={paragraphFontColor}
            dataCy="paragraph-color"
            errorMessage={error.paragraphFontColor}
            label="Text color"
            onChange={handleParagraphFontColorChange}
          />

          <ColorPickerInput
            color={buttonColor}
            dataCy="background-button-color"
            errorMessage={error.buttonBackgroundColor}
            label="Background button color"
            onChange={handleButtonBackgroundColorChange}
          />

          <ColorPickerInput
            color={buttonFontColor}
            dataCy="button-color"
            errorMessage={error.buttonFontColor}
            label="Button font color"
            onChange={handleButtonFontColorChange}
          />

          <ColorPickerInput
            color={primaryColor}
            dataCy="primary-color"
            errorMessage={error.primaryColor}
            label="Primary color"
            onChange={handlePrimaryColorChange}
          />

          <ColorPickerInput
            color={secondaryColor}
            dataCy="secondary-color"
            errorMessage={error.secondaryColor}
            label="Secondary color"
            onChange={handleSecondaryColorChange}
          />

          <ColorPickerInput
            color={hueRotateBaseColor}
            dataCy="hue-rotate"
            errorMessage={error.hueRotate}
            label="Template Images Tint"
            onChange={handleHueRotateBaseColorChange}
            customPreview={
              <ColorPreview
                style={{
                  backgroundColor: hueRotateBaseColor,
                  filter: `hue-rotate(${hueRotate}deg)`,
                }}
                title="Preview Base Color with Hue Rotate"
              />
            }
            customInput={(_handleColorChange) => (
              <>
                <Input
                  data-cy="hue-input"
                  id="hue"
                  max={360}
                  min={0}
                  onChange={handleHueRotateChange}
                  required
                  step={1}
                  value={hueRotate}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type="number"
                />
                <SliderCustom
                  max={360}
                  min={0}
                  onValueChange={handleHueRotateSliderChange}
                  value={hueRotateSlider}
                />
              </>
            )}
          />

          <TemplateImagesColorsSection hasColors={hasAnyTemplateColors}>
            <TemplateImagesColorsTitle>
              Template Images Colors
            </TemplateImagesColorsTitle>

            {templatePrimaryColor && (
              <ColorPickerInput
                color={templatePrimaryColor}
                dataCy={`template-color-${templatePrimaryColor}`}
                errorMessage={error.templatePrimaryColor}
                label="Primary Color"
                onChange={handleTemplatePrimaryColorChange}
                required={false}
              />
            )}

            {templateColors.secondaryColor && (
              <ColorPickerInput
                color={templateColors.secondaryColor}
                dataCy={`template-color-${templateColors.secondaryColor}`}
                errorMessage={error.templateSecondaryColor}
                label="Secondary Color"
                onChange={handleTemplateSecondaryColorChange}
                required={false}
              />
            )}

            {templateColors.backgroundColor && (
              <ColorPickerInput
                color={templateColors.backgroundColor}
                dataCy={`template-color-${templateColors.backgroundColor}`}
                errorMessage={error.templateBackgroundColor}
                label="Background Color"
                onChange={handleTemplateBackgroundColorChange}
                required={false}
              />
            )}

            {templateColors.accentColor && (
              <ColorPickerInput
                color={templateColors.accentColor}
                dataCy={`template-color-${templateColors.accentColor}`}
                errorMessage={error.templateAccentColor}
                label="Accent Color"
                onChange={handleTemplateAccentColorChange}
                required={false}
              />
            )}

            {Object.keys(templateAdditionalColors).length > 0 && (
              <BaseCollapsibleMenu
                baseItemText="Additional Colors"
                scrollToBottomFunction={() => {
                  additionalColorsBottomRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              >
                {Object.entries(templateAdditionalColors).map(
                  ([key, color]) => (
                    <ColorPickerInput
                      key={key}
                      color={color}
                      dataCy={`template-color-${key}`}
                      errorMessage={
                        error.templateAdditionalColors
                          ? error.templateAdditionalColors[key]
                          : ''
                      }
                      label="Template Color"
                      onChange={handleTemplateColorsChange}
                      colorKey={key}
                    />
                  )
                )}
              </BaseCollapsibleMenu>
            )}

            <div ref={additionalColorsBottomRef}></div>

            {!hasAnyTemplateColors && (
              <>
                <ColorPickerInput
                  color={templatePrimaryColor}
                  required={false}
                  dataCy="no-color-primary"
                />
                <ColorPickerInput
                  color={templateColors.secondaryColor}
                  required={false}
                  dataCy="no-color-secondary"
                />
                <ColorPickerInput
                  color={templateColors.backgroundColor}
                  required={false}
                  dataCy="no-color-background"
                />
                <ColorPickerInput
                  color={templateColors.accentColor}
                  required={false}
                  dataCy="no-color-accent"
                />
                <OverlayDiv>
                  <OverlayText>
                    You&apos;d need an SVG to get the templates color
                  </OverlayText>
                </OverlayDiv>
              </>
            )}
          </TemplateImagesColorsSection>
        </Container>
      )}
    </FormModalCustom>
  );
};

interface IColorPickerInputProps {
  color: string;
  customInput?: (handleColorChange: (hex: string) => void) => ReactNode;
  customPreview?: ReactNode;
  dataCy?: string;
  errorMessage?: string;
  label?: string;
  onChange?: (color: string, colorKey: string | null) => void;
  colorKey?: string | null;
  required?: boolean;
}

// create a function component to color picker inputs with the fieldset
const ColorPickerInput = ({
  color,
  customInput,
  customPreview,
  dataCy = 'no-color',
  errorMessage,
  label,
  onChange,
  colorKey = null,
  required = true,
}: IColorPickerInputProps) => {
  const [error, setError] = useState<string>('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  const [hexColorValue, setHexColorValue] = useState(color);

  const handleColorChange = useCallback(
    (newColor: ColorResult) => {
      onChange?.(newColor.hex, colorKey);
      setHexColorValue(newColor.hex);
    },
    [colorKey, onChange]
  );

  const handleParagraphHexChange = (hex: string) => {
    if (hex.length <= 7) {
      setHexColorValue(hex);
      if (/^#([A-Fa-f0-9]{6})$/i.test(hex)) {
        onChange?.(hex, colorKey);
        setError('');
      } else {
        setError('Invalid color (Example: #000000)');
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node | null)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {showColorPicker && (
        <div data-cy={`${dataCy}-picker-container`} ref={colorPickerRef}>
          <CustomChromePicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChange}
            disableAlpha
          />
        </div>
      )}
      <ColorPickerContainer>
        {customPreview ?? <ColorPreview style={{ backgroundColor: color }} />}

        <Fieldset
          data-cy={`field-set`}
          filled={!!color}
          isInvalid={!!(error || errorMessage)}
          isSelectData
        >
          {customInput ? (
            customInput(handleParagraphHexChange)
          ) : (
            <Input
              data-cy={`${dataCy}-input`}
              id={`${dataCy}`}
              value={hexColorValue}
              onChange={(e) => {
                handleParagraphHexChange(e.target.value);
              }}
              required={required}
              type="text"
              disabled={!color}
            />
          )}
          {label && (
            <LabelSelectData data-cy={`${dataCy}-label`}>
              {label}
            </LabelSelectData>
          )}
          <ColorPickerIcon
            onClick={() => {
              if (color) {
                setShowColorPicker(!showColorPicker);
              }
            }}
          />
        </Fieldset>
      </ColorPickerContainer>
      <ErrorMessageContainer>
        {(error || errorMessage) && (
          <ErrorMessage data-cy={error || errorMessage}>
            {error || errorMessage}
          </ErrorMessage>
        )}
      </ErrorMessageContainer>
    </>
  );
};
