import { MdClose, MdSearch } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';

import {
  colors,
  radii,
  scrollbar,
  shadows,
} from '@gbs-monorepo-packages/styles';
import * as DialogPrimitive from '@radix-ui/react-dialog';

const overlayShow = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const contentShow = keyframes`
  0% { opacity: 0; transform: 'translate(-50%, -48%) scale(.96)' };
  100% { opacity: 1; transform: 'translate(-50%, -50%) scale(1)' };
`;

export const DialogOverlay = styled(DialogPrimitive.Overlay)`
  background-color: ${colors.blackTranslucent400};

  inset: 0;
  position: fixed;
  z-index: 10;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }
`;

export const DialogContent = styled(DialogPrimitive.Content)`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  box-shadow: ${shadows.modal};
  left: 50%;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }

  &:focus {
    outline: none;
  }
`;

export const CloseIconContainer = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  color: ${colors.black300};
  font-family: inherit;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
  &:focus {
    box-shadow: ${shadows.modal};
  }
`;

export const CloseIcon = styled(MdClose)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchCourseHeader = styled.div`
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 1rem;
  display: flex;
`;

export const SearchCourseInputContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.lightGray200};
  border-radius: ${radii.sm};

  &:hover {
    background-color: ${colors.gray200};
  }
`;

export const SearchCourseInput = styled.input`
  width: 100%;
  height: 2.2rem;
  background-color: inherit;
  border: 0;
  border-radius: 0 6px 6px 0;
  padding-left: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: inherit;
  border-radius: ${radii.sm} 0 0 6px;
  padding: 0 0.5rem 0 1rem;
  cursor: pointer;
`;

export const SearchContent = styled.div`
  overflow: auto;

  ${scrollbar};
`;

export const SearchResultsList = styled.ul``;

export const SearchItem = styled.li`
  padding: 1rem 0.8rem;
  border-bottom: ${colors.gray100} 0.1em solid;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const SearchItemTitleContainer = styled.div`
  padding: 0.3rem 0;
`;

export const SearchItemTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
`;

export const SearchItemContentContainer = styled.div``;

export const SearchItemContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  color: ${colors.gray300};
`;

export const SearchResultsLabelContainer = styled.div`
  padding: 1rem;
`;

export const SearchResultsLabel = styled.p``;

export const CloseButtonContainer = styled.div`
  padding: 0 2rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIcon = styled(MdSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;
