import { type IApiThrowsError, type IResponseData, api } from './api';
import { type ICoursePageDTO } from './coursePages';
import { type IFontDTO, type IGlobalStyleDTO } from './courses';
import { type IClientFileDTO, type IDocumentDTO } from './documents';

export interface IViewCourseDTO {
  id: number;
  headerImage: IDocumentDTO | null;
  pages: ICoursePageDTO[];
  passcode?: string;
  passcodeProtected: boolean;
  title: string;
  viewURL: string | null;
  fonts: IFontDTO[];
  globalStyle: IGlobalStyleDTO | never[] | null;
  courseFile: IClientFileDTO | null;
}

interface IPostCoursePasscodeProps {
  viewURL: string;
  passcode: string;
  language?: string;
}

export type IGetCoursesByViewURLProps = Pick<IViewCourseDTO, 'viewURL'>;
export type IPostCoursesByViewURLProps = IPostCoursePasscodeProps;

export const getViewCourse = async ({
  viewURL,
}: IGetCoursesByViewURLProps): Promise<IViewCourseDTO> => {
  const result = await api
    .get<IResponseData<IViewCourseDTO>>(`view-course/${viewURL ?? ''}`)
    .then((resp) => resp.data.data)
    .catch((error: IApiThrowsError) => {
      throw error;
    });

  return result;
};

export const postViewCourse = async ({
  viewURL,
  passcode,
  language,
}: IPostCoursesByViewURLProps): Promise<IViewCourseDTO> => {
  const result = await api
    .post<IResponseData<IViewCourseDTO>>(`view-course/${viewURL}`, {
      passcode,
      language,
    })
    .then((resp) => resp.data.data)
    .catch((error: IApiThrowsError) => {
      throw error;
    });

  return result;
};
