import styled from 'styled-components';

export const SearchTemplatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const SearchBarContainer = styled.div`
  flex-grow: 1;
`;

export const SearchTypeContainer = styled.div`
  width: 40%;
`;
