import { type Editor, type ResizerOptions } from 'grapesjs';

import { tableLayoutCellType } from '../..';
import {
  DEFAULT_CELL_DIM,
  RESIZE_KEY_DIMENSION,
  defaultResizeCellOptions,
} from '../../../../../../constants/grapes';
import {
  type IResizeCellCustomConfig,
  handlerResizeCellTableLayout,
} from '../../../../Customs/HandleTables/handlers/ResizeCellTableLayout';
import {
  type IBlockContent,
  type IComponentProperties,
} from '../../../../interfaces';
import TableLayoutCellStyle from './styles.css?inline';

export const tableLayoutCellClass = 'table-layout-cell';

export interface IDefaultTableLayoutCellProps {
  initWithText?: boolean;
}

export const DefaultTableLayoutCell = (
  options: IDefaultTableLayoutCellProps = {}
): IComponentProperties => {
  const { initWithText = false } = options;
  const cell: IBlockContent = {
    type: tableLayoutCellType,
    attributes: {
      class: tableLayoutCellClass,
    },
    components: [
      {
        content: initWithText ? 'Insert_Here' : '',
        tagName: 'p',
        type: 'text',
        style: {
          margin: 'unset',
        },
      },
    ],
    styles: TableLayoutCellStyle,
    style: {
      [RESIZE_KEY_DIMENSION]: DEFAULT_CELL_DIM,
    },
  };

  return cell;
};

type IResizerCustomOptions = IResizeCellCustomConfig & ResizerOptions;

export const generateCellResizeOptions = (
  editor: Editor,
  resizeOptions: ResizerOptions
): IResizerCustomOptions => ({
  ...defaultResizeCellOptions,
  ...resizeOptions,
  // eslint-disable-next-line no-restricted-syntax
  onEnd(_event, options) {
    // Overwriting `onEnd` method
    handlerResizeCellTableLayout(editor, options);

    // default `onEnd` Behavior, required to be called
    editor.getWrapper()?.removeClass('gjs-resizing');
    editor.trigger('component:resize');
    editor.Canvas.toggleFramesEvents(true);
    // showOffsets = true; // this line is a default behavior and maybe is required, but we haven´t access to this variable
  },
});
