import { type Editor } from 'grapesjs';

import { getCommandsInfo } from '../../../../utils/grapes';
import type { IButtonProps, ICommandInfo } from '../../interfaces';

export const cmdCommandsInfo = 'commands-info';

const iconStyle = 'style="display: block; max-width:22px"';

export interface ICommandsInfoPluginOptions {
  handlerOpenCommandsInfo: (editor: Editor) => void;
  setCommandsInfo: (commandsInfo: ICommandInfo[]) => void;
}

const CommandsInfoPlugin = (
  editor: Editor,
  { handlerOpenCommandsInfo, setCommandsInfo }: ICommandsInfoPluginOptions
): void => {
  setCommandsInfo(getCommandsInfo(editor));

  const buttonCommandsInfoOptions: IButtonProps = {
    id: cmdCommandsInfo,
    className: '',
    command: cmdCommandsInfo,
    attributes: { title: 'Commands Info', id: cmdCommandsInfo },
    active: false,
    tagName: 'span',
    label: `<svg ${iconStyle} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M11 7h2v2h-2V7zm0 4h2v6h-2v-6zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>`,
    context: cmdCommandsInfo,
    buttons: [],
    options: {},
    dragDrop: false,
    togglable: true,
    runDefaultCommand: true,
    stopDefaultCommand: false,
    disable: false,
  };

  const _buttonCommandsInfo = editor.Panels.addButton(
    'options',
    buttonCommandsInfoOptions
  );

  editor.Commands.add(cmdCommandsInfo, {
    run(editor) {
      handlerOpenCommandsInfo(editor);
    },
  });
};

export default CommandsInfoPlugin;
