import { type CanvasSpot, type Editor } from 'grapesjs';
import { type MouseEvent } from 'react';

import { useSpot } from '../hooks';
import {
  AddIcon,
  SpotAddColumnLeftButtonContainer,
  SpotAddColumnRightButtonContainer,
} from './styles';

export interface ITableCellSpotProps {
  editor?: Editor;
  spot?: CanvasSpot;
}

export const TableCellSpot = (_props: ITableCellSpotProps): JSX.Element => {
  const { appendCell } = useSpot();

  const addCellLeft = ({ ctrlKey }: MouseEvent): void => {
    appendCell({ addBefore: true, isolateAction: ctrlKey });
  };

  const addCellRight = ({ ctrlKey }: MouseEvent): void => {
    appendCell({ addBefore: false, isolateAction: ctrlKey });
  };

  return (
    <>
      <SpotAddColumnLeftButtonContainer
        data-cy="add-column-left-button"
        onClick={addCellLeft}
      >
        <AddIcon />
      </SpotAddColumnLeftButtonContainer>
      <SpotAddColumnRightButtonContainer
        data-cy="add-column-right-button"
        onClick={addCellRight}
      >
        <AddIcon />
      </SpotAddColumnRightButtonContainer>
    </>
  );
};
