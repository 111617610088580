import { type ReactNode, useCallback, useState } from 'react';

import { BaseCheckbox } from '@gbs-monorepo-packages/common';

import {
  FormPopover,
  type IFormPopoverProps,
} from '../../../../../components/FormPopover';
import {
  CheckboxContainer,
  CheckboxLabel,
  Fieldset,
  Input,
  Label,
} from './styles';

interface ISearchComponentsPopoverProps
  extends Partial<
    Omit<IFormPopoverProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (newSearch: string, isOnlyInsideSelection: boolean) => void;
  onDecline: () => void;
  open: boolean;
  trigger: ReactNode;
  waitToOpen?: boolean;
}

export const SearchComponentsPopover = ({
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  ...props
}: ISearchComponentsPopoverProps): JSX.Element | null => {
  const [search, setSearch] = useState('');
  const [isOnlyInsideSelection, setIsOnlyInsideSelection] = useState(false);

  const handleSearch = useCallback(async () => {
    const newSearch = search.trim();
    if (!newSearch) {
      return;
    }

    onAccept?.(newSearch, isOnlyInsideSelection);

    setSearch('');
  }, [isOnlyInsideSelection, onAccept, search]);

  const handleDeclineSearch = useCallback(() => {
    onDecline?.();
    setSearch('');
  }, [onDecline]);

  return (
    <FormPopover
      acceptText="Search"
      dataCy="search-components-dialog-popover"
      mainText="Search your components"
      {...props}
      onAccept={() => {
        void handleSearch();
      }}
      onOpenChange={handleDeclineSearch}
      open={open && !waitToOpen}
    >
      <Fieldset data-cy="field-set" filled={!!search}>
        <Input
          data-cy="search-input"
          defaultValue={search}
          id="search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={`Search (e.g. ".class", "#id", "tag")`}
          required
          type="text"
        />
        <Label data-cy="search-label" htmlFor="search">
          Search
        </Label>
      </Fieldset>
      <CheckboxContainer data-cy="search-only-from-selection-container">
        <BaseCheckbox
          checked={isOnlyInsideSelection}
          dataCy="search-only-from-selection-checkbox"
          id="search-only-from-selection-checkbox"
          name="search-only-from-selection"
          onCheckedChange={(checked) => {
            setIsOnlyInsideSelection(
              checked === 'indeterminate' ? false : checked
            );
          }}
        />
        <CheckboxLabel
          data-cy="search-only-from-selection-label"
          htmlFor="search-only-from-selection-checkbox"
        >
          Search only inside selection
        </CheckboxLabel>
      </CheckboxContainer>
    </FormPopover>
  );
};
