import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const FieldsetCheckBox = styled.fieldset`
  all: unset;
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  min-height: 1.5rem;
`;

export const LabelCheckBox = styled.label`
  color: ${colors.labelText};
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: hidden;
  padding: 1px 6px;
  user-select: none;
`;

export const CheckContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

export const Label = styled(LabelCheckBox)`
  background-color: ${colors.white300};
  left: 16px;
  max-width: 90%;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  white-space: nowrap;
  z-index: 1;
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;
