import { type IUserDTO } from '@gbs-monorepo-packages/auth';

type IUser = Pick<IUserDTO, 'permissions' | 'roles'>;

interface IValidateUserPermissionsParams {
  user: IUser;
  permissions?: string[];
  roles?: string[];
}

/**
 * @deprecated
 */
export const validateUserPermissions = ({
  user,
  permissions = [],
  roles = [],
}: IValidateUserPermissionsParams): boolean => {
  const { permissions: userPermissions = [], roles: userRoles = [] } = user;
  if (permissions.length) {
    if (!userPermissions.length) {
      return false;
    }

    const hasAllPermissions = permissions.every((permission) => {
      return userPermissions.includes(permission);
    });

    if (!hasAllPermissions) {
      return false;
    }
  }

  if (roles.length) {
    if (!userRoles.length) {
      return false;
    }

    const hasSomeRole = roles.some((role) => {
      return userRoles.includes(role);
    });

    if (!hasSomeRole) {
      return false;
    }
  }

  return true;
};
