import { produce } from 'immer';
import { type ReactNode, createContext, useCallback, useState } from 'react';

import * as CoursePageService from '../services/coursePages';
import * as TemplatePageService from '../services/templates';
import Logger from '../utils/logger';
import { ByPageNumber } from '../utils/sortObjects';

export type ICoursePage = CoursePageService.ICoursePageDTO & {
  isNew?: boolean;
  isNewFromTemplate?: boolean;
  edited?: boolean;
};

export type ITemplatePage = TemplatePageService.ITemplateDTO & {
  isNew?: boolean;
  edited?: boolean;
};

export interface IDeleteCoursePageInfo {
  idRemoved: number;
  indexRemoved: number;
  indexToSelected?: number;
  newCoursePages: ICoursePage[];
}

export interface IDeleteMultiplePagesInfo {
  idsRemoved: number[];
  courseId: number;
  newCoursePages: ICoursePage[];
}

export interface ICoursePagesContextData {
  addCoursePage: (newPage: ICoursePage, to: number) => void;
  changeCoursePagesOrder: (from: number, to: number) => void;
  changeCoursePageContent: (
    index: number,
    {
      cssContent,
      hasWrapper,
      htmlContent,
      title,
      components,
    }: Partial<
      Pick<
        ICoursePage,
        'components' | 'cssContent' | 'hasWrapper' | 'htmlContent' | 'title'
      >
    >
  ) => ICoursePage[];
  coursePages: ICoursePage[];
  templatePages: ITemplatePage[];
  deleteCoursePage: (id: number) => Promise<IDeleteCoursePageInfo | never>;
  deleteMultipleCoursePages: (
    pageTemplateIds: number[],
    courseId: number,
    tmpCoursePages: ICoursePage[]
  ) => Promise<IDeleteMultiplePagesInfo>;
  overwriteCoursePages: (
    newCoursePages: ICoursePage[],
    disableDeepClone?: boolean
  ) => ICoursePage[];
  overwriteTemplatePages: (
    newCoursePages: ITemplatePage[],
    deepClone?: boolean
  ) => ITemplatePage[];
  selectedCoursePageIndex: number;
  selectCoursePageIndex: (pageIndex: number) => void;
  syncCoursePages: (
    companyId: number,
    selectCoursePageId?: number | string
  ) => Promise<ICoursePage[] | never>;
  syncTemplatePage: (templateId: number) => Promise<ITemplatePage[] | never>;
  getPageWithIndex: (index: number) => ICoursePage | undefined;
}

interface ICoursePagesProps {
  children: ReactNode;
}

export const CoursePagesContext = createContext<ICoursePagesContextData>(
  {} as ICoursePagesContextData
);

export const CoursePagesProvider = ({
  children,
}: ICoursePagesProps): JSX.Element => {
  const [coursePages, setCoursePages] = useState<ICoursePage[]>([]);
  const [templatePages, setTemplatePages] = useState<ITemplatePage[]>([]);

  const [selectedCoursePageIndex, setSelectedCoursePageIndex] = useState(-1);

  const addCoursePage = useCallback((newPage: ICoursePage, to: number) => {
    setCoursePages(
      produce((draft) => {
        draft.splice(to, 0, newPage);
      })
    );
  }, []);

  const changeCoursePagesOrder = useCallback((from: number, to: number) => {
    if (to === from) {
      return;
    }
    setCoursePages(
      produce((draft) => {
        const [dragged] = draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      })
    );
  }, []);

  const getPageWithIndex = useCallback(
    (index: number): ICoursePage | undefined => {
      return coursePages.at(index);
    },
    [coursePages]
  );

  const changeCoursePageContent = useCallback(
    (
      index: number,
      {
        cssContent,
        hasWrapper,
        htmlContent,
        title,
        components,
      }: Partial<
        Pick<
          ICoursePage,
          'components' | 'cssContent' | 'hasWrapper' | 'htmlContent' | 'title'
        >
      >
    ): ICoursePage[] => {
      let nextState: ICoursePage[] = [];
      setCoursePages((state) => {
        nextState = produce(state, (draft) => {
          if (draft.at(index)) {
            draft[index].edited = true;

            if (components !== undefined) draft[index].components = components;
            if (cssContent !== undefined) draft[index].cssContent = cssContent;
            if (hasWrapper !== undefined) draft[index].hasWrapper = hasWrapper;
            if (htmlContent !== undefined)
              draft[index].htmlContent = htmlContent;
            if (title !== undefined) draft[index].title = title;
          }
        });
        return nextState;
      });
      return nextState;
    },
    []
  );

  const deleteCoursePage = useCallback(
    async (index: number): Promise<IDeleteCoursePageInfo | never> => {
      const coursePage = coursePages.at(index);
      if (!coursePage) throw Error('Page to delete not found');

      const { id, isNew = false } = coursePage;
      if (id > 0 && !isNew) {
        await CoursePageService.deleteCoursePage({ id });
      }

      const newCoursePages = produce(coursePages, (draft) => {
        draft.splice(index, 1);
      });

      setCoursePages(newCoursePages);

      return { idRemoved: id, newCoursePages, indexRemoved: index };
    },
    [coursePages]
  );

  const overwriteCoursePages = useCallback(
    (
      newCoursePages: ICoursePage[],
      disableDeepClone = false
    ): ICoursePage[] => {
      const coursePages: ICoursePage[] = !disableDeepClone
        ? JSON.parse(JSON.stringify(newCoursePages))
        : newCoursePages;

      setCoursePages(coursePages);
      return coursePages;
    },
    []
  );

  const overwriteTemplatePages = useCallback(
    (newTemplatePages: ITemplatePage[], deepClone = true): ITemplatePage[] => {
      const templatePage: ITemplatePage[] = deepClone
        ? JSON.parse(JSON.stringify(newTemplatePages))
        : newTemplatePages;

      setTemplatePages(templatePage);
      return templatePage;
    },
    []
  );

  const refreshCoursePages = useCallback(
    async (
      courseId: number,
      coursePageIndex = 0
    ): Promise<CoursePageService.ICoursePageDTO[] | never> => {
      const result = await CoursePageService.getCoursePagesByCourseId({
        courseId,
      });

      if (!result.length) {
        setSelectedCoursePageIndex(-1);
        setCoursePages([]);
        return [];
      }

      result.sort(ByPageNumber<ICoursePage>);

      setSelectedCoursePageIndex(coursePageIndex);
      setCoursePages(result);
      return result;
    },
    []
  );

  const selectCoursePageIndex = useCallback((pageIndex: number): void => {
    setSelectedCoursePageIndex(pageIndex);
  }, []);

  const syncCoursePages = useCallback(
    async (companyId: number): Promise<CoursePageService.ICoursePageDTO[]> => {
      try {
        return await refreshCoursePages(companyId, selectedCoursePageIndex);
      } catch (err) {
        Logger.debug('err:', err);
        return [];
      }
    },
    [refreshCoursePages, selectedCoursePageIndex]
  );

  const deleteMultipleCoursePages = useCallback(
    async (
      pagesDelete: number[],
      courseId: number,
      tmpCoursePages: ICoursePage[]
    ): Promise<IDeleteMultiplePagesInfo> => {
      const pagesToDelete = pagesDelete.filter((id) => {
        const coursePage = tmpCoursePages.find((page) => page.id === id);
        return !coursePage?.isNew;
      });

      if (pagesToDelete.length) {
        await CoursePageService.deleteMultipleCoursePages(
          pagesToDelete,
          courseId
        );
      }

      let newCoursePages = produce(tmpCoursePages, (draft) => {
        pagesDelete.forEach((id) => {
          const index = draft.findIndex((page) => page.id === id);
          if (index > -1) {
            draft.splice(index, 1);
          }
        });
      });

      const syncBackPages = await syncCoursePages(courseId);

      // Check if newCoursePages is extensible
      if (!Object.isExtensible(newCoursePages)) {
        // Create a new array if it is not extensible
        newCoursePages = [...newCoursePages];
      }

      if (newCoursePages.length > 0) {
        syncBackPages.forEach((page) => {
          const index = newCoursePages.findIndex((p) => p.id === page.id);
          if (index === -1) {
            newCoursePages.push(page);
          }
        });
      } else {
        newCoursePages = [...newCoursePages, ...syncBackPages];
      }

      setCoursePages(newCoursePages);

      return { idsRemoved: pagesToDelete, courseId, newCoursePages };
    },
    [syncCoursePages]
  );

  const refreshTemplatePages = useCallback(
    async (
      id: number
    ): Promise<TemplatePageService.ITemplatePageDTO[] | never> => {
      const result = await TemplatePageService.getTemplatePage({ id });

      setTemplatePages([
        {
          id: result.id,
          cssContent: result.cssContent,
          htmlContent: result.htmlContent,
          hasWrapper: result.hasWrapper,
          title: result.title,
          createdAt: result.createdAt,
          updatedAt: result.updatedAt,
          idExternal: result.idExternal,
          components: result.components,
          templateColorsFound: result.templateColorsFound,
        },
      ]);

      return [result];
    },
    []
  );

  const syncTemplatePage = useCallback(
    async (
      templateId: number
    ): Promise<TemplatePageService.ITemplateDTO[] | never> => {
      try {
        return await refreshTemplatePages(templateId);
      } catch (err) {
        Logger.debug('err:', err);
        return [];
      }
    },
    [refreshTemplatePages]
  );

  return (
    <CoursePagesContext.Provider
      value={{
        addCoursePage,
        changeCoursePagesOrder,
        changeCoursePageContent,
        coursePages,
        templatePages,
        deleteCoursePage,
        deleteMultipleCoursePages,
        overwriteCoursePages,
        overwriteTemplatePages,
        selectedCoursePageIndex,
        selectCoursePageIndex,
        syncCoursePages,
        syncTemplatePage,
        getPageWithIndex,
      }}
    >
      {children}
    </CoursePagesContext.Provider>
  );
};
