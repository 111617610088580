import styled from 'styled-components';

import { BaseLoading } from '@gbs-monorepo-packages/common';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;
