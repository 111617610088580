import { useNavigate } from 'react-router-dom';

import {
  Container,
  Footer,
  FooterLines,
  FooterLinesLinks,
  LazyImageCustom,
  Main,
  MainActionContainer,
  MainButton,
  MainContent,
  MainText,
} from './styles';

export const Home = (): JSX.Element => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Container data-cy="home-container">
      <Main data-cy="main-container">
        <MainContent data-cy="main-text-content">
          <LazyImageCustom
            src={'/logo.png'}
            alt="logo-image"
            dataCy={'image-logo'}
          />
          <MainText data-cy="text-description">
            Benefit Education is the easiest way to handle your{' '}
            <strong>benefits experience</strong>,<br /> made for employers,
            brokers, and vendors.
          </MainText>
          <MainActionContainer data-cy="main-actions-container">
            <MainButton onClick={goToLogin} data-cy="button-login">
              LOGIN
            </MainButton>
          </MainActionContainer>
        </MainContent>
      </Main>
      <Footer data-cy="footer-container">
        <FooterLinesLinks data-cy="terms-information">
          <a href="mailto:edge@gbsbenefits.com" data-cy="button-contactUs">
            Contact Us
          </a>
        </FooterLinesLinks>
        <FooterLines data-cy="copyright-container">
          <p data-cy="text-copyRight">GBS Benefits, Inc.</p>
        </FooterLines>
      </Footer>
    </Container>
  );
};
