import type { Component, Editor } from 'grapesjs';
import { type RefObject } from 'react';

import {
  findInsideComponents,
  runCommandIfCanvasFocussed,
  stopPropagationFirstEvent,
} from '../../../../utils/grapes';
import { type IButtonProps } from '../../interfaces';

export const cmdSearchComponents = 'search-components';
export const cmdOpenSearchComponents = 'open-search-components';
export const shortcutOpenSearchComponents = '⌘+shift+f, ctrl+shift+f';

const iconStyle = 'style="display: block; max-width:22px"';

export interface ISearchComponentsOptions extends Record<string, any> {
  newSearch: string;
  isOnlyInsideSelection: boolean;
}

export const handlerSearchComponents = (
  { Commands }: Editor,
  options: ISearchComponentsOptions
): Component[] => {
  const isRunning = Commands.isActive(cmdSearchComponents);

  if (isRunning) throw new Error('Search components is already running');

  return Commands.run(cmdSearchComponents, options) as Component[];
};

export interface ISearchComponentsPluginOptions {
  handlerOpenSearchComponents: (editor: Editor) => void;
  buttonRef: RefObject<HTMLDivElement>;
}

const SearchComponentsPlugin = (
  { Commands, Keymaps, Panels }: Editor,
  { handlerOpenSearchComponents, buttonRef }: ISearchComponentsPluginOptions
): void => {
  Keymaps.add(
    cmdOpenSearchComponents,
    shortcutOpenSearchComponents,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);

      runCommandIfCanvasFocussed(cmdOpenSearchComponents, editor);
    }
  );

  const buttonSearchComponentsOptions: IButtonProps = {
    id: cmdOpenSearchComponents,
    className: '',
    command: cmdOpenSearchComponents,
    attributes: { title: 'Search components', id: cmdOpenSearchComponents },
    active: false,
    tagName: 'span',
    label: `<svg ${iconStyle} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7l-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59 20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3zM2 19h10v-2H2v2z"></path></svg>`,
    context: cmdOpenSearchComponents,
    buttons: [],
    options: {},
    dragDrop: false,
    togglable: false,
    runDefaultCommand: true,
    stopDefaultCommand: false,
    disable: false,
  };

  Panels.addButton('options', buttonSearchComponentsOptions);

  Commands.add(cmdOpenSearchComponents, {
    // eslint-disable-next-line no-restricted-syntax
    run(editor) {
      const divTrigger = buttonRef.current;
      if (!divTrigger) return;

      const spanSearchComponents = document.getElementById(
        cmdOpenSearchComponents
      );
      if (!spanSearchComponents) return;

      const rect = spanSearchComponents.getClientRects().item(0);
      if (!rect) return;

      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollLeft =
        document.documentElement.scrollLeft || document.body.scrollLeft;
      const { style } = divTrigger;

      style.position = 'absolute';
      style.top = `${rect.top + scrollTop}px`;
      style.left = `${rect.left + scrollLeft}px`;
      style.width = `${rect.width}px`;
      style.height = `${rect.height}px`;

      handlerOpenSearchComponents(editor);
    },
  });

  Commands.add(cmdSearchComponents, {
    // eslint-disable-next-line no-restricted-syntax
    run(
      editor,
      _sender,
      opts?: Partial<ISearchComponentsOptions>
    ): Component[] {
      if (!opts) throw new Error('Search components options are required');
      const { newSearch = '', isOnlyInsideSelection = false } = opts;
      const fromComponents = isOnlyInsideSelection
        ? editor.getSelectedAll()
        : [editor.getWrapper()];
      const foundComponents = findInsideComponents(newSearch, fromComponents);
      editor.select(foundComponents);
      return foundComponents;
    },
  });
};

export default SearchComponentsPlugin;
