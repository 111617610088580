import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';

export const buttonLinkId = 'button-link';

const ButtonLinkPlugin = (editor: Editor): void => {
  const ButtonLinkContent: IBlockContent = {
    attributes: { class: 'link-button' },
    content: 'Button Link',
    editable: true,
    tagName: 'a',
    type: 'link',
  };

  const CenterContainer: IBlockContent = {
    components: ButtonLinkContent,
    editable: true,
    style: { 'text-align': 'center', width: '100%' },
    tagName: 'div',
  };

  editor.BlockManager.add(buttonLinkId, {
    category: 'Basic',
    content: CenterContainer,
    label: 'Button Link',
    media:
      '<svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"> <defs id="defs2" /> <g id="layer1"> <path id="path25" d="m 2.8841272,7.2934849 c -0.8999983,0 -1.4003906,0.4 -1.4003906,1 v 6.0000001 c 0,0.599998 0.4988296,1 1.2988281,1 H 14.837252 a 2.2415133,2.2415133 0 0 1 -0.01563,-0.1875 2.2415133,2.2415133 0 0 1 0.15625,-0.8125 H 2.4837366 V 8.2934849 H 20.483736 v 4.5703121 h 1 V 8.2934849 c 0,-0.6 -0.500783,-1 -1.300781,-1 z" /> <path d="M 3.4834194,10.793078 H 19.483419 v 1 H 3.4834194 Z" id="path27" /> <path fill="currentColor" d="m 15.832518,15.206238 c 0,-0.766598 0.62314,-1.389739 1.389738,-1.389739 h 1.793211 v -0.851775 h -1.793211 a 2.2415133,2.2415133 0 0 0 -2.241513,2.241514 2.2415133,2.2415133 0 0 0 2.241513,2.241513 h 1.793211 v -0.851775 h -1.793211 c -0.766598,0 -1.389738,-0.623141 -1.389738,-1.389738 m 1.838041,0.448302 h 3.586421 v -0.896605 h -3.586421 v 0.896605 m 4.034724,-2.689816 h -1.793211 v 0.851775 h 1.793211 c 0.766597,0 1.389738,0.623141 1.389738,1.389739 0,0.766597 -0.623141,1.389738 -1.389738,1.389738 h -1.793211 v 0.851775 h 1.793211 a 2.2415133,2.2415133 0 0 0 2.241513,-2.241513 2.2415133,2.2415133 0 0 0 -2.241513,-2.241514 z" id="path195-8" style="stroke-width:0.448303" /> </g> </svg>',
    select: true,
  });
};

export default ButtonLinkPlugin;
