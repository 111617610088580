import { FiServer } from 'react-icons/fi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import {
  MdAnalytics,
  MdGridView,
  MdOutlineFeaturedPlayList,
  MdOutlineFolder,
  MdOutlineSettings,
  MdWebStories,
} from 'react-icons/md';
import { RiBookLine } from 'react-icons/ri';
import { SiBookstack } from 'react-icons/si';
import styled, { css } from 'styled-components';

import { BaseLoading } from '@gbs-monorepo-packages/common';

export const HeaderChildrenContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0 1rem;
`;

export const CompanyName = styled.h2`
  font-size: 1.25rem;
  padding: 0 0.125rem;
`;

const sidebarIconStyles = css`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DashBoardIcon = styled(MdGridView)`
  ${sidebarIconStyles}
`;

export const MembersIcon = styled(RiBookLine)`
  ${sidebarIconStyles}
`;

export const AdminPortalIcon = styled(MdOutlineFeaturedPlayList)`
  ${sidebarIconStyles}
`;

export const CompaniesIcon = styled(HiOutlineOfficeBuilding)`
  ${sidebarIconStyles}
`;

export const CoursesIcon = styled(SiBookstack)`
  ${sidebarIconStyles}
  fill: transparent;
  stroke: currentColor;
  stroke-width: 1px;
`;

export const DocumentsIcon = styled(MdOutlineFolder)`
  ${sidebarIconStyles}
`;

export const SettingsIcon = styled(MdOutlineSettings)`
  ${sidebarIconStyles}
`;

export const TemplatesIcon = styled(MdWebStories)`
  ${sidebarIconStyles}
`;

export const AnalyticsIcon = styled(MdAnalytics)`
  ${sidebarIconStyles}
`;

export const ClientPortalIcon = styled(FiServer)`
  ${sidebarIconStyles}
`;

export const Container = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
`;

export const BaseLoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-10%, -50%);
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;
