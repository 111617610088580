import { type Component, type Editor } from 'grapesjs';

import {
  type IConfigAdd,
  addSiblingsComponents,
  runCommand,
} from '../../../../../../utils/grapes';
import {
  DefaultTableLayoutCell,
  type IDefaultTableLayoutCellProps,
} from '../../../../Blocks/TableLayout/components/DefaultTableCell';

export const cmdAddCellTableLayout = 'add-table-cell';

export interface IAddCellTableLayoutConfig {
  addBefore?: boolean;
  addConfig?: Omit<IConfigAdd, 'at'>;
  cell: Component;
  newCellOptions?: IDefaultTableLayoutCellProps;
  selectAdded?: boolean;
}

export const handlerAddCellTableLayout = (
  editor: Editor,
  config?: IAddCellTableLayoutConfig
): void => {
  runCommand(cmdAddCellTableLayout, editor, config);
};

export const addCellTableLayout = ({
  addBefore = false,
  addConfig,
  cell,
  newCellOptions,
}: IAddCellTableLayoutConfig): Component[] => {
  const at = cell.index() + (addBefore ? 0 : 1);

  const newComponent = DefaultTableLayoutCell(newCellOptions);
  const newComponents = addSiblingsComponents(cell, newComponent, {
    at,
    ...addConfig,
  });
  return newComponents;
};

export const addAddCellTableLayoutCommand = ({ Commands }: Editor): void => {
  Commands.add(cmdAddCellTableLayout, {
    // eslint-disable-next-line no-restricted-syntax
    run(editor, _sender, config: IAddCellTableLayoutConfig) {
      const { selectAdded = true } = config;
      const newComponents = addCellTableLayout(config);
      if (selectAdded) editor.select(newComponents);
      return newComponents;
    },
  });
};
