import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';
import DividerStyle from './styles.css?inline';

export const dividerId = 'horizontal-divider';
export const dividerType = 'horizontal-divider';
export const dividerContainerType = `${dividerType}-container`;

const DividerPlugin = (editor: Editor): void => {
  editor.DomComponents.addType(dividerType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value === dividerType,
    model: {
      defaults: {
        attributes: {
          'custom-type': dividerType,
        },
        tagName: 'div',
        name: 'Divider',
      },
    },
  });

  editor.DomComponents.addType(dividerContainerType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value ===
      dividerContainerType,
    model: {
      defaults: {
        attributes: {
          'custom-type': dividerContainerType,
        },
        tagName: 'div',
        name: 'Divider Container',
      },
    },
  });

  const DividerContent: IBlockContent = {
    attributes: { class: 'horizontal-divider primary-cl' },
    styles: DividerStyle,
    type: dividerType,
  };

  const CenterContainer: IBlockContent = {
    components: DividerContent,
    editable: true,
    style: {
      'text-align': 'center',
      width: '100%',
    },
    type: dividerContainerType,
  };

  editor.BlockManager.add(dividerId, {
    label: 'Divider',
    category: 'Basic',
    content: CenterContainer,
    media:
      '<svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\n<path d="m1.9968 2.7109a0.70766 0.70766 0 0 0 0.70766 0.70766h18.399a0.70766 0.70766 0 0 0 0-1.4153h-18.399a0.70766 0.70766 0 0 0-0.70766 0.70766zm0 18.399a0.70766 0.70766 0 0 0 0.70766 0.70766h18.399a0.70766 0.70766 0 0 0 0-1.4153h-18.399a0.70766 0.70766 0 0 0-0.70766 0.70766z" fill-rule="evenodd" stroke-width="1.4153"/>\n<path d="m3.4121 10.495a1.4153 1.4153 0 0 1 1.4153-1.4153h14.153a1.4153 1.4153 0 0 1 1.4153 1.4153v2.8306a1.4153 1.4153 0 0 1-1.4153 1.4153h-14.153a1.4153 1.4153 0 0 1-1.4153-1.4153z" stroke-width="1.4153"/>\n</svg>',
    select: true,
  });
};

export default DividerPlugin;
