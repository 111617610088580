import { useCallback, useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { FormBase, FormModal } from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type UsersUpdateSchema, userUpdateSchema } from '../../userSchema';

interface IUpdateTeammatesProps {
  onAccept: (data: UsersUpdateSchema) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  currentRole: string;
}

export const ModalUpdateUsers = ({
  onAccept,
  onDecline,
  open,
  loading = false,
  email,
  firstName,
  lastName,
  phone,
  currentRole,
}: IUpdateTeammatesProps): JSX.Element => {
  const updateUsers = useForm<UsersUpdateSchema>({
    resolver: zodResolver(userUpdateSchema),
  });

  const {
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = updateUsers;

  const values = watch();

  const resetForm = useCallback(() => {
    setValue('email', email);
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('phone', phone);
    setValue('currentRole', currentRole);
  }, [email, firstName, lastName, phone, currentRole, setValue]);

  const handleDeclineAddTeammates = useCallback(() => {
    resetForm();
    onDecline?.();
  }, [onDecline, resetForm]);

  useEffect(() => {
    if (!loading) {
      resetForm();
    }
  }, [loading]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const onSubmit: SubmitHandler<UsersUpdateSchema> = (
    data: UsersUpdateSchema
  ) => {
    onAccept?.(data);
  };

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...updateUsers}>
      <FormModal
        acceptText="Update User"
        declineText="Cancel"
        open={open}
        dataCy="add-userForm"
        mainText="Edit User"
        onOpenChange={handleDeclineAddTeammates}
        onDecline={handleDeclineAddTeammates}
        onAccept={handleSubmit(onSubmit)}
        loading={loading}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <FormBase.InputContent
            filled={!!values?.firstName}
            inputRef="firstNameEdit"
            label="First Name"
            errorMessage={errors.firstName?.message}
            dataCy="label-First Name"
          >
            <FormBase.InputText
              dataCy="firstNameEdit-input"
              id="firstNameEdit"
              name="firstName"
              type="text"
              autoComplete="off"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.lastName}
            inputRef="lastNameEdit"
            label="Last Name"
            errorMessage={errors.lastName?.message}
            dataCy="label-Last Name"
          >
            <FormBase.InputText
              dataCy="lastNameEdit-input"
              id="lastNameEdit"
              name="lastName"
              type="text"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.phone}
            inputRef="phoneInvite"
            label="Phone"
            errorMessage={errors.phone?.message}
            dataCy="phone"
          >
            <FormBase.InputText
              dataCy="phoneInvite-input"
              id="phoneInvite"
              name="phone"
              type="text"
              maxLength={15}
            />
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
