import styled from 'styled-components';

import { scrollbar } from '@gbs-monorepo-packages/styles';

export const Content = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
  overflow: auto;

  & .infinite-scroll-component__outerdiv {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & .infinite-scroll-component {
    ${scrollbar};
  }
`;
