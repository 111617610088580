import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as Accordion from '@radix-ui/react-accordion';

export const AccordionTrigger = styled(Accordion.Trigger)`
  display: flex;
  flex-direction: row;

  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  width: 100%;
  height: 45px;

  text-align: left;

  justify-content: space-between;
  align-items: center;
  align-content: flex-start;

  font-size: 20px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;
`;

export const AccordionGroupLabel = styled.span`
  flex-grow: 1;
`;

export const AccordionGroupActions = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-grow: 1;
`;

interface IActionButtonProps {
  readonly isVisible: boolean;
}

export const ActionButton = styled.div<IActionButtonProps>`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background-color: ${colors.backgroundHover};
  }

  ${({ isVisible = false }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

export const AccordionContent = styled(Accordion.Content)`
  flex-grow: 1;

  padding: 20px;
  background-color: ${colors.white300};
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
  font-size: 15px;
  line-height: 1;
`;

export const EditIcon = styled(MdOutlineModeEditOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TemplateCardContainer = styled.div`
  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;
