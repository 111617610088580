import { type Component, type Editor } from 'grapesjs';

import {
  type IConfigAdd,
  addSiblingsComponents,
  isOnRegularTable,
  runCommand,
} from '../../../../../../utils/grapes';
import { type IDefaultTableLayoutCellProps } from '../../../../Blocks/TableLayout/components/DefaultTableCell';
import { DefaultTableLayoutRow } from '../../../../Blocks/TableLayout/components/DefaultTableRow';

export const cmdAddRowTableLayout = 'add-table-row';

export interface IAddRowTableLayoutConfig {
  addBefore?: boolean;
  addConfig?: Omit<IConfigAdd, 'at'>;
  newCellOptions?: IDefaultTableLayoutCellProps;
  persistRegularity?: boolean;
  row: Component | null;
  selectAdded?: boolean;
}

export const handlerAddRowTableLayout = (
  editor: Editor,
  config?: IAddRowTableLayoutConfig
): void => {
  runCommand(cmdAddRowTableLayout, editor, config);
};

export const addRowTableLayout = (
  config: IAddRowTableLayoutConfig
): Component[] => {
  const {
    addBefore = false,
    addConfig,
    newCellOptions,
    persistRegularity = true,
    row,
  } = config;
  if (!row) return [];

  const offset = addBefore ? 0 : 1;

  const newRow = DefaultTableLayoutRow(
    persistRegularity && isOnRegularTable(row) ? row.components().length : 1,
    newCellOptions
  );

  const at = row.index() + offset;
  const trComps = addSiblingsComponents(row, newRow, { at, ...addConfig });
  return trComps ?? [];
};

export const addAddRowTableLayoutCommand = ({ Commands }: Editor): void => {
  Commands.add(cmdAddRowTableLayout, {
    // eslint-disable-next-line no-restricted-syntax
    run(editor, _sender, config: IAddRowTableLayoutConfig) {
      const { selectAdded = true } = config;
      const newComponents = addRowTableLayout(config);
      if (selectAdded) editor.select(newComponents);
      return newComponents;
    },
  });
};
