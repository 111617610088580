import styled from 'styled-components';

import { BaseLoading, CheckBoxGroup } from '@gbs-monorepo-packages/common';
import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

import { TemplateCard } from '../TemplateCard';

export const RadioContainer = styled.div`
  align-items: center;
  color: ${colors.text};
  display: flex;
`;

export const SelectTemplateTypeContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SelectContainer = styled.div`
  height: 200px;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;

  ${scrollbar};
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const TemplateCardCustom = styled(TemplateCard)``;

export const CustomCheckBoxGroup = styled(CheckBoxGroup)`
  color: ${colors.blue300};
  & ${TemplateCardCustom} + ${TemplateCardCustom} {
    margin-top: 0.125rem;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TitleText = styled.p``;

export const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  flex-grow: 1;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
  text-align: center;
  margin-top: 1rem;
`;
