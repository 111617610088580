import { type ImgHTMLAttributes, forwardRef } from 'react';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';

export interface IBaseLoadingProps extends ImgHTMLAttributes<HTMLImageElement> {
  dataCy?: string;
  notLoading?: boolean;
}

export const BaseLoading = forwardRef<HTMLImageElement, IBaseLoadingProps>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseLoading(
    { dataCy = 'loading-spinner', notLoading = false, ...props },
    ref
  ): JSX.Element {
    return (
      <>
        {notLoading || (
          <img
            alt="Loading spinner"
            {...props}
            data-cy={dataCy}
            ref={ref}
            src={LoadingSpinnerAnimated}
          />
        )}
      </>
    );
  }
);
