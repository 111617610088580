import { type InputHTMLAttributes, forwardRef } from 'react';

import { DefaultContentEditable } from './styles';

export interface IContentEditableProps
  extends InputHTMLAttributes<HTMLInputElement> {
  children: string;
  dataCy?: string;
}

export const ContentEditable = forwardRef<
  HTMLInputElement,
  IContentEditableProps
>(({ children, dataCy, ...props }, ref): JSX.Element => {
  return (
    <DefaultContentEditable
      {...props}
      data-cy={dataCy}
      ref={ref}
      value={children}
    />
  );
});

ContentEditable.displayName = 'ContentEditable';
