import {
  MdArrowBack,
  MdBrush,
  MdOutlineSettings,
  MdOutlineViewQuilt,
  MdTextFormat,
} from 'react-icons/md';
import styled from 'styled-components';

import { Button } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

import { ContentEditable } from '../../../../components/ContentEditable';

export const Header = styled.header`
  align-items: center;
  color: ${colors.button};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;

  @media (max-width: 320px) {
    align-self: flex-start;
  }
`;

export const TitlePage = styled(ContentEditable)`
  display: flex;
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BackPageButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.button};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 0.375rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const BackIcon = styled(MdArrowBack)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ExtraCourseContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const ExtraCourseButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.sm};
  color: ${colors.button};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 0.375rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ActionLabel = styled.p`
  color: ${colors.button};
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.375rem;

  @media (max-width: 980px) {
    display: none;
  }
`;

export const FontsIcon = styled(MdTextFormat)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const StyleIcon = styled(MdBrush)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TemplateIcon = styled(MdOutlineViewQuilt)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SettingsIcon = styled(MdOutlineSettings)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ActionCourseButton = styled(Button)``;

export const ActionCourseContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  & > ${ActionCourseButton} + ${ActionCourseButton} {
    margin-left: 1rem;
  }

  @media (max-width: 320px) {
    align-self: flex-end;
  }
`;
