import { type Component, type Editor } from 'grapesjs';

import { stopPropagationFirstEvent } from '../../../../utils/grapes';
import { type IBlockContent } from '../../interfaces';
import BulletPointStyle from './styles.css?inline';
export const bulletItemsId = 'bullet-point';
export const bulletItemsType = 'bullet-points-link';
export const bulletItemsTypeDescription = 'bullet-points-description';
export const bulletItemsContainerTypeUl = `${bulletItemsType}-container-ul`;
export const bulletItemsContainerTypeOl = `${bulletItemsType}-container-ol`;
export const updateComponentType = 'update-component-bullet-type';
export const cmdAddNewBulletItem = 'add-new-bullet-item';
export const shortcutAddNewBulletItem = 'enter';
export const cmdListGoToLeft = 'list-go-to-left';
export const shortcutListGoToLeft = 'shift+tab';
export const cmdListGoToRight = 'list-go-to-right';
export const shortcutListGoToRight = 'tab';
export const cmdListOutdent = 'list-outdent';
export const cmdListMerge = 'list-merge';
export const cmdBreakWithNewLine = 'break-with-new-line';
export const shortcutBreakWithNewLine = 'shift+enter';

interface IComponentDragEvent {
  target: Component;
  parent: Component;
  index: number;
}

interface IOptionsOutdentCommand {
  listToOutdent: Component;
}

interface IOptionsMergeCommand {
  listFrom: Component;
  listTo: Component;
}

let parentComponentDragStart: Component;
let targetComponentDragStartIndex: number;

const BulletPointPlugin = (editor: Editor): void => {
  editor.TraitManager.addType('href-next', {
    // Expects as return a simple HTML string or an HTML element
    createInput({ trait }) {
      // Create a new element container and add some content
      const el = document.createElement('div');
      el.innerHTML = `
      <div class="containerButtonsTrait">
      <button class="buttonTrait bullet-button">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M360-200v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360ZM200-160q-33 0-56.5-23.5T120-240q0-33 23.5-56.5T200-320q33 0 56.5 23.5T280-240q0 33-23.5 56.5T200-160Zm0-240q-33 0-56.5-23.5T120-480q0-33 23.5-56.5T200-560q33 0 56.5 23.5T280-480q0 33-23.5 56.5T200-400Zm0-240q-33 0-56.5-23.5T120-720q0-33 23.5-56.5T200-800q33 0 56.5 23.5T280-720q0 33-23.5 56.5T200-640Z"/>
      </svg>
        <span class="spanButtonTrait">Bullets</span>
      </button>

      <button class="buttonTrait numbered-button">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M120-80v-60h100v-30h-60v-60h60v-30H120v-60h120q17 0 28.5 11.5T280-280v40q0 17-11.5 28.5T240-200q17 0 28.5 11.5T280-160v40q0 17-11.5 28.5T240-80H120Zm0-280v-110q0-17 11.5-28.5T160-510h60v-30H120v-60h120q17 0 28.5 11.5T280-560v70q0 17-11.5 28.5T240-450h-60v30h100v60H120Zm60-280v-180h-60v-60h120v240h-60Zm180 440v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360Z"/>
      </svg>
        <span class="spanButtonTrait">Numbered</span>
      </button>
    </div>
    <style>

    .gjs-field .gjs-field-href-next {
      background-color: var(--gbs-color-light-blue-400);
    }

    .buttonTrait {
      display: flex;
      align-items: center;
      padding: 5px;
      background-color: var(--gbs-color-gray-50);
      color: var(--gbs-color-black);
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .buttonTrait:hover {
      background-color: var(--gbs-color-gray-100);
    }

    button > svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .spanButtonTrait {
      font-size: 12px;
    }

    .containerButtonsTrait{
      display: flex;
      flex-direction: column;
      gap: 2px;
      background-color: var(--gbs-background-color);
    }

    </style>
      `;

      const bulletButton = el.querySelector('.bullet-button');
      const numberedButton = el.querySelector('.numbered-button');
      if (bulletButton && numberedButton) {
        bulletButton.addEventListener('click', () => {
          editor.runCommand(updateComponentType);
        });

        numberedButton.addEventListener('click', () => {
          editor.runCommand(updateComponentType);
        });
      }

      return el;
    },
  });

  editor.DomComponents.addType(bulletItemsContainerTypeUl, {
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value ===
      bulletItemsContainerTypeUl,
    model: {
      defaults: {
        attributes: {
          'custom-type': bulletItemsContainerTypeUl,
          class: 'bullet-list',
          editable: true,
          listType: 'ul',
        },
        tagName: 'ul',
        name: 'List Container',
        traits: [
          'id',
          'title',
          {
            type: 'href-next',
            name: 'href',
            label: 'Bullet style',
          },
        ],
      },
    },
  });

  editor.DomComponents.addType(bulletItemsContainerTypeOl, {
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value ===
      bulletItemsContainerTypeOl,
    model: {
      defaults: {
        attributes: {
          'custom-type': bulletItemsContainerTypeOl,
          class: 'bullet-list',
          editable: true,
          listType: 'ol',
        },
        tagName: 'ol',
        name: 'List Container',
        traits: [
          'id',
          'title',
          {
            type: 'href-next',
            name: 'href',
            label: 'Bullet style',
          },
        ],
      },
    },
  });

  const textType = editor.DomComponents.getType('text');

  editor.DomComponents.addType(bulletItemsTypeDescription, {
    extend: 'text',
    model: {
      defaults: {
        attributes: {
          'custom-type': bulletItemsType,
          class: 'bullet-point',
          editable: true,
        },
        tagName: 'span',
        name: 'Description',
        removable: false,
        copyable: false,
        draggable: false,
      },
    },
    view: {
      events: (): Backbone.EventsHash => {
        return {
          ...(textType?.view.prototype.events() as Backbone.EventsHash),
          keydown: 'fnkeydown',
        };
      },

      // eslint-disable-next-line no-restricted-syntax
      fnkeydown(e: KeyboardEvent) {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
          editor.runCommand(cmdAddNewBulletItem);
        }

        if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
          editor.runCommand(cmdListGoToRight);
        }

        if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
          editor.runCommand(cmdListGoToLeft);
        }
      },
    },
  });
  editor.Keymaps.add(
    cmdAddNewBulletItem,
    shortcutAddNewBulletItem,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);
    }
  );
  editor.Keymaps.add(
    cmdListGoToRight,
    shortcutListGoToRight,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);
    }
  );
  editor.Keymaps.add(
    cmdListGoToLeft,
    shortcutListGoToLeft,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);
    }
  );

  editor.Keymaps.add(
    cmdBreakWithNewLine,
    shortcutBreakWithNewLine,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);
    }
  );

  editor.DomComponents.addType(bulletItemsType, {
    model: {
      defaults: {
        attributes: {
          'custom-type': bulletItemsType,
          class: 'bullet-point',
          editable: true,
        },
        name: 'List Item',
        tagName: 'li',
        components: [
          {
            type: bulletItemsTypeDescription,
            content: 'Insert your text here',
            editable: true,
          },
        ],
        droppable: false,
      },
    },
    view: {
      events: (): Backbone.EventsHash => {
        return {
          ...(textType?.view.prototype.events() as Backbone.EventsHash),
          dblclick: 'fndblclick',
        };
      },

      // eslint-disable-next-line no-restricted-syntax
      fndblclick() {
        const selectedItem = editor.getSelected();
        const tagName = selectedItem?.get('tagName');

        if (selectedItem && tagName === 'li') {
          activateElement(selectedItem);
        }
      },
    },
  });

  const BulletItemContent: IBlockContent = {
    editable: true,
    type: bulletItemsType,
    styles: BulletPointStyle,
  };

  const CenterContainer: IBlockContent = {
    components: BulletItemContent,
    editable: true,
    style: { 'text-align': 'left', width: '100%' },
    type: bulletItemsContainerTypeUl,
  };

  editor.BlockManager.add(bulletItemsId, {
    category: 'Basic',
    content: CenterContainer,
    label: 'Bullet point',
    media:
      '<svg width="16mm" height="16mm" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path style="fill:currentColor;stroke:currentColor;stroke-width:.0831472;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.1" d="M1.288.584h2.626v3.573H1.288z" transform="matrix(.70458 0 0 .5221 1.939 3.505)"/><path style="fill:currentColor;stroke:currentColor;stroke-width:.0627658;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.1" d="M5.618 2.092h10.017v.534H5.618z" transform="matrix(.70458 0 0 .5221 1.939 3.505)"/><path style="fill:currentColor;stroke:currentColor;stroke-width:.0831472;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.1" d="M1.282 8.283h2.626v3.573H1.282z" transform="matrix(.70458 0 0 .5221 1.939 3.505)"/><path style="fill:currentColor;stroke:currentColor;stroke-width:.0627658;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.1" d="M5.612 9.791h10.017v.534H5.612zM5.6 17.279h10.017v.534H5.6z" transform="matrix(.70458 0 0 .5221 1.939 3.505)"/><path style="fill:currentColor;stroke:currentColor;stroke-width:.0831472;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.1" d="M1.264 15.96H3.89v3.573H1.264z" transform="matrix(.70458 0 0 .5221 1.939 3.505)"/></svg>',
    select: true,
  });

  editor.on('block:drag:stop', (dropTarget: Component, block: HTMLElement) => {
    if (block.id === bulletItemsId && dropTarget !== null) {
      const parent = dropTarget.parent();
      if (parent) {
        const parentTagName = parent.get('tagName');
        if (parentTagName === 'ol' || parentTagName === 'ul') {
          const index = dropTarget.index();

          dropTarget.remove();

          const cmpListItem = editor.DomComponents.addComponent({
            type: bulletItemsType,
          });

          parent.append(cmpListItem, {
            at: index,
          });
        }
      }
    }
  });

  editor.on(
    'component:drag:start',
    ({ target, parent }: IComponentDragEvent) => {
      const parentTagName = parent.get('tagName');
      if (parentTagName === 'ul' || parentTagName === 'ol') {
        parentComponentDragStart = parent;
        targetComponentDragStartIndex = target.index();
      }
    }
  );

  editor.on(
    'component:drag:end',
    ({ target, parent, index }: IComponentDragEvent) => {
      const targetTagName = target.get('tagName');
      const parentTagName = parent.get('tagName');

      if (targetTagName === 'li') {
        // When we drag a list item out of a list, we need to create a list for this item
        if (parentTagName !== 'ul' && parentTagName !== 'ol') {
          const componentType =
            parentComponentDragStart.attributes.tagName === 'ul'
              ? bulletItemsContainerTypeUl
              : bulletItemsContainerTypeOl;
          const cmpListContainer = editor.DomComponents.addComponent({
            type: componentType,
          });

          const container = parent.append(cmpListContainer, { at: index });
          container[0].append(target);
        }

        // When the last item in a list is dragged, we delete the empty list
        if (parentComponentDragStart?.components().length === 0) {
          parentComponentDragStart.remove();
        }

        // When the first element of a list is also a list, we need to indent that list
        const firstComponent = parentComponentDragStart.getChildAt(0);
        const firstComponentTagName = firstComponent?.attributes.tagName;
        if (firstComponentTagName === 'ul' || firstComponentTagName === 'ol') {
          editor.runCommand(cmdListOutdent, {
            listToOutdent: firstComponent,
          });
        }

        // When the previous and next elements are lists, we need to merge their items
        if (targetComponentDragStartIndex > 0) {
          let indexBefore = targetComponentDragStartIndex - 1;
          let indexAfter = targetComponentDragStartIndex;

          if (parent.getId() === parentComponentDragStart.getId()) {
            if (index < targetComponentDragStartIndex) {
              indexBefore = targetComponentDragStartIndex;
              indexAfter = targetComponentDragStartIndex + 1;
            }
          }

          const beforeSibling =
            parentComponentDragStart.getChildAt(indexBefore);
          const afterSibling = parentComponentDragStart.getChildAt(indexAfter);

          const beforeTagName = beforeSibling?.attributes.tagName ?? '';
          const afterTagName = afterSibling?.attributes.tagName ?? '';

          if (
            ['ul', 'ol'].includes(beforeTagName) &&
            ['ul', 'ol'].includes(afterTagName)
          ) {
            editor.runCommand(cmdListMerge, {
              listFrom: afterSibling,
              listTo: beforeSibling,
            });
          }
        }
      }

      // When we drag a list before or after another sub-list, we need to merge its items
      if (targetTagName === 'ul' || targetTagName === 'ol') {
        if (parentTagName === 'ul' || parentTagName === 'ol') {
          if (beforeSiblingIsList(target)) {
            editor.runCommand(cmdListMerge, {
              listFrom: target,
              listTo: getBeforeSibling(target),
            });
          }

          if (afterSiblingIsList(target)) {
            editor.runCommand(cmdListMerge, {
              listFrom: getAfterSibling(target),
              listTo: target,
            });
          }
        }
      }
    }
  );

  editor.on('component:remove:before', (model: Component) => {
    if (model.get('type') === bulletItemsType) {
      const cmpList = editor.getSelected()?.parent();
      const isFirstItemOfList =
        cmpList?.getChildAt(0).getId() === model.getId();

      // merge list items when an item is removed in between
      if (beforeSiblingIsList(model) && afterSiblingIsList(model)) {
        const beforeSibling = getBeforeSibling(model);
        const afterSibling = getAfterSibling(model);

        if (!beforeSibling || !afterSibling) {
          return;
        }

        editor.runCommand(cmdListMerge, {
          listFrom: afterSibling,
          listTo: beforeSibling,
        });
      }

      // outdent list when the first item is removed
      if (isFirstItemOfList && afterSiblingIsList(model)) {
        const afterSibling = getAfterSibling(model);
        const parent = model.parent();

        if (!afterSibling || !parent) {
          return;
        }

        editor.runCommand(cmdListOutdent, {
          listToOutdent: afterSibling,
        });
      }
    }
  });

  // remove the ul or ol when the last li is removed
  editor.on('component:remove', (model: Component) => {
    if (model.get('type') === bulletItemsType) {
      const cmpList = editor.getSelected()?.parent();
      if (!cmpList) {
        return;
      }
      if (cmpList.components().length === 0) {
        cmpList?.remove();
      }
    }
  });

  const commands = editor.Commands;
  commands.add(
    cmdListOutdent,
    (em: Editor, sender: any, options: IOptionsOutdentCommand) => {
      const { listToOutdent } = options;

      const parent = listToOutdent.parent();
      if (!parent) {
        return;
      }

      const componentsToMove: Component[] = [];
      listToOutdent.components().forEach((comp) => {
        componentsToMove.push(comp);
      });

      let position = 0;

      componentsToMove.forEach((item) => {
        item.move(parent, { at: position });
        position++;
      });

      listToOutdent.remove();
    }
  );

  commands.add(
    cmdListMerge,
    (em: Editor, sender: any, options: IOptionsMergeCommand) => {
      const { listFrom, listTo } = options;

      const componentsToMove: Component[] = [];

      listFrom.components().forEach((comp) => {
        componentsToMove.push(comp);
      });

      componentsToMove.forEach((item) => {
        item.move(listTo);
      });

      listFrom.remove();
    }
  );

  commands.add(updateComponentType, () => {
    const component = editor.getSelected() as Component;
    const newComponentType =
      component.attributes.tagName === 'ul'
        ? bulletItemsContainerTypeOl
        : bulletItemsContainerTypeUl;

    const newComponentTagName =
      component.attributes.tagName === 'ul' ? 'ol' : 'ul';

    component.set('type', newComponentType);
    component.set('attributes', {
      class: 'bullet-list',
      editable: true,
      listType: newComponentTagName,
    });
    component.set('tagName', newComponentTagName);
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  commands.add(cmdAddNewBulletItem, async (editor: Editor) => {
    const selectedItem = editor.getSelected();
    // @ts-expect-error: Property 'getContent' does not exist on type 'ComponentView'
    const contentDescription = await editor.getSelected()?.view.getContent();
    if (contentDescription === '') {
      const selectLastItem = editor.getSelected()?.parent()?.parent();
      if (selectLastItem) {
        // @ts-expect-error: Property 'remove' does not exist on type 'ComponentView'
        selectedItem?.parent().remove();
        activateElement(selectLastItem);
      }
      return;
    }

    const cmpList = editor.getSelected()?.parent()?.parent();

    const positionIndex = editor.getSelected()?.parent()?.index() ?? 0;

    const cmpListItem = editor.DomComponents.addComponent({
      type: bulletItemsType,
    });

    const appendedListItem = cmpList?.append(cmpListItem, {
      at: positionIndex + 1,
    });

    if (selectedItem) {
      selectedItem?.view?.onDisable();
      editor.selectRemove(selectedItem);
    }

    if (appendedListItem) {
      activateElement(appendedListItem[0]);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  commands.add(cmdListGoToRight, async (editor: Editor) => {
    /**
      8, as it starts with 3 components, List container, List Item and Content 
      and one more for each List Container created, totaling a maximum of 5 sub lists
     */
    if (editor.getSelected()?.parents().length === 8) {
      return;
    }

    const cmpList = editor.getSelected()?.parent()?.parent();

    // verify if UL Container has LI(parent)
    if (cmpList?.components().models.length === 1) {
      return;
    }

    // get first component of the list
    const cmpListItem = editor.getSelected()?.parent();
    const positionListItem = cmpListItem?.index() ?? 0;

    const beforeSibling =
      positionListItem > 0 ? cmpList?.getChildAt(positionListItem - 1) : null;
    const afterSibling = cmpList?.getChildAt(positionListItem + 1);

    const beforeSiblingIsList =
      beforeSibling?.attributes.tagName === 'ul' ||
      beforeSibling?.attributes.tagName === 'ol';
    const afterSiblingIsList =
      afterSibling?.attributes.tagName === 'ul' ||
      afterSibling?.attributes.tagName === 'ol';

    // @ts-expect-error: Property 'getContent' does not exist on type 'ComponentView'
    const contentDescription = await editor.getSelected()?.view.getContent();

    if (!beforeSiblingIsList && !afterSiblingIsList) {
      const componentType =
        cmpList?.attributes.tagName === 'ul'
          ? bulletItemsContainerTypeUl
          : bulletItemsContainerTypeOl;

      const newComponent = editor.DomComponents.addComponent({
        type: componentType,
        components: {
          type: bulletItemsType,
          components: {
            type: bulletItemsTypeDescription,
            content: contentDescription,
          },
        },
      });

      const appendedList = cmpList?.append(newComponent, {
        at: positionListItem,
      });

      if (appendedList) {
        activateElement(appendedList[0].getChildAt(0));
      }
    }

    if (beforeSiblingIsList) {
      const newListItemComponent = editor.DomComponents.addComponent({
        type: bulletItemsType,
        components: {
          type: bulletItemsTypeDescription,
          content: contentDescription,
        },
      });

      const appendedListItem = beforeSibling.append(newListItemComponent);

      activateElement(appendedListItem[0]);

      if (afterSiblingIsList) {
        const componentsToMove: Component[] = [];

        // iterate component's first level children
        afterSibling.components().forEach((comp) => {
          componentsToMove.push(comp);
        });

        componentsToMove.forEach((item) => {
          item.move(beforeSibling);
        });

        afterSibling.remove();
      }
    }

    if (afterSiblingIsList && !beforeSiblingIsList) {
      const newListItemComponent = editor.DomComponents.addComponent({
        type: bulletItemsType,
        components: {
          type: bulletItemsTypeDescription,
          content: contentDescription,
        },
      });

      const appendedListItem = afterSibling.append(newListItemComponent, {
        at: 0,
      });

      activateElement(appendedListItem[0]);
    }
    cmpListItem?.remove();
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  commands.add(cmdListGoToLeft, async (editor: Editor) => {
    const cmpList = editor.getSelected()?.parent()?.parent();
    const cmpListItem = editor.getSelected()?.parent();
    const positionListContainer = cmpList?.index() ?? 0;
    const positionListItem = cmpListItem?.index() ?? 0;

    if (cmpList && !listContainerHasParent(cmpList)) {
      return;
    }

    const isFirstChild = positionListItem === 0;
    const isLastChild = cmpList?.getLastChild().index() === positionListItem;
    const isMiddleChild = !isFirstChild && !isLastChild;

    // @ts-expect-error: Property 'getContent' does not exist on type 'ComponentView'
    const contentDescription = await editor.getSelected()?.view.getContent();

    const newComponent = editor.DomComponents.addComponent({
      type: bulletItemsType,
      components: {
        type: bulletItemsTypeDescription,
        content: contentDescription,
      },
    });

    const parentList = cmpList?.parent();
    const position = isFirstChild
      ? positionListContainer
      : positionListContainer + 1;
    const appendedListItem = parentList?.append(newComponent, { at: position });
    cmpListItem?.remove();

    if (appendedListItem) {
      activateElement(appendedListItem[0]);
    }

    if (isFirstChild) {
      const afterList = parentList?.getChildAt(positionListContainer + 1);

      // When the first element of a list is also a list, we need to outdent that list
      if (afterList) {
        const firstComponent = afterList.getChildAt(0);
        const firstComponentTagName = firstComponent?.attributes.tagName;
        if (firstComponentTagName === 'ul' || firstComponentTagName === 'ol') {
          editor.runCommand(cmdListOutdent, {
            listToOutdent: firstComponent,
          });
        }
      }
    }

    if (isMiddleChild) {
      const componentType =
        cmpList?.attributes.tagName === 'ul'
          ? bulletItemsContainerTypeUl
          : bulletItemsContainerTypeOl;

      const newContainer = editor.DomComponents.addComponent({
        type: componentType,
      });

      const newListContainer = parentList?.append(newContainer, {
        at: positionListContainer + 2,
      });

      const componentsToMove: Component[] = [];

      cmpList?.components().forEach((comp) => {
        if (comp.index() >= positionListItem) {
          componentsToMove.push(comp);
        }
      });

      componentsToMove.forEach((item) => {
        if (newListContainer) {
          item.move(newListContainer[0]);
        }
      });

      // When the first element of a list is also a list, we need to outdent that list
      if (newListContainer) {
        const firstComponent = newListContainer[0].getChildAt(0);
        const firstComponentTagName = firstComponent?.attributes.tagName;
        if (firstComponentTagName === 'ul' || firstComponentTagName === 'ol') {
          editor.runCommand(cmdListOutdent, {
            listToOutdent: firstComponent,
          });
        }
      }
    }

    if (cmpList?.components().length === 0) {
      cmpList.remove();
    }
  });

  const listContainerHasParent = (listContainer: Component): boolean => {
    const parent = listContainer.parent();
    const tag = parent?.attributes.tagName;

    return tag === 'ul' || tag === 'ol';
  };

  const getBeforeSibling = (item: Component): Component | null => {
    const positionListItem = item.index();
    const parentItem = editor.getSelected()?.parent();
    if (!parentItem) {
      return null;
    }

    const beforeSibling =
      positionListItem > 0
        ? parentItem?.getChildAt(positionListItem - 1)
        : null;

    return beforeSibling;
  };

  const getAfterSibling = (item: Component): Component | null => {
    const positionListItem = item.index();
    const parentItem = editor.getSelected()?.parent();
    if (!parentItem) {
      return null;
    }

    const afterSibling =
      positionListItem + 1 <= parentItem?.components().length
        ? parentItem?.getChildAt(positionListItem + 1)
        : null;

    return afterSibling;
  };

  const beforeSiblingIsList = (item: Component): boolean => {
    const beforeSibling = getBeforeSibling(item);

    return (
      beforeSibling?.attributes.tagName === 'ul' ||
      beforeSibling?.attributes.tagName === 'ol'
    );
  };

  const afterSiblingIsList = (item: Component): boolean => {
    const afterSibling = getAfterSibling(item);

    return (
      afterSibling?.attributes.tagName === 'ul' ||
      afterSibling?.attributes.tagName === 'ol'
    );
  };

  const activateElement = (listItem: Component): void => {
    const textItem = listItem.getChildAt(0);
    editor.select(textItem);
    textItem.view?.onActive(new Event('dblclick'));
  };
};

export default BulletPointPlugin;
