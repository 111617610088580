import { type CanvasSpot, type Editor } from 'grapesjs';
import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useToast } from '@gbs-monorepo-packages/common';

import {
  CELL_TABLE_LAYOUT_SPOT_TYPE,
  ROW_TABLE_LAYOUT_SPOT_TYPE,
  SPOTS_TYPES,
  TABLE_LAYOUT_WRAPPER_SPOT_TYPE,
} from '../../../../constants/grapes';
import { generateCellResizeOptions } from '../../Blocks/TableLayout/components/DefaultTableCell';
import {
  type IAddCellTableLayoutConfig,
  handlerAddCellTableLayout,
} from '../../Customs/HandleTables/handlers/AddCellTableLayout';
import {
  type IAddColumnTableLayoutConfig,
  handlerAddColumnTableLayout,
} from '../../Customs/HandleTables/handlers/AddColumnTableLayout';
import {
  type IAddRowTableLayoutConfig,
  handlerAddRowTableLayout,
} from '../../Customs/HandleTables/handlers/AddRowTableLayout';

type IAppendCellTableLayoutProps =
  | (Omit<IAddCellTableLayoutConfig, 'cell'> & {
      isolateAction: true;
    })
  | (Omit<IAddColumnTableLayoutConfig, 'cell'> & {
      isolateAction: false;
    });

type IApendRowTableLayoutProps = Omit<
  IAddRowTableLayoutConfig,
  'persistRegularity' | 'row'
> & {
  isolateAction: boolean;
};

export interface ISpotContextData {
  appendCell: (config: IAppendCellTableLayoutProps) => void;
  appendRow: (config: IAppendCellTableLayoutProps) => void;
  cellSpot: CanvasSpot | null;
  editor: Editor;
  rowSpot: CanvasSpot | null;
  tableSpot: CanvasSpot | null;
}

interface ISpotProps {
  children: (spots: {
    cellSpot?: CanvasSpot | null;
    rowSpot?: CanvasSpot | null;
    tableSpot?: CanvasSpot | null;
  }) => ReactNode;
  editor: Editor;
}

export const SpotContext = createContext<ISpotContextData>(
  {} as ISpotContextData
);

export const SpotProvider = ({ children, editor }: ISpotProps): JSX.Element => {
  const [cellSpot, setCellSpot] = useState<CanvasSpot | null>(null);
  const [rowSpot, setRowSpot] = useState<CanvasSpot | null>(null);
  const [tableSpot, setTableSpot] = useState<CanvasSpot | null>(null);
  const { addToast } = useToast();

  useEffect(() => {
    const { Canvas } = editor;
    const handleSpotEvents = (): void => {
      const setSpot = new Set(SPOTS_TYPES);
      for (const spot of Canvas.getSpots()) {
        if (setSpot.size === 0) break;
        switch (spot.type) {
          case TABLE_LAYOUT_WRAPPER_SPOT_TYPE:
            setTableSpot(spot);
            setSpot.delete(TABLE_LAYOUT_WRAPPER_SPOT_TYPE);
            break;
          case CELL_TABLE_LAYOUT_SPOT_TYPE:
            setCellSpot(spot);
            setSpot.delete(CELL_TABLE_LAYOUT_SPOT_TYPE);
            break;
          case ROW_TABLE_LAYOUT_SPOT_TYPE:
            setRowSpot(spot);
            setSpot.delete(ROW_TABLE_LAYOUT_SPOT_TYPE);
            break;
          default:
            break;
        }
      }
      setSpot.forEach((type) => {
        switch (type) {
          case TABLE_LAYOUT_WRAPPER_SPOT_TYPE:
            setTableSpot(null);
            break;
          case CELL_TABLE_LAYOUT_SPOT_TYPE:
            setCellSpot(null);
            break;
          case ROW_TABLE_LAYOUT_SPOT_TYPE:
            setRowSpot(null);
            break;
          default:
            break;
        }
      });
    };

    editor.on('canvas:spot', handleSpotEvents);
    return () => {
      editor.off('canvas:spot', handleSpotEvents);
    };
  }, [editor]);

  useEffect(() => {
    const handleCellResizeDirections = () => {
      const cell = cellSpot?.component;
      if (!cell) return;

      let cl = true;
      let cr = true;

      const cellIndex = cell.index();
      const length = cell.parent()?.components().length ?? 0;

      if (cellIndex === 0) {
        cl = false;
      }

      if (cellIndex === length - 1) {
        cr = false;
      }

      cell.set('resizable', generateCellResizeOptions(editor, { cl, cr }));
    };

    handleCellResizeDirections();
  }, [editor, cellSpot?.component]);

  const appendCell = useCallback(
    (config: IAppendCellTableLayoutProps) => {
      const cell = cellSpot?.component;
      if (!cell) {
        addToast({
          title: 'Error on appending cell',
          styleType: 'error',
          dataCy: 'append-cell-error-toast',
        });
        return;
      }

      if (config.isolateAction) {
        handlerAddCellTableLayout(editor, { cell, ...config });
      } else {
        try {
          handlerAddColumnTableLayout(editor, { cell, ...config });
        } catch (error) {
          addToast({
            title: 'Error on appending cell',
            styleType: 'error',
            dataCy: 'append-cell-error-toast',
          });
        }
      }
    },
    [addToast, cellSpot?.component, editor]
  );

  const appendRow = useCallback(
    ({ isolateAction, ...config }: IApendRowTableLayoutProps) => {
      const row = rowSpot?.component;
      if (!row) {
        addToast({
          title: 'Error on appending row',
          styleType: 'error',
          dataCy: 'append-row-error-toast',
        });
        return;
      }

      try {
        handlerAddRowTableLayout(editor, {
          row,
          persistRegularity: !isolateAction,
          ...config,
        });
      } catch (error) {
        addToast({
          title: 'Error on appending row',
          styleType: 'error',
          dataCy: 'append-row-error-toast',
        });
      }
    },
    [addToast, editor, rowSpot?.component]
  );

  return (
    <SpotContext.Provider
      value={{
        appendCell,
        appendRow,
        cellSpot,
        editor,
        rowSpot,
        tableSpot,
      }}
    >
      {children({ tableSpot, rowSpot, cellSpot })}
    </SpotContext.Provider>
  );
};
