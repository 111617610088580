import { type HTMLAttributes, type ReactNode } from 'react';

import { Content } from './styles';

export interface IContentPaginationProps
  extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  dataCy?: string;
}

export const ContentPagination = ({
  children,
  dataCy = 'content-modal',
  ...props
}: IContentPaginationProps): JSX.Element => {
  return (
    <Content {...props} data-cy={dataCy}>
      {children}
    </Content>
  );
};
