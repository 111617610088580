import { z } from 'zod';

export const autobuildSchema = z.object({
  order: z
    .number()
    .min(1, {
      message: 'Order must be greater than zero.',
    })
    .positive({ message: 'Order must be greater than zero.' })
    .int(),
  type: z.string().min(1, { message: 'Type is required' }),
  property: z.string().min(1, { message: 'Property is required' }),
});

export type AutobuildSchema = z.infer<typeof autobuildSchema>;
