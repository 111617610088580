import { type IGlobalStyleDTO } from '../services/courses';

export interface IGlobalStyle extends Omit<Required<IGlobalStyleDTO>, 'id'> {}

export const globalStyleDefault = {
  buttonColor: '#00426b',
  buttonFontColor: '#ffffff',
  headlineFont: null,
  headlineFontColor: '#000000',
  headlineFontSize: 24,
  headlineFontSizeUnit: 'px',
  hueRotate: 0,
  pageWidth: 500,
  pageWidthUnit: 'px',
  paragraphFont: null,
  paragraphFontColor: '#000000',
  paragraphFontSize: 16,
  paragraphFontSizeUnit: 'px',
  primaryColor: '#0b70fe',
  secondaryColor: '#c5f1ff',
  templateColors: {
    primaryColor: '',
    secondaryColor: '',
    backgroundColor: '',
    accentColor: '',
    additionalColors: {},
  },
} satisfies IGlobalStyle;

export const maxHueRotate = 360;
export const minHueRotate = 0;
export const minValuePageWidth = 1;
export const maxValuePageWidth = 900;
export const maxValuePercentagePageWidth = 100;
