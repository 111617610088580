import styled from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const CommandCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;

  ${scrollbar};
`;

export const CommandCard = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 0.25rem;
  padding: 0.5rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const CommandLabel = styled.h4`
  color: ${colors.textLight};
  font-size: 1.25rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CommandsDescriptionsContainer = styled.p`
  color: ${colors.textLight};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 300;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CommandsDescriptions = styled.span`
  & + & {
    margin-left: 0.25rem;
  }
`;
