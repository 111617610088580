import styled from 'styled-components';

import { BaseDropdownItem, BaseLoading } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Center = styled.div`
  text-align: center;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;

export const DropdownItem = styled(BaseDropdownItem)`
  font-size: 1rem;
  font-weight: 400;

  all: 'unset';
  line-height: 1.125rem;
  color: ${colors.labelText};
  height: auto;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  padding-left: 25;
  user-select: none;
  border-radius: ${radii.sm};

  &[data-highlighted],
  &:hover {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const ContainerGrid = styled.div`
  display: grid;
  width: 100%;
  padding: 10px;
  grid-template-columns: 3fr 1.5fr 2fr 1fr;
  grid-column-gap: 15px;
  border-bottom: 1px solid ${colors.gray100};
  align-items: center;
`;

export const ItemText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ItemGrid = styled.div`
  text-align: left;
  color: ${colors.textLight};
  min-width: 0;
`;

export const ItemGridCenter = styled.div`
  text-align: center;
  color: ${colors.textLight};
  min-width: 0;
`;

export const HeaderGrid = styled(ContainerGrid)`
  padding-bottom: 1rem;
  font-weight: bold;

  & ${ItemGrid} {
    color: ${colors.textLight};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
  }

  & ${ItemGridCenter} {
    color: ${colors.textLight};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
  }
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const NoMemberContainer = styled.div`
  margin-top: 1rem;
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoMemberText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
