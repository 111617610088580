import {
  type FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isDesktop } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  BaseModal,
  BaseTooltip,
  useToast,
} from '@gbs-monorepo-packages/common';
import { colors } from '@gbs-monorepo-packages/styles';

import {
  wrapperClass,
  wrapperCss,
} from '../../components/GrapesJS/Customs/ToggleWrapper';
import { SearchCourseModal } from '../../components/SearchCourseModal';
import {
  GOOGLE_FONTS_API_URL,
  GOOGLE_TAG_MANAGER,
  IS_PROD,
} from '../../constants/Env';
import { languageData } from '../../constants/Languages';
import { EdgeStorageKey } from '../../constants/Storage';
import { type IApiThrowsError } from '../../services/api';
import { type ICoursePageDTO } from '../../services/coursePages';
import { type IFontDTO } from '../../services/courses';
import {
  type IViewCourseDTO,
  getViewCourse,
  postViewCourse,
} from '../../services/viewCourses';
import { generateCssFromGlobalStyle } from '../../utils/generate';
import { getStorage, setStorage } from '../../utils/localStorage';
import { ByPageNumber } from '../../utils/sortObjects';
import {
  ActionIconContainer,
  ActionItemsContainer,
  AsideContainer,
  ContainerArrows,
  CourseViewerContainer,
  CustomSelectData,
  Description,
  ErrorMessage,
  ExitFullScreenMobile,
  ExitFullscreenIcon,
  Fieldset,
  Footer,
  Form,
  FullscreenIcon,
  FullscreenMessageContainer,
  Header,
  HeaderImage,
  HeaderImageContainer,
  IframeCustom,
  Input,
  Label,
  LeftArrowIcon,
  LeftArrowIconContainer,
  LeftArrowIconFullScreen,
  LoadingPages,
  LoadingPagesContainer,
  LockButton,
  LockedIcon,
  MainContainer,
  MainContent,
  NoPagesContainer,
  NoPagesText,
  OverlayToggleToMobile,
  PageContainer,
  PageIcon,
  PageIconContainer,
  PageItem,
  PageItemsContainer,
  PageMessage,
  PageTitle,
  PageTitleContainer,
  PagesMenuIcon,
  RightArrowIcon,
  RightArrowIconContainer,
  RightArrowIconFullScreen,
  SearchIcon,
  SubmitButton,
  TextContent,
  Title,
  ToggleFullscreenButton,
  ToggleLeftButton,
  ToggleRightButton,
  UnLockedIcon,
} from './styles';

interface IPasscodeError {
  passcode?: string;
  other?: string;
}

export const CourseViewer = (): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [isSearchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [showPasscodeModal, setShowPasscodeModal] = useState<boolean>(false);
  const [showPasscode, setShowPasscode] = useState(false);
  const [showPagesContainer, setShowPagesContainer] = useState<boolean>(false);
  const [isPagesMenuOpen, setPagesMenuOpen] = useState<boolean>(false);
  const [showFullscreenMessageContainer, setShowFullscreenMessageContainer] =
    useState<boolean>(false);
  const [loadingCourse, setLoadingCourse] = useState<boolean>(false);
  const [loadingPasscodeSubmit, setLoadingPasscodeSubmit] =
    useState<boolean>(false);
  const [course, setCourse] = useState<IViewCourseDTO | null>(null);
  const [sortedPages, setSortedPages] = useState<ICoursePageDTO[]>([]);
  const [currentPage, setCurrentPage] = useState<ICoursePageDTO | null>(null);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [passcode, setPasscode] = useState<string>('');
  const [error, setError] = useState<IPasscodeError>({});
  const [fontsImportString, setFontsImportString] = useState<string>('');
  const { viewURL = '' } = useParams();
  const headerImagePath = course?.courseFile?.path ?? '';

  const [showButtonLeft, setShowButtonLeft] = useState(false);
  const [showButtonRight, setShowButtonRight] = useState(false);
  const [language, setLanguage] = useState('en');
  const [searchParams, setSearchParams] = useSearchParams();
  const { addToast } = useToast();

  useEffect(() => {
    const lang = searchParams.get('language');
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  useEffect(() => {
    let mount = true;
    const fetchViewCourse = async () => {
      try {
        if (
          searchParams.has('language') &&
          searchParams.get('language') !== language
        ) {
          return;
        }

        setLoadingCourse(true);
        let url = viewURL;
        if (searchParams.has('language')) {
          url += `?language=${language}`;
        }
        const result: IViewCourseDTO = await getViewCourse({ viewURL: url });

        if (mount) {
          setCourse(result);
        }
      } catch (err) {
        if ((err as IApiThrowsError).response?.data.error.code === 403) {
          setShowPasscodeModal(true);
        }
      } finally {
        if (mount) {
          setLoadingCourse(false);
        }
      }
    };

    const handlePostViewCourse = async () => {
      try {
        const pass = getStorage<string>(EdgeStorageKey.VIEWCOURSE_PASSCODE);
        setLoadingCourse(true);
        const result = await postViewCourse({
          viewURL,
          passcode: pass ?? '',
          language: language !== 'en' ? language : undefined,
        });

        if (mount) {
          setCourse(result);
        }
      } catch (err) {
        const errorCode = (err as IApiThrowsError).response?.data.error.code;
        if (Number(errorCode) >= 500) {
          addToast({
            title: 'Something went wrong',
            description:
              'An error occurred. Please try again or contact Benefit Education support.',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        } else {
          setShowPasscodeModal(true);
        }
      } finally {
        if (mount) {
          setLoadingCourse(false);
        }
      }
    };

    if (course?.passcodeProtected === true) {
      void handlePostViewCourse();
    } else {
      void fetchViewCourse();
    }

    return () => {
      mount = false;
    };
  }, [viewURL, language]);

  useEffect(() => {
    if (course) {
      const page = Number(searchParams.get('page'));

      const courseSortedPages = course.pages.sort(ByPageNumber);
      if (page) {
        const pageNumber = Number(page);
        const totalPages = courseSortedPages.length;

        const pageToMove = Math.max(
          1,
          Math.min(pageNumber - 1, totalPages - 1)
        );

        if (totalPages < pageNumber || pageNumber < 1) {
          setCurrentPage(courseSortedPages.at(0) ?? null);
          setCurrentPageIndex(0);
          searchParams.set('page', `1`);
          setSearchParams(searchParams);
        } else {
          setCurrentPage(courseSortedPages.at(pageToMove) ?? null);
          setCurrentPageIndex(pageToMove);
          searchParams.set('page', `${pageToMove + 1}`);
          setSearchParams(searchParams);
        }
      } else if (currentPage === null) {
        setCurrentPage(courseSortedPages[0] ?? null);
      } else {
        const selectedPage = courseSortedPages.find(
          (page) => page.id === currentPage.id
        );
        if (selectedPage) {
          setCurrentPage(selectedPage);
        }
      }

      setSortedPages(courseSortedPages);

      if (fontsImportString === '') {
        let importString = '';
        course.fonts.forEach((font: IFontDTO) => {
          importString += `<link href="${GOOGLE_FONTS_API_URL}?family=${font.family}" rel="stylesheet">`;
        });

        setFontsImportString(importString);
      }
    }
  }, [course, fontsImportString]);

  const moveToPreviousPage = useCallback(() => {
    const newPageIndex = currentPageIndex - 1;
    searchParams.set('page', `${newPageIndex + 1}`);
    if (currentPageIndex + 1 === 1) return;

    setCurrentPageIndex(newPageIndex);
    setCurrentPage(sortedPages[newPageIndex] ?? null);
    setShowButtonRight(false);
    setSearchParams(searchParams);
  }, [currentPageIndex, searchParams, setSearchParams, sortedPages]);

  const moveToNextPage = useCallback(() => {
    const newPageIndex = currentPageIndex + 1;
    searchParams.set('page', `${newPageIndex + 1}`);
    if (newPageIndex >= sortedPages.length) return;

    setCurrentPageIndex(newPageIndex);
    setCurrentPage(sortedPages[newPageIndex] ?? null);
    setShowButtonLeft(false);
    setSearchParams(searchParams);
  }, [currentPageIndex, searchParams, setSearchParams, sortedPages]);

  const moveToPage = (index: number) => {
    searchParams.set('page', `${index + 1}`);
    setCurrentPageIndex(index);
    setCurrentPage(sortedPages[index] ?? null);
    setSearchParams(searchParams);
  };

  const handlerPasscodeSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (loadingPasscodeSubmit) return;
    setLoadingPasscodeSubmit(true);
    setError({});

    postViewCourse({
      viewURL,
      passcode,
      language: language !== 'en' ? language : undefined,
    })
      .then((result: IViewCourseDTO) => {
        setCourse(result);
        setShowPasscodeModal(false);
        setLoadingPasscodeSubmit(false);
        setPasscode('');
        setStorage(EdgeStorageKey.VIEWCOURSE_PASSCODE, passcode);
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          setLoadingPasscodeSubmit(false);
          if (error.response && error.response.data.error.code >= 500) {
            setError({
              passcode: error.response?.data.error.message,
            });
          } else {
            setError({
              other: error.response?.data.error.message,
            });
          }
        }, 2000);
      });
  };

  const cssGlobalStyles = useMemo(() => {
    const globalStyle = course?.globalStyle;
    if (!globalStyle || 'length' in globalStyle) return '';

    return generateCssFromGlobalStyle(globalStyle);
  }, [course?.globalStyle]);

  useEffect(() => {
    setShowFullscreenMessageContainer(true);
    const timer = setTimeout(() => {
      setShowFullscreenMessageContainer(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [fullScreen]);

  useEffect(() => {
    setShowPagesContainer(true);
    const timer = setTimeout(() => {
      setShowPagesContainer(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentPageIndex]);

  const iframe = iframeRef.current;
  const scrollAmount = 50;

  const handleIframeMessage = useCallback(
    (event: MessageEvent<{ key: string; clientX: number }>) => {
      if (event.origin !== window.location.origin || !iframe) {
        return;
      }

      const iframeDocument =
        iframe.contentDocument ?? iframe.contentWindow?.document;
      const clientWidth = document.documentElement.clientWidth;
      const limitHover = 120;

      if (event.data.key === 'Escape') {
        setFullScreen(false);
      } else if (event.data.key === 'ArrowRight') {
        moveToNextPage();
      } else if (event.data.key === 'ArrowLeft') {
        moveToPreviousPage();
      } else if (event.data.key === 'ArrowUp' && iframeDocument?.defaultView) {
        iframeDocument.defaultView.scrollBy(0, -scrollAmount);
      } else if (
        event.data.key === 'ArrowDown' &&
        iframeDocument?.defaultView
      ) {
        iframeDocument.defaultView.scrollBy(0, scrollAmount);
      }

      if (event.data.clientX < limitHover) {
        setShowButtonLeft(true);
      } else {
        setShowButtonLeft(false);
      }

      if (event.data.clientX > clientWidth - limitHover) {
        setShowButtonRight(true);
      } else {
        setShowButtonRight(false);
      }
    },
    [moveToNextPage, moveToPreviousPage, fullScreen, iframe]
  );

  const keydownFunction = useCallback(
    (event: KeyboardEvent) => {
      if (!iframe) return;

      const iframeDocument =
        iframe.contentDocument ?? iframe.contentWindow?.document;

      if (event.key === 'Escape') {
        setFullScreen(false);
      } else if (event.key === 'ArrowRight') {
        moveToNextPage();
      } else if (event.key === 'ArrowLeft') {
        moveToPreviousPage();
      } else if (event.key === 'ArrowUp' && iframeDocument?.defaultView) {
        iframeDocument.defaultView.scrollBy(0, -scrollAmount);
      } else if (event.key === 'ArrowDown' && iframeDocument?.defaultView) {
        iframeDocument.defaultView.scrollBy(0, scrollAmount);
      }
    },
    [moveToNextPage, moveToPreviousPage, iframe]
  );

  useEffect(() => {
    document.addEventListener('keydown', keydownFunction, false);
    window.addEventListener('message', handleIframeMessage);

    return () => {
      document.removeEventListener('keydown', keydownFunction, false);
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [keydownFunction, handleIframeMessage]);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;

      if (iframeDocument) {
        const iframe = document.getElementById('iframe');

        if (iframe instanceof HTMLElement) {
          iframe.click();
        }
      }
    }
  }, [fullScreen]);

  useEffect(() => {
    if (course != null) {
      if (IS_PROD) {
        TagManager.initialize({
          gtmId: GOOGLE_TAG_MANAGER,
          dataLayer: {
            courseId: course.id,
            event: 'Course_View',
          },
        });
      }
    }
  }, [course]);

  const handleChangeLanguage = (value: string) => {
    setLanguage(value);

    if (value === 'en') {
      searchParams.delete('language');
    } else {
      searchParams.set('language', value);
    }

    setSearchParams(searchParams);
  };

  return (
    <CourseViewerContainer data-cy="courseViewer-container">
      <MainContainer data-cy="page-container">
        <Header data-cy="header-courseView" $visible={!fullScreen}>
          <HeaderImageContainer
            data-cy="header-imageContainer"
            style={{
              visibility: headerImagePath === '' ? 'hidden' : 'visible',
            }}
          >
            {headerImagePath !== '' && (
              <HeaderImage
                data-cy={`${headerImagePath}`}
                src={`${headerImagePath}`}
              />
            )}
          </HeaderImageContainer>
          <ActionItemsContainer data-cy="action-itemsContainer">
            {!showPasscodeModal && (
              <CustomSelectData
                data={languageData}
                dataCy="select-language"
                name="language"
                onValueChange={handleChangeLanguage}
                value={language}
                zIndex={10}
              />
            )}

            <BaseTooltip message="Full Screen" disableHoverableContent>
              <ActionIconContainer
                data-cy="fullscreen-iconContainer"
                onClick={() => {
                  setFullScreen(true);
                  setPagesMenuOpen(false);
                  setSearchModalOpen(false);
                }}
              >
                <FullscreenIcon />
              </ActionIconContainer>
            </BaseTooltip>

            {sortedPages.length > 1 && (
              <>
                <BaseTooltip message="Search" disableHoverableContent>
                  <ActionIconContainer
                    data-cy="search-iconContainer"
                    onClick={() => {
                      setSearchModalOpen(!isSearchModalOpen);
                    }}
                  >
                    <SearchIcon />
                  </ActionIconContainer>
                </BaseTooltip>

                <BaseTooltip message="Pages Menu" disableHoverableContent>
                  <ActionIconContainer
                    data-cy="pagesMenu-container"
                    onClick={() => {
                      setPagesMenuOpen(!isPagesMenuOpen);
                    }}
                  >
                    <PagesMenuIcon />
                  </ActionIconContainer>
                </BaseTooltip>
              </>
            )}
          </ActionItemsContainer>
        </Header>
        {loadingCourse ? (
          <LoadingPagesContainer data-cy="loading-course-viewer-container">
            <LoadingPages dataCy="loading-course-viewer" />
          </LoadingPagesContainer>
        ) : !currentPage ? (
          <NoPagesContainer data-cy="no-pages-founded">
            <NoPagesText data-cy="no-pages-founded-text">
              No pages found
            </NoPagesText>
          </NoPagesContainer>
        ) : (
          <MainContent data-cy="course-viewer-content">
            <IframeCustom
              id="iframe"
              ref={iframeRef}
              srcDoc={`<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0">
                      <meta name="x-apple-disable-message-reformatting">
                      <meta http-equiv="X-UA-Compatible" content="IE=edge">
                      <style type="text/css">${wrapperCss}</style>

                      <link rel="preconnect" href="https://fonts.googleapis.com">
                      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                      ${fontsImportString}

                      <style type="text/css">${
                        currentPage?.cssContent ?? ''
                      }</style>
                      <style type="text/css">${cssGlobalStyles}</style>
                      <table id="gjs_body" style="table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: transparent;width:100%" cellpadding="0" cellspacing="0">
                        <tbody>
                          <tr style="vertical-align: top">
                            <td ${
                              currentPage.hasWrapper ?? false
                                ? `class="${wrapperClass}"`
                                : ''
                            } style="vertical-align: top;">
                              ${currentPage?.htmlContent ?? ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <script>
                        document.addEventListener('keydown', function(event) {
                          window.parent.postMessage({
                            key: event.key,
                            clientX: null
                          }, '*')
                        });

                        document.addEventListener('mousemove', function(event) {
                          window.parent.postMessage({
                            key: null,
                            clientX: event.clientX
                          }, '*')
                        })
                      </script>
                      `}
            />
            <ContainerArrows $visible={fullScreen}>
              {currentPageIndex + 1 !== 1 && (
                <ToggleLeftButton
                  data-cy="leftArrow-container"
                  onClick={moveToPreviousPage}
                  disabled={currentPageIndex + 1 === 1}
                  $visible={showButtonLeft || !isDesktop}
                >
                  <LeftArrowIconFullScreen />
                </ToggleLeftButton>
              )}

              {currentPageIndex + 1 !== sortedPages.length && (
                <ToggleRightButton
                  data-cy="rightArrow-container"
                  onClick={moveToNextPage}
                  disabled={currentPageIndex + 1 === sortedPages.length}
                  $visible={showButtonRight || !isDesktop}
                >
                  <RightArrowIconFullScreen />
                </ToggleRightButton>
              )}

              <BaseTooltip message="Exit Full Screen" disableHoverableContent>
                <ExitFullScreenMobile $visible={!isDesktop}>
                  <ToggleFullscreenButton
                    data-cy="exit-fullscreen-iconContainer"
                    onClick={() => {
                      setFullScreen(false);
                    }}
                  >
                    <ExitFullscreenIcon />
                  </ToggleFullscreenButton>
                </ExitFullScreenMobile>
              </BaseTooltip>
            </ContainerArrows>
          </MainContent>
        )}
        <PageContainer
          $visible={(showPagesContainer || !isDesktop) && fullScreen}
        >
          <PageMessage data-cy={currentPageIndex + 1}>
            {currentPageIndex + 1} / {sortedPages.length ?? 1}
          </PageMessage>
        </PageContainer>
        <FullscreenMessageContainer
          $visible={showFullscreenMessageContainer && fullScreen}
        >
          <PageMessage data-cy="fullscreen-exit-message">
            Press <b>ESC</b> to exit full screen mode
          </PageMessage>
        </FullscreenMessageContainer>
        <Footer
          data-cy="footer-courseViewer"
          $visible={!fullScreen && sortedPages.length > 1}
        >
          <LeftArrowIconContainer
            data-cy="leftArrow-container"
            onClick={moveToPreviousPage}
            disabled={currentPageIndex + 1 === 1}
          >
            <LeftArrowIcon />
          </LeftArrowIconContainer>
          <PageMessage data-cy={currentPageIndex + 1}>
            {currentPageIndex + 1} / {sortedPages.length ?? 1}
          </PageMessage>
          <RightArrowIconContainer
            data-cy="rightArrow-container"
            onClick={moveToNextPage}
            disabled={currentPageIndex + 1 === sortedPages.length}
          >
            <RightArrowIcon />
          </RightArrowIconContainer>
        </Footer>
      </MainContainer>

      <OverlayToggleToMobile
        $isShrunken={isPagesMenuOpen}
        onClick={() => {
          setPagesMenuOpen(false);
        }}
      ></OverlayToggleToMobile>
      <AsideContainer
        data-cy="aside-container"
        style={{ display: isPagesMenuOpen ? 'flex' : 'none' }}
      >
        <PageItemsContainer data-cy="pageItems-container">
          {sortedPages.map((coursePage, index) => {
            const { id, title } = coursePage;

            return (
              <PageItem
                data-cy={id}
                key={id}
                onClick={() => {
                  moveToPage(index);
                  setPagesMenuOpen(false);
                }}
                style={{
                  backgroundColor:
                    currentPageIndex === index
                      ? `${colors.backgroundHover}`
                      : '',
                }}
              >
                <PageIconContainer data-cy="pageIcon-container">
                  <PageIcon />
                </PageIconContainer>
                <PageTitleContainer data-cy="pageTitle-container">
                  <PageTitle data-cy={title}>{title}</PageTitle>
                </PageTitleContainer>
              </PageItem>
            );
          })}
        </PageItemsContainer>
      </AsideContainer>
      <SearchCourseModal
        data-cy="searchCourse-modal"
        isOpen={isSearchModalOpen}
        requestClose={() => {
          setSearchModalOpen(!isSearchModalOpen);
        }}
        coursePages={sortedPages ?? []}
        moveToPage={moveToPage}
      />
      <BaseModal
        open={showPasscodeModal}
        hiddenCloseButton
        dataCy="passcode-content-modal"
        loading={loadingPasscodeSubmit}
      >
        <TextContent data-cy="text-container">
          <Title data-cy="title">Course Protected</Title>
          <Description data-cy="description">
            Please enter the Course passcode below:
          </Description>
        </TextContent>
        <Form onSubmit={handlerPasscodeSubmit} data-cy="passcode-form">
          <Fieldset filled={!!passcode} isInvalid={!!error.passcode}>
            <Input
              data-cy="password-input"
              value={passcode}
              id="passcode"
              onChange={(e) => {
                setPasscode(e.target.value.trim());
              }}
              required
              type={showPasscode ? 'text' : 'password'}
            />
            <Label htmlFor="passcode">Passcode</Label>
            <LockButton
              data-cy="toggle-lock-button"
              onClick={() => {
                setShowPasscode(!showPasscode);
              }}
              type="button"
            >
              {showPasscode ? (
                <LockedIcon data-cy="locked-icon" />
              ) : (
                <UnLockedIcon data-cy="unlocked-icon" />
              )}
            </LockButton>
          </Fieldset>
          <ErrorMessage data-cy={error.passcode ?? error.other}>
            {error.passcode ?? error.other}
          </ErrorMessage>
          <SubmitButton
            type="submit"
            data-cy="passcode-submit-button"
            disabled={loadingPasscodeSubmit}
          >
            Submit
          </SubmitButton>
        </Form>
      </BaseModal>
    </CourseViewerContainer>
  );
};
