import { type ChangeEventHandler, type MouseEventHandler } from 'react';

import {
  BaseModal,
  Button,
  type IBaseModalProps,
} from '@gbs-monorepo-packages/common';

import { UploadField } from '../UploadField';
import {
  ButtonsContainer,
  Description,
  ErrorMessage,
  TextContent,
  TextLimit,
  TextLimitContainer,
  Title,
} from './styles';

interface IUploadModalProps extends Partial<IBaseModalProps> {
  accept?: string;
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  description?: string;
  errorMessage?: string;
  fileSizeLimit?: string;
  fileDimensionsLimit?: string;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const UploadModal = ({
  accept,
  acceptText,
  dataCy = 'upload-modal',
  declineText,
  disabled = false,
  loading = false,
  mainText,
  description,
  errorMessage,
  fileSizeLimit,
  fileDimensionsLimit,
  onAccept,
  onChange,
  onDecline,
  ...props
}: IUploadModalProps): JSX.Element => (
  <BaseModal loading={loading} {...props} dataCy={dataCy}>
    <TextContent data-cy="text-container">
      <Title data-cy={mainText}>{mainText}</Title>
      {description ? (
        <Description data-cy={description}>{description}</Description>
      ) : (
        <></>
      )}
    </TextContent>

    <UploadField dataCy="input-file" onChange={onChange} accept={accept} />

    {errorMessage && (
      <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>
    )}

    <TextLimitContainer data-cy="text-limit-container">
      {fileSizeLimit && (
        <TextLimit data-cy="info-limit">Limit: {fileSizeLimit}</TextLimit>
      )}
      {fileDimensionsLimit && (
        <TextLimit data-cy="info-dimensions">
          Dimensions: {fileDimensionsLimit}
        </TextLimit>
      )}
    </TextLimitContainer>

    <ButtonsContainer>
      {declineText && (
        <Button
          type="button"
          dataCy="button-cancel"
          styleType="outline"
          onClick={onDecline}
          disabled={loading}
        >
          {declineText}
        </Button>
      )}
      {acceptText && (
        <Button
          type="button"
          dataCy="button-add"
          onClick={onAccept}
          loading={loading}
          disabled={disabled}
        >
          {acceptText}
        </Button>
      )}
    </ButtonsContainer>
  </BaseModal>
);
