const shuffleArray = (array: string[]) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const LegendColorsArray = shuffleArray([
  '#ea2880',
  '#74e543',
  '#8540e1',
  '#ccf229',
  '#4f27b3',
  '#56e670',
  '#c436d7',
  '#58b02e',
  '#db5adf',
  '#b2dc47',
  '#5058d9',
  '#dad940',
  '#432389',
  '#91e376',
  '#e53bb2',
  '#47b961',
  '#a56beb',
  '#e6ba27',
  '#677ce6',
  '#e48a25',
  '#5a8edb',
  '#e44821',
  '#57e5c4',
  '#992b9c',
  '#6fe59f',
  '#b2227b',
  '#3e8228',
  '#e077d3',
  '#8da83e',
  '#9654b3',
  '#ddca5f',
  '#451962',
  '#c3e38b',
  '#313373',
  '#e2a54a',
  '#6256a5',
  '#a99129',
  '#d896de',
  '#5fa86c',
  '#ec5499',
  '#47a790',
  '#d82e3b',
  '#7fe0dd',
  '#e43c67',
  '#58beed',
  '#c66424',
  '#4898bf',
  '#ee6256',
  '#74c4d8',
  '#b2402c',
  '#33788f',
  '#831c1c',
  '#bce0ba',
  '#241028',
  '#dcd08c',
  '#4a1b44',
  '#6c6e1c',
  '#c7559b',
  '#406b35',
  '#b23061',
  '#447862',
  '#ac374a',
  '#cedae4',
  '#121c29',
  '#dfa774',
  '#3c5b8f',
  '#aa7430',
  '#a085ca',
  '#2a4014',
  '#e585ac',
  '#2c442f',
  '#e07179',
  '#245254',
  '#e07f5d',
  '#2c3353',
  '#e4c9b3',
  '#842c69',
  '#8ea379',
  '#611a2e',
  '#adb5e6',
  '#65291a',
  '#7aa5a3',
  '#874d22',
  '#7385b0',
  '#a18d52',
  '#6d4161',
  '#69663a',
  '#e3b1d3',
  '#1e3134',
  '#d18c84',
  '#422826',
  '#b4aaba',
  '#4d3a1d',
  '#ac668e',
  '#5a7079',
  '#9a4b57',
  '#aa937d',
  '#6a5879',
  '#7e5e53',
  '#9e7c8e',
]);
