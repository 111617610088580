import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';

import { Button } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const SearchIcon = styled(MdSearch)`
  height: 2rem;
  width: 2rem;
`;

export const SearchButton = styled(Button)`
  border-top-right-radius: ${radii.lg};
  border-bottom-right-radius: ${radii.lg};
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const Fieldset = styled.fieldset`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};

  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto 1rem;
  position: relative;
`;

export const InputSearch = styled.input`
  -webkit-appearance: none;
  border: 1px solid ${colors.inputBorderLight};
  border-radius: ${radii.lg};
  background-color: ${colors.white300};
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.1;
  outline: none;
  padding: 1rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }

  :focus-within {
    border: 1px solid ${colors.fieldFocus};
  }

  @media (max-width: 337px) {
    width: 100%;
  }
`;
