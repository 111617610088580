import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const ClearButton = styled.p`
  color: ${colors.textLight};
  border: none;
  border-radius: 4px;
  margin-top: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s;
  text-decoration: underline;
`;
