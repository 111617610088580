import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  api,
} from './api';
import { type ICourseDTO } from './courses';
import { type ITemplateColors } from './templates';

export type ICoursePageBuildYourOwn = 'build_your_own';
export type ICoursePageDecisionTool = 'decision_tool';
export type ICoursePageMultipleChoice = 'multiple_choice';

export type ICoursePageType =
  | ICoursePageBuildYourOwn
  | ICoursePageDecisionTool
  | ICoursePageMultipleChoice;

export interface ICoursePageDTO {
  id: number;
  cssContent: string | null;
  htmlContent: string;
  pageNumber: number;
  hasWrapper?: boolean;
  title: string;
  type: ICoursePageType;
  createdAt: string;
  updatedAt: string;
  idExternal?: string | null;
  components?: string | null;
  templateColorsFound?: ITemplateColors | null;
}

export type ICoursePageContentId = Pick<ICoursePageDTO, 'id'>;
export type ICoursePageContent = Omit<
  ICoursePageDTO,
  'createdAt' | 'id' | 'updatedAt'
>;

export type IBatchCoursePagesToPatch = Array<
  ICreateCoursePageProps | IUpdateCoursePageProps
>;

export interface ICreateCoursePageProps extends ICoursePageContent {
  courseId: number;
}

export type IDeleteCoursePageProps = ICoursePageContentId;

export interface ICreateCoursePageFromTemplateProps {
  title: string;
  templateId: number;
  courseId: number;
}

export interface IGetCoursePagesByCourseIdProps {
  courseId: number | string;
}

export interface IGetCoursePageHistoryProps {
  coursePageId: number | string;
  page: number;
  limit: number;
}

export interface IPatchCoursePagesProps {
  courseId: number;
  pages: IBatchCoursePagesToPatch;
}

export interface ICoursePageHistory {
  data: IHistoryPage[];
  meta: IPaginationMetaProps;
}

export interface IHistoryPage {
  version: number;
  page: Partial<ICoursePageDTO>;
  loggedAt: string;
}

export type IUpdateCoursePageProps = ICoursePageContentId &
  Partial<Omit<ICoursePageContent, 'type'>>;

export const createCoursePage = async ({
  ...data
}: ICreateCoursePageProps): Promise<{ message: string }> => {
  const result = await api
    .patch<IResponseData>(`api/course_pages`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createCoursePageFromTemplate = async ({
  title,
  templateId,
  courseId,
}: ICreateCoursePageFromTemplateProps): Promise<ICoursePageDTO> => {
  const result = await api
    .post<IResponseData<ICoursePageDTO>>(
      `api/course_pages/course/${courseId}/template/`,
      {
        title,
        templateId,
      }
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteCoursePage = async ({
  id,
}: IDeleteCoursePageProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`api/course_pages/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCoursePagesByCourseId = async ({
  courseId,
}: IGetCoursePagesByCourseIdProps): Promise<ICoursePageDTO[]> => {
  const result = await api
    .get<IResponseData<ICoursePageDTO[]>>(`api/course_pages/course/${courseId}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteMultipleCoursePages = async (
  pages: number[],
  id: number
): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`api/course_pages/delete_pages/${id}`, {
      data: { pages },
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getCoursePageHistory = async ({
  coursePageId,
  page,
  limit,
}: IGetCoursePageHistoryProps): Promise<ICoursePageHistory> => {
  const result = await api
    .get<IPaginationResponseData<IHistoryPage[]>>(
      `api/course_pages/history/${coursePageId}?page=${page}&limit=${limit}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateCoursePage = async ({
  id,
  ...data
}: IUpdateCoursePageProps): Promise<{ message: string }> => {
  const result = await api
    .patch<IResponseData>(`api/course_pages/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const patchCoursePages = async ({
  courseId,
  pages,
}: IPatchCoursePagesProps): Promise<ICourseDTO> => {
  const result = await api
    .patch<IResponseData<ICourseDTO>>(`api/courses/save/${courseId}`, {
      pages,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
