import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import styled from 'styled-components';

import {
  BaseLoading,
  BaseTabs,
  BaseTabsContent,
  BaseTabsList,
  BaseTabsTrigger,
} from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  overflow: auto;
  padding: 2rem 1.5rem;
  width: 100%;

  ${scrollbar};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TabsRoot = styled(BaseTabs)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsList = styled(BaseTabsList)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${colors.lightGray200};
  margin-bottom: 1rem;
`;

export const TabsTrigger = styled(BaseTabsTrigger)`
  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;

  &:first-child {
    border-top-left-radius: ${radii.sm};
  }
  &:last-child {
    border-top-right-radius: ${radii.sm};
  }

  &[data-state='active'] {
    color: ${colors.labelText};
    font-weight: bold;
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
  }
`;

export const TabsContent = styled(BaseTabsContent)`
  flex-grow: 1;
  padding: 20px;
  background-color: ${colors.white300};
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
`;

export const LockButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.labelText};
  display: inline-flex;
  font-size: 1rem;
  height: 2.5rem;
  justify-content: center;
  width: 2.75rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;
