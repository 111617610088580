export interface ICustomFont {
  id: string;
  label: string;
}

export const fontUnits = [
  { key: 'px', value: 'px' },
  { key: '%', value: '%' },
  { key: 'em', value: 'em' },
  { key: 'rem', value: 'rem' },
  { key: 'vh', value: 'vh' },
  { key: 'vw', value: 'vw' },
];

export const fonts = [
  {
    family: 'Arial',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Helvetica',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Arial Black',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Gadget',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Brush Script MT',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Comic Sans MS',
    category: 'cursive, sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Courier New',
    category: 'monospace',
    origin: 'grapesJS',
  },
  {
    family: 'Courier',
    category: 'monospace',
    origin: 'grapesJS',
  },
  {
    family: 'Georgia',
    category: 'serif',
    origin: 'grapesJS',
  },
  {
    family: 'Impact',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Charcoal',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Lucida Sans Unicode',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Lucida Grande',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Tahoma',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Geneva',
    category: 'sans-serif',
    origin: 'grapesJS',
  },
  {
    family: 'Times New Roman',
    category: 'serif',
    origin: 'grapesJS',
  },
  {
    family: 'Times',
    category: 'serif',
    origin: 'grapesJS',
  },
  {
    family: 'Trebuchet MS',
    category: 'sans-s',
    origin: 'grJS',
  },
  {
    family: 'Verdana',
    category: 'sansif',
    origin: 'grJS',
  },
];
