import { useCallback, useState } from 'react';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { ModalText } from './styles';

interface IDeletePageModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (pageIndex: number) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  pageIndexToDelete: number;
  pageTitle: string;
  waitToOpen?: boolean;
}

export const DeletePageModal = ({
  onAccept,
  onDecline,
  open,
  pageIndexToDelete,
  pageTitle,
  waitToOpen = false,
  loading,
  ...props
}: IDeletePageModalProps): JSX.Element | null => {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDeletePage = useCallback(async () => {
    setLoadingDelete(true);
    await onAccept?.(pageIndexToDelete);
    setLoadingDelete(false);
  }, [onAccept, pageIndexToDelete]);

  const handleDeclineDeletePage = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  return !open ? null : (
    <DialogModal
      acceptText="Delete"
      dataCy="delete-course-page-dialog-modal"
      declineText="Cancel"
      mainText={`Are you sure you want to delete the page ${pageTitle}?`}
      {...props}
      loading={loadingDelete || loading}
      onAccept={() => {
        void handleDeletePage();
      }}
      onDecline={handleDeclineDeletePage}
      onOpenChange={handleDeclineDeletePage}
      open={!waitToOpen}
    >
      <ModalText data-cy="text-informationDeletePage">
        Existing pages in the database will be deleted
      </ModalText>
    </DialogModal>
  );
};
