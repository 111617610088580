import { useCallback, useState } from 'react';

import {
  BaseCheckbox,
  FormBase,
  type IBaseModalProps,
  blockMultipleSpaces,
} from '@gbs-monorepo-packages/common';

import {
  type IDocumentDTO,
  type IRenameDocumentProps,
} from '../../../../services/documentsFolder';
import {
  ContainerWithError,
  Fieldset,
  FormModalCustom,
  Grid,
  Input,
  Label,
} from './styles';

interface IEditDocumentModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  document: IDocumentDTO;
  onAccept: (document: IRenameDocumentProps) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
}

export const EditDocumentModal = ({
  document,
  onAccept,
  onDecline,
  open,
  loading,
  zIndex,
  ...props
}: IEditDocumentModalProps): JSX.Element | null => {
  const [description, setDescription] = useState(document.name ?? '');
  const [highlight, setHighlight] = useState(!!document.highlightedDate);

  const handleEditDependent = useCallback(() => {
    const documentAux: IRenameDocumentProps = {
      documentId: String(document.id),
      description: description.trim() === '' ? null : description.trim(),
      highlighted: highlight,
    };

    onAccept(documentAux);
  }, [description, document.id, highlight, onAccept]);

  const handleDeclineEditDependent = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  return !open ? null : (
    <FormModalCustom
      loading={loading}
      biggerModal
      dataCy="modal-editDocument"
      {...props}
      open={open}
      acceptText="Update"
      declineText="Cancel"
      mainText={`Edit Document '${
        document.name !== null
          ? document.name
          : document.originalName ?? 'uknown'
      }'`}
      onAccept={handleEditDependent}
      onDecline={handleDeclineEditDependent}
      onOpenChange={handleDeclineEditDependent}
      zIndex={zIndex}
    >
      <Grid>
        <ContainerWithError>
          <Fieldset filled={!!description}>
            <Input
              data-cy="input-description"
              value={description}
              autoComplete="off"
              id="description-input"
              name="description"
              onChange={(e) => {
                if (e.target.value.length <= 40) {
                  setDescription(e.target.value);
                }
              }}
              onBlur={(e) => {
                setDescription(blockMultipleSpaces(e.target.value));
              }}
              type="text"
            />
            <Label data-cy="label-description" htmlFor="description-input">
              Description (Optional)
            </Label>
          </Fieldset>
          <FormBase.CheckboxContent
            label="Highlight document on Client Dashboard"
            htmlFor="highlightDocumentCheckbox"
          >
            <BaseCheckbox
              id="highlightDocumentCheckbox"
              checked={highlight}
              dataCy="highlight-document-checkbox"
              onCheckedChange={(checked: boolean) => {
                setHighlight(checked);
              }}
            ></BaseCheckbox>
          </FormBase.CheckboxContent>
        </ContainerWithError>
      </Grid>
    </FormModalCustom>
  );
};
