import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import {
  MdGridView,
  MdOutlineFeaturedPlayList,
  MdOutlineFolder,
  MdOutlineSettings,
  MdWebStories,
} from 'react-icons/md';
import { RiBookLine } from 'react-icons/ri';
import { SiBookstack } from 'react-icons/si';
import styled, { css } from 'styled-components';

const sidebarIconStyles = css`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DashBoardIcon = styled(MdGridView)`
  ${sidebarIconStyles}
`;

export const MembersIcon = styled(RiBookLine)`
  ${sidebarIconStyles}
`;

export const CompaniesIcon = styled(HiOutlineOfficeBuilding)`
  ${sidebarIconStyles}
`;

export const CoursesIcon = styled(SiBookstack)`
  ${sidebarIconStyles}
  fill: transparent;
  stroke: currentColor;
  stroke-width: 1px;
`;

export const DocumentsIcon = styled(MdOutlineFolder)`
  ${sidebarIconStyles}
`;

export const SettingsIcon = styled(MdOutlineSettings)`
  ${sidebarIconStyles}
`;

export const TemplatesIcon = styled(MdWebStories)`
  ${sidebarIconStyles}
`;

export const AdminPortalIcon = styled(MdOutlineFeaturedPlayList)`
  ${sidebarIconStyles}
`;

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;
