import type { Component, Editor, ToolbarButtonProps } from 'grapesjs';

import {
  runCommandIfCanvasFocussed,
  stopPropagationFirstEvent,
} from '../../../../utils/grapes';

export const idDeleteOnlyComponent = 'tlb-delete-only-component';
export const cmdDeleteOnlyComponent = 'delete-only-component';
const shortcutDeleteOnlyComponent =
  '⌘+backspace, ctrl+backspace, ⌘+delete, ctrl+delete';

export const handlerDeleteOnlyComponent = (editor: Editor): void => {
  runCommandIfCanvasFocussed(cmdDeleteOnlyComponent, editor);
};

export const addDeleteOnlyComponentButtonToolbar = (
  component: Component
): ToolbarButtonProps[] => {
  const children = component.components();
  if (
    (component.get('removable') ?? false) &&
    children.length &&
    children.models.some((comp) => comp.get('selectable'))
  ) {
    return [
      {
        id: idDeleteOnlyComponent,
        label:
          '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0H24V24H0z"></path><path d="M20 5c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1 .628.835 1 1.874 1 3 0 2.761-2.239 5-5 5s-5-2.239-5-5c0-1.126.372-2.165 1-3H4c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1h16zm-7 10v2h6v-2h-6zm6-8H5v4h14V7z"></path></g></svg>',
        command: handlerDeleteOnlyComponent,
        attributes: { title: 'Delete only component' },
      },
    ];
  }
  return [];
};

export const DeleteOnlyComponentPlugin = (editor: Editor): void => {
  const { Commands, Keymaps } = editor;
  Keymaps.add(
    cmdDeleteOnlyComponent,
    shortcutDeleteOnlyComponent,
    (editor, ...args) => {
      stopPropagationFirstEvent(args);

      handlerDeleteOnlyComponent(editor);
    }
  );

  Commands.add(cmdDeleteOnlyComponent, {
    run(editor) {
      const selectedComponents = [...editor.getSelectedAll()];
      const body = editor.getWrapper();
      editor.selectRemove(selectedComponents);

      selectedComponents.forEach((selectedComponent) => {
        if (selectedComponent === body || selectedComponent.cid === body?.cid)
          return;

        const children = [...selectedComponent.components().models];
        if (!children.length) return;

        const parent = selectedComponent.parent();
        const selectedComponentIndex = selectedComponent.index();

        children.forEach((child, index) => {
          parent?.append(child, { at: selectedComponentIndex + index });
          if (child.get('selectable') ?? false) {
            editor.selectAdd(child);
          }
        });

        selectedComponent.remove();
      });
    },
  });
};
