// import { SelectFilter } from '@gbs-monorepo-packages/common';

import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  ABORT_REQUEST_REASON,
  DefaultDescription,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IRequestAbortController,
  LIMIT_PAGE,
  assertAbortReason,
  generateAbortRequest,
  isAbortError,
  useToast,
} from '@gbs-monorepo-packages/common';

import { useCompanies as useClient } from '../../hooks/useCompanies';
import { type ISelectFilterValue } from '../../link_common_components/SelectFilter';
import {
  type ICompanyDTO,
  getCompanies as getClients,
} from '../../services/companies';
import Logger from '../../utils/logger';
import { SelectFilterCustom } from './styles';

export interface ISelectCompaniesProps {
  onValueChange?: (id: string, data: ICompanyDTO) => void;
}

const NotFoundIndex = -1 as const;
const NoExtraIndex = undefined;
type IExtraIndex = typeof NoExtraIndex | typeof NotFoundIndex;

interface ILocation extends Location {
  state: { initialAccess: boolean; from: string } | null;
}

export const SelectCompanies = ({
  onValueChange,
}: ISelectCompaniesProps): JSX.Element => {
  const {
    selectedCompany: selectedClient,
    refreshClients,
    setRefreshClients,
  } = useClient();
  const { addToast } = useToast();
  const { user } = useAuth();
  const location: Partial<ILocation> = useLocation();

  const [client, setClient] = useState<ISelectFilterValue<ICompanyDTO> | null>(
    null
  );
  const [clients, setClients] = useState<ICompanyDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const forcedSelectedClientIndex = useRef<IExtraIndex>();

  const [loadingClients, setLoadingClients] = useState(false);

  const lastRequestAbort = useRef<IRequestAbortController | null>(null);
  const searchClient = useRef('');
  const isRefreshing = useRef(false);

  useEffect(() => {
    let mount = true;

    if (selectedClient) {
      const fetchClients = async () => {
        setLoadingClients(true);
        try {
          const { data, meta } = await getClients({
            filter: searchClient.current,
            limit: LIMIT_PAGE,
            page: INIT_PAGE,
          });

          let addedExtraClient: IExtraIndex = NoExtraIndex;
          if (
            location.state?.initialAccess === true &&
            !location.state?.from.includes('companies')
          ) {
            const indexClient = data.findIndex(
              ({ id }) => id === user?.companyId
            );

            if (indexClient !== NotFoundIndex) {
              if (mount) {
                setClient({
                  ...data[indexClient],
                  index: indexClient,
                });
              }
            }
          } else if (selectedClient?.id !== undefined) {
            const indexClient = data.findIndex(
              ({ id }) => id === selectedClient.id
            );

            let newIndex = indexClient;
            if (indexClient === NotFoundIndex) {
              data.push(selectedClient);
              addedExtraClient = NotFoundIndex;
              newIndex = data.length - 1;
            }

            if (mount) {
              setClient({
                ...selectedClient,
                index: newIndex,
              });
            }
          }

          if (mount) {
            forcedSelectedClientIndex.current = addedExtraClient;
            setClients(data);
            setPaginationMeta(meta);
          }
        } catch (error) {
          Logger.debug('error: ', error);
          addToast({
            title: 'Error on getting client',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'fetch-client-error-toast',
            duration: 3000,
          });
        } finally {
          mount && setLoadingClients(false);
        }
      };

      void fetchClients();
    }

    return () => {
      mount = false;
    };
  }, [addToast, selectedClient]);

  const searchClients = useCallback(
    async (newFilter = false) => {
      let pageAux = 0;
      if (newFilter) {
        lastRequestAbort.current?.abort(ABORT_REQUEST_REASON);
      } else {
        if (lastRequestAbort.current?.avoidConcurrency ?? false) {
          Logger.info(
            'Skip new page request, because there is a new search request'
          );
          return;
        }
        pageAux = paginationMeta?.page ?? 0;
      }

      const newRequestAbort = generateAbortRequest(newFilter);
      lastRequestAbort.current = newRequestAbort;

      try {
        const { data: newPageData, meta } = await getClients(
          {
            page: pageAux + 1,
            limit: LIMIT_PAGE,
            filter: searchClient.current ?? '',
          },
          {
            signal: newRequestAbort.signal,
          }
        );
        if (newFilter) {
          let addedExtraClient: IExtraIndex = NoExtraIndex;
          if (selectedClient?.id !== undefined) {
            const indexClient = newPageData.findIndex(
              ({ id }) => id === selectedClient.id
            );

            let newIndex = indexClient;
            if (indexClient === NotFoundIndex) {
              newPageData.push(selectedClient);
              addedExtraClient = NotFoundIndex;
              newIndex = newPageData.length - 1;
            }

            setClient(
              produce((draft) => {
                if (draft) draft.index = newIndex;
              })
            );
          }

          forcedSelectedClientIndex.current = addedExtraClient;
          setClients(newPageData);
        } else {
          const extraIndex = forcedSelectedClientIndex.current;
          if (extraIndex !== NoExtraIndex && selectedClient?.id !== undefined) {
            const index = newPageData.findIndex(
              ({ id }) => id === selectedClient.id
            );

            let offsetSelection = newPageData.length;
            let deleteExtra = 0;
            if (index !== NotFoundIndex) {
              deleteExtra = 1;
              forcedSelectedClientIndex.current = NoExtraIndex;
              if (selectedClient.id === client?.id) {
                offsetSelection = index;
              }
            }
            setClients(
              produce((draft) => {
                draft.splice(extraIndex, deleteExtra, ...newPageData);
              })
            );

            // FIXME: it is offsetting the index of the selected client when loading more clients, but the selected client is not the default one we can not make the offset
            setClient(
              produce((draft) => {
                if (draft) {
                  draft.index = offsetSelection + (draft.index ?? 0);
                }
              })
            );
          } else {
            setClients(
              produce((draft) => {
                draft.push(...newPageData);
              })
            );
          }
        }
        setPaginationMeta(meta);
      } catch (error) {
        if (
          isAbortError(error) &&
          assertAbortReason(newRequestAbort, ABORT_REQUEST_REASON)
        ) {
          Logger.info(
            'Request was aborted, because there is a new search request'
          );
          return;
        }
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting client',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'search-client-error-toast',
          duration: 3000,
        });
      } finally {
        if (lastRequestAbort.current === newRequestAbort) {
          lastRequestAbort.current = null;
        }
      }
    },
    [addToast, client?.id, paginationMeta?.page, selectedClient]
  );

  useEffect(() => {
    if (refreshClients && !isRefreshing.current) {
      isRefreshing.current = true;
      void searchClients(true).then(() => {
        isRefreshing.current = false;
        setRefreshClients(false);
      });
    }
  }, [refreshClients]);

  const handleSearch = useCallback(
    async (newSearch: string) => {
      searchClient.current = newSearch;
      await searchClients(true);
    },
    [searchClients]
  );

  const handleValueChange = useCallback(
    (newValue: ISelectFilterValue<ICompanyDTO>) => {
      setClient(newValue);
      onValueChange?.(newValue.id.toString(), newValue);
    },
    [onValueChange]
  );

  return (
    <SelectFilterCustom
      attribute="name"
      data={clients}
      dataCy="select-companies"
      disabled={loadingClients}
      hasMore={clients.length < (paginationMeta?.total ?? 0)}
      next={searchClients}
      onSearch={handleSearch}
      onValueChange={handleValueChange}
      pageSize={LIMIT_PAGE}
      placeholder="Select a client"
      value={client}
      zIndex={1}
    />
  );
};
