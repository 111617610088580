import styled from 'styled-components';

import { FormModal } from '@gbs-monorepo-packages/common';
import { scrollbar } from '@gbs-monorepo-packages/styles';

export const FormModalCustom = styled(FormModal)`
  overflow: unset;
  max-height: unset;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 75vh;
  padding-top: 0.5rem;
  gap: 1rem;

  ${scrollbar};
`;

export const ColorPickerContainer = styled.div``;
