import { type Component, type Editor } from 'grapesjs';

import {
  appendComponents,
  getTableInfo,
  runCommand,
} from '../../../../../../utils/grapes';
import { DefaultTableLayoutCell } from '../../../../Blocks/TableLayout/components/DefaultTableCell';
import {
  type IAddCellTableLayoutConfig,
  addCellTableLayout,
} from '../AddCellTableLayout';

export const cmdAddColumnTableLayout = 'add-table-column';

export interface IAddColumnTableLayoutConfig
  extends IAddCellTableLayoutConfig {}

export const handlerAddColumnTableLayout = (
  editor: Editor,
  config?: IAddColumnTableLayoutConfig
): void => {
  runCommand(cmdAddColumnTableLayout, editor, config);
};

export const addColumnTableLayout = (
  config: IAddColumnTableLayoutConfig
): Component[] => {
  const { addBefore = false, cell, newCellOptions } = config;

  const { isRegularTable, rows } = getTableInfo(cell);

  if (!isRegularTable) {
    return addCellTableLayout(config);
  }

  const newComponent = DefaultTableLayoutCell(newCellOptions);
  const at = cell.index() + (addBefore ? 0 : 1);
  const newComponents = [];
  for (const row of rows) {
    newComponents.push(...appendComponents(row, newComponent, { at }));
  }
  return newComponents;
};

export const addAddColumnTableLayoutCommand = ({ Commands }: Editor): void => {
  Commands.add(cmdAddColumnTableLayout, {
    // eslint-disable-next-line no-restricted-syntax
    run(editor, _sender, config: IAddColumnTableLayoutConfig) {
      const { selectAdded = true } = config;
      const newComponents = addColumnTableLayout(config);
      if (selectAdded) editor.select(newComponents);
      return newComponents;
    },
  });
};
