import styled from 'styled-components';

import { LazyImage } from '@gbs-monorepo-packages/common';
import { colors } from '@gbs-monorepo-packages/styles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.authPagesBackground};
  height: 100vh;
  text-align: center;
`;

export const Main = styled.main`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.125rem;
  flex-grow: 1;
  align-content: center;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainText = styled.p`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
`;

export const MainActionContainer = styled.div`
  display: flex;
  margin-top: 0.875rem;
  justify-content: center;
`;

export const MainButton = styled.button`
  background-color: ${colors.blue400};
  color: ${colors.white300};
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.75rem;
  padding: 0.3125rem 0.875rem;
  text-transform: uppercase;
  width: 8rem;

  &:hover {
    filter: brightness(0.9);
  }

  & + button {
    margin-left: 0.875rem;
  }
`;

export const Illustration = styled.img`
  width: 300px;
`;

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const FooterLines = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 1.5rem 0;
`;

export const FooterLinesLinks = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;

  a {
    color: ${colors.text};
    text-decoration: none;
  }
`;

export const LazyImageCustom = styled(LazyImage)`
  margin-bottom: 3rem;
  width: unset;
  height: 20vh;
`;
