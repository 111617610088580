import { desktopOnlyColumnClass } from '../..';
import { type IComponentProperties } from '../../../../interfaces';
import DesktopOnlyColumnStyle from './styles.css?inline';

export const DefaultDesktopColumn = (
  components: IComponentProperties[] = []
): IComponentProperties => ({
  components,
  attributes: { class: desktopOnlyColumnClass },
  styles: DesktopOnlyColumnStyle,
  tagName: 'div',
});
