import { produce } from 'immer';
import {
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ABORT_REQUEST_REASON,
  DefaultDescription,
  FormBase,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IRequestAbortController,
  type ISelectFilterValue,
  LIMIT_PAGE,
  assertAbortReason,
  generateAbortRequest,
  isAbortError,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { useCompanies } from '../../../../hooks/useCompanies';
import {
  type ICompanyDTO,
  getCompanies as getClients,
} from '../../../../services/companies';
import Logger from '../../../../utils/logger';
import { ErrorMessage, SelectContainer, SelectFilterData } from './styles';

interface IDuplicateCourseModalProps
  extends Partial<Omit<IDialogModalProps, 'children' | 'onOpenChange'>> {
  defaultValue?: string;
  errorMessage?: string;
  onDecline: () => void;
  onValueChange: (id: string, data: ICompanyDTO) => void;
  open: boolean;
  waitToOpen?: boolean;
}

const NotFoundIndex = -1 as const;
const NoExtraIndex = undefined;
type IExtraIndex = typeof NoExtraIndex | typeof NotFoundIndex;

export const DuplicateCourseModal = ({
  errorMessage,
  loading = false,
  onAccept,
  onDecline,
  onValueChange,
  open,
  waitToOpen = false,
  ...props
}: IDuplicateCourseModalProps): JSX.Element | null => {
  const { addToast } = useToast();

  const { selectedCompany: selectedClient } = useCompanies();

  const [client, setClient] = useState<ISelectFilterValue<ICompanyDTO> | null>(
    null
  );
  const [clients, setClients] = useState<ICompanyDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const isSelectOpen = useRef(new Set());
  const forcedSelectedClientIndex = useRef<IExtraIndex>();
  const [loadingClients, setLoadingClients] = useState(true);

  const lastRequestAbort = useRef<IRequestAbortController | null>(null);
  const searchClient = useRef('');

  useEffect(() => {
    setClient(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    let mount = true;

    const fetchClients = async () => {
      setLoadingClients(true);
      try {
        const { data, meta } = await getClients({
          filter: searchClient.current,
          limit: LIMIT_PAGE,
          page: INIT_PAGE,
        });

        let addedExtraClient: IExtraIndex = NoExtraIndex;
        if (selectedClient?.id !== undefined) {
          const indexClient = data.findIndex(
            ({ id }) => id === selectedClient.id
          );

          let newIndex = indexClient;
          if (indexClient === NotFoundIndex) {
            data.push(selectedClient);
            addedExtraClient = NotFoundIndex;
            newIndex = data.length - 1;
          }

          if (mount) {
            setClient({
              ...selectedClient,
              index: newIndex,
            });
          }
        }

        if (mount) {
          forcedSelectedClientIndex.current = addedExtraClient;
          setClients(data);
          setPaginationMeta(meta);
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting client',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'fetch-client-error-toast',
          duration: 3000,
        });
      } finally {
        mount && setLoadingClients(false);
      }
    };

    if (open) {
      void fetchClients();
    }

    return () => {
      mount = false;
    };
  }, [addToast, open]);

  const searchClients = useCallback(
    async (newFilter = false) => {
      let pageAux = 0;
      if (newFilter) {
        lastRequestAbort.current?.abort(ABORT_REQUEST_REASON);
      } else {
        if (lastRequestAbort.current?.avoidConcurrency ?? false) {
          Logger.info(
            'Skip new page request, because there is a new search request'
          );
          return;
        }
        pageAux = paginationMeta?.page ?? 0;
      }

      const newRequestAbort = generateAbortRequest(newFilter);
      lastRequestAbort.current = newRequestAbort;

      try {
        const { data: newPageData, meta } = await getClients(
          {
            page: pageAux + 1,
            limit: LIMIT_PAGE,
            filter: searchClient.current ?? '',
          },
          {
            signal: newRequestAbort.signal,
          }
        );
        if (newFilter) {
          let addedExtraClient: IExtraIndex = NoExtraIndex;
          if (client?.id !== undefined) {
            const indexClient = newPageData.findIndex(
              ({ id }) => id === client.id
            );

            let newIndex = indexClient;
            if (indexClient === NotFoundIndex) {
              newPageData.push(client);
              addedExtraClient = NotFoundIndex;
              newIndex = newPageData.length - 1;
            }

            setClient(
              produce((draft) => {
                if (draft) draft.index = newIndex;
              })
            );
          }

          forcedSelectedClientIndex.current = addedExtraClient;
          setClients(newPageData);
        } else {
          const extraIndex = forcedSelectedClientIndex.current;
          if (extraIndex !== NoExtraIndex && client?.id !== undefined) {
            const index = newPageData.findIndex(({ id }) => id === client.id);

            let offsetSelection = newPageData.length;
            let deleteExtra = 0;
            if (index !== NotFoundIndex) {
              deleteExtra = 1;
              forcedSelectedClientIndex.current = NoExtraIndex;
              offsetSelection = index;
            }
            setClients(
              produce((draft) => {
                draft.splice(extraIndex, deleteExtra, ...newPageData);
              })
            );

            // FIXME: it is offsetting the index of the selected client when loading more clients, but the selected client is not the default one we can not make the offset
            setClient(
              produce((draft) => {
                if (draft) {
                  draft.index = offsetSelection + (draft.index ?? 0);
                }
              })
            );
          } else {
            setClients(
              produce((draft) => {
                draft.push(...newPageData);
              })
            );
          }
        }
        setPaginationMeta(meta);
      } catch (error) {
        if (
          isAbortError(error) &&
          assertAbortReason(newRequestAbort, ABORT_REQUEST_REASON)
        ) {
          Logger.info(
            'Request was aborted, because there is a new search request'
          );
          return;
        }
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting client',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'search-client-error-toast',
          duration: 3000,
        });
      } finally {
        if (lastRequestAbort.current === newRequestAbort) {
          lastRequestAbort.current = null;
        }
      }
    },
    [addToast, client, paginationMeta?.page]
  );

  const handleSearch = useCallback(
    async (newSearch: string) => {
      searchClient.current = newSearch;
      await searchClients(true);
    },
    [searchClients]
  );

  const handleValueChange = useCallback(
    (newValue: ISelectFilterValue<ICompanyDTO>) => {
      setClient(newValue);
      onValueChange?.(newValue.id.toString(), newValue);
    },
    [onValueChange]
  );

  const clearStates = useCallback(() => {
    setClient(selectedClient);
    setClients([]);
    setPaginationMeta(null);
    forcedSelectedClientIndex.current = NoExtraIndex;
  }, [selectedClient]);

  const handleAccept = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!isSelectOpen.current.size) {
        clearStates();
        onAccept?.(e);
      }
    },
    [clearStates, onAccept]
  );

  const handleDecline = useCallback(() => {
    if (!isSelectOpen.current.size) {
      clearStates();
      onDecline();
    }
  }, [clearStates, onDecline]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const isLoading = loading || loadingClients;

  return !open ? null : (
    <DialogModal
      dataCy="duplicate-course-content-modal"
      {...props}
      acceptText="Confirm"
      declineText="Cancel"
      loading={isLoading}
      mainText="Duplicate course"
      onAccept={handleAccept}
      onDecline={handleDecline}
      onOpenChange={handleDecline}
      open={!waitToOpen}
    >
      <FormBase.InputContent
        data-cy="fieldSet-company"
        filled={!!client}
        label="Select Client"
        inputRef="select-client"
      >
        <SelectContainer>
          <SelectFilterData
            attribute="name"
            data={clients}
            dataCy="button-select-data-client"
            disabled={isLoading}
            hasMore={clients.length < (paginationMeta?.total ?? 0)}
            name="select-client"
            next={searchClients}
            onOpenChange={(isOpen) => {
              handleOpenChange(isOpen, 'options');
            }}
            onSearch={handleSearch}
            onValueChange={handleValueChange}
            pageSize={LIMIT_PAGE}
            placeholder=""
            required
            value={client}
          />
        </SelectContainer>
      </FormBase.InputContent>

      <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>
    </DialogModal>
  );
};
