import { useCallback } from 'react';

import { type IFormModalProps } from '@gbs-monorepo-packages/common';

import { DialogModal } from '../../../../components/DialogModal';
import { type ICommandInfo } from '../../../../components/GrapesJS/interfaces';
import {
  CommandCard,
  CommandCardContainer,
  CommandLabel,
  CommandsDescriptions,
  CommandsDescriptionsContainer,
} from './styles';

interface ICommandsInfoModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  commandsInfo: ICommandInfo[];
  onClose: () => void;
  open: boolean;
  waitToOpen?: boolean;
}

export const CommandsInfoModal = ({
  commandsInfo,
  onClose,
  open,
  waitToOpen = false,
  ...props
}: ICommandsInfoModalProps): JSX.Element | null => {
  const handleDeclineSavePageTemplate = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return !open ? null : (
    <DialogModal
      dataCy="commands-info-dialog-modal"
      mainText="List of commands"
      {...props}
      onOpenChange={handleDeclineSavePageTemplate}
      open={!waitToOpen}
    >
      <CommandCardContainer>
        {commandsInfo.map(({ id, shortcuts, label }) => (
          <CommandCard key={id}>
            <CommandLabel>{label}</CommandLabel>
            <CommandsDescriptionsContainer>
              {shortcuts.map((shortcut, index, { length }) => (
                <CommandsDescriptions key={id + '_' + shortcut}>
                  {shortcut}
                  <span>{index < length - 1 && ', '}</span>
                </CommandsDescriptions>
              ))}
            </CommandsDescriptionsContainer>
          </CommandCard>
        ))}
      </CommandCardContainer>
    </DialogModal>
  );
};
