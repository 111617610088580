import type {
  AddOptions,
  CommandOptions,
  Component,
  ComponentAddType,
  Components,
  Editor,
} from 'grapesjs';

import { type ICommandInfo } from '../components/GrapesJS/interfaces';
import {
  RESIZE_KEY_DIMENSION,
  TABLE_LAYOUT_TYPE,
  translateCommandLabel,
} from '../constants/grapes';
import Logger from './logger';

export const getCanvasContent = (
  { Canvas }: Editor,
  options: { forceFocus: boolean }
): Document | null => {
  let iframeContent = Canvas.getDocument();

  if (
    options.forceFocus &&
    (iframeContent === null || !iframeContent.hasFocus())
  ) {
    const currentDocument = document.getElementsByClassName(
      'gjs-frame'
    ) as HTMLCollectionOf<HTMLElement>;

    currentDocument.item(0)?.focus();

    iframeContent = Canvas.getDocument();
  }

  return iframeContent;
};

export const canvasIsFocussed = (editor: Editor): boolean => {
  const iframeContent = getCanvasContent(editor, { forceFocus: true });

  try {
    if (iframeContent?.hasFocus() ?? false) return true;
  } catch (error) {
    Logger.debug('error: ', ['canvas is not focussed', iframeContent]);
  }
  return false;
};

export const runCommandIfCanvasFocussed = <T = any>(
  command: string,
  editor: Editor,
  options?: CommandOptions
): T | null => {
  options = options ?? {};
  options.requireFocus = true;
  return runCommand<T>(command, editor, options);
};

export const runCommand = <T = any>(
  command: string,
  editor: Editor,
  options?: CommandOptions
): T | null => {
  const { Commands } = editor;
  const isNotRunning = !Commands.isActive(command);

  if (isNotRunning) {
    const requireFocus = Boolean(options?.requireFocus);
    if (!canvasIsFocussed(editor) && requireFocus) return null; // order on if is important
    return Commands.run(command, options) as T;
  }

  return null;
};

export const stopPropagationFirstEvent = (args: any[]): void => {
  args.some((arg) => {
    if (arg instanceof Event) {
      arg.stopPropagation();
      return true;
    }
    return false;
  });
};

const regexToSplitCommands = /\s?,\s?/;

export const getCommandsInfo = (editor: Editor): ICommandInfo[] => {
  const keymaps = editor.Keymaps.getAll();
  const commandsInfo: ICommandInfo[] = [];
  for (const id in keymaps) {
    const keymap = keymaps[id];
    const shortcuts = keymap.keys.split(regexToSplitCommands);
    const label = translateCommandLabel.get(id) ?? keymap.id;
    commandsInfo.push({ ...keymap, shortcuts, label });
  }
  return commandsInfo;
};

export const findInsideComponents = (
  query: string,
  fromComponents: Array<Component | undefined>
): Component[] => {
  const foundComponents: Component[] = [];
  fromComponents.forEach((component) => {
    if (component !== undefined) {
      foundComponents.push(...component.find(query));
    }
  });
  return foundComponents;
};

interface IGetByTypeConfig {
  checkParents?: boolean;
  componentTypes: string[];
  getType?: (component: Component) => string;
  parseQuery?: (componentTypes: string[]) => string;
}

export const findFromComponent = (
  component: Component,
  {
    checkParents = false,
    componentTypes,
    getType = (component: Component) => component.get('tagName') ?? '',
    parseQuery = (componentTypes: string[]) => componentTypes.join(','),
  }: IGetByTypeConfig
): Component | null => {
  const componentType = getType(component).toLocaleLowerCase();
  if (componentTypes.includes(componentType)) {
    return component;
  }
  if (!checkParents) return null;
  return component.closest(parseQuery(componentTypes)) ?? null;
};

export const getByCustomType = (
  component: Component,
  findOptions: Omit<IGetByTypeConfig, 'getType'>
): Component | null => {
  return findFromComponent(component, {
    ...findOptions,
    getType: (component: Component) =>
      component.getEl()?.attributes.getNamedItem('custom-type')?.value ?? '',
    parseQuery: (componentTypes: string[]) =>
      parseToCustomTypeSelector(componentTypes.join(',')),
  });
};

export interface IConfigAdd extends AddOptions {
  repeat?: number;
}

export const addSiblingsComponents = (
  components: Component | Component[],
  newComponent: ComponentAddType,
  config?: IConfigAdd
): Component[] => {
  const parent =
    'length' in components ? components.at(0)?.parent() : components.parent();
  if (parent === undefined) return [];

  return appendComponents(parent, newComponent, config);
};

export const appendComponents = (
  parent: Component,
  newComponent: ComponentAddType,
  config?: IConfigAdd
): Component[] => {
  const { repeat = 1, ...extraConfig } = config ?? {};
  const newComponents: Component[] = [];

  for (let i = 0; i < repeat; i++) {
    newComponents.push(...parent.append(newComponent, extraConfig));
  }

  return newComponents;
};

export const parseToCustomTypeSelector = (selectors: string): string =>
  selectors.replace(/(\w[\d_\w-]*)/g, '[custom-type="$1"]');

export interface ITableInfo {
  isRegularTable: boolean;
  table: Component;
  rows: Components;
}

export const getTableInfo = (element: Component): ITableInfo => {
  const table = getByCustomType(element, {
    checkParents: true,
    componentTypes: TABLE_LAYOUT_TYPE,
  });
  if (!table) {
    throw new Error('table not found');
  }

  const rows = table.components();
  let columnsRef = -1;
  let isRegularTable = true;
  for (const row of rows) {
    const columns = row.components().length;
    if (columns !== columnsRef && columnsRef !== -1) {
      isRegularTable = false;
      break;
    }
    columnsRef = columns;
  }
  return { isRegularTable, table, rows };
};

export const isOnRegularTable = (element: Component): boolean => {
  const table = getByCustomType(element, {
    checkParents: true,
    componentTypes: TABLE_LAYOUT_TYPE,
  });
  if (!table) {
    throw new Error('table not found');
  }

  const rows = table.components();
  let columnsRef = -1;
  let isRegularTable = true;
  for (const row of rows) {
    const columns = row.components().length;
    if (columns !== columnsRef && columnsRef !== -1) {
      isRegularTable = false;
      break;
    }
    columnsRef = columns;
  }
  return isRegularTable;
};

export const harmonizeRowCellSizes = (rowBase: Component): void => {
  const { isRegularTable, rows } = getTableInfo(rowBase);
  if (isRegularTable) {
    const cellsBase = rowBase.components();

    const newWidthsToReplicate = cellsBase.map((cellBase) =>
      Number(cellBase.getStyle(RESIZE_KEY_DIMENSION))
    );

    for (const row of rows) {
      if (row === rowBase) {
        continue;
      }
      const cells = row.components();
      if (cells.length === newWidthsToReplicate.length) {
        cells.forEach((cell, idx) => {
          cell.setStyle({
            [RESIZE_KEY_DIMENSION]: newWidthsToReplicate[idx].toString(),
          });
        });
      }
    }
  }
};
