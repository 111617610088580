export const filterByName = <T extends { name: string }>(
  list: T[],
  newSearch: string
): T[] => {
  return filterByAttribute(list, 'name', newSearch);
};

export const filterByTitle = <T extends { title: string }>(
  list: T[],
  newSearch: string
): T[] => {
  return filterByAttribute(list, 'title', newSearch);
};

export const filterByAttribute = <
  K extends number | string,
  T extends Record<K, string>
>(
  list: T[],
  attribute: K,
  newSearch: string
): T[] => {
  if (!newSearch) return list;
  return list.filter((item) =>
    item[attribute].trim().toLowerCase().includes(newSearch.toLowerCase())
  );
};
