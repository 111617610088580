import {
  MdDeleteOutline,
  MdInsertDriveFile,
  MdOutlineModeEditOutline,
  MdOutlineVisibility,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const TemplateCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  justify-content: space-between;
  align-items: center;
`;

export const TemplateCardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;
`;

export const TemplateCardIconContainer = styled.div`
  display: flex;

  align-items: center;
`;

export const TemplateCardNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const TemplateCardName = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TemplateCardActionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PreviewLink = styled(Link)`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

interface IActionButtonProps {
  readonly isVisible: boolean;
}

export const ActionButton = styled.div<IActionButtonProps>`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background-color: ${colors.backgroundHover};
  }

  ${({ isVisible = false }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

export const TemplateCardIcon = styled(MdInsertDriveFile)`
  height: 2rem;
  width: 2rem;
`;

export interface IIconSizeProps {
  // Requires lower case due to being used on the DOM
  readonly iconsize: 'normal' | 'small';
}

export const PreviewIcon = styled(MdOutlineVisibility)<IIconSizeProps>`
  ${({ iconsize = 'normal' }) => {
    switch (iconsize) {
      case 'small':
        return css`
          height: 1.2rem;
          width: 1.2rem;
        `;

      default:
        return css`
          height: 1.5rem;
          width: 1.5rem;
        `;
    }
  }}
`;

export const EditIcon = styled(MdOutlineModeEditOutline)<IIconSizeProps>`
  ${({ iconsize = 'normal' }) => {
    switch (iconsize) {
      case 'small':
        return css`
          height: 1.2rem;
          width: 1.2rem;
        `;

      default:
        return css`
          height: 1.5rem;
          width: 1.5rem;
        `;
    }
  }}
`;

export const DeleteIcon = styled(MdDeleteOutline)<IIconSizeProps>`
  ${({ iconsize = 'normal' }) => {
    switch (iconsize) {
      case 'small':
        return css`
          height: 1.2rem;
          width: 1.2rem;
        `;

      default:
        return css`
          height: 1.5rem;
          width: 1.5rem;
        `;
    }
  }}
`;
