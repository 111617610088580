import styled from 'styled-components';

import { BaseLoading } from '@gbs-monorepo-packages/common';
import { scrollbar } from '@gbs-monorepo-packages/styles';

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.75rem 0;
  min-height: 3.25rem;
  width: 100%;

  ${scrollbar};
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
`;
