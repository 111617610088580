import React, { type ReactNode, memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { BaseSlot } from '@gbs-monorepo-packages/common';

export interface ICardProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode;
  draggableId: number;
  index: number;
  /**
   * @description This is used to fix the offset of the draggable card when it is dragged outside the viewport, it is used to calculate the offset of the viewport and adjust the position of the card accordingly.
   */
  refViewPort?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

// eslint-disable-next-line no-restricted-syntax
export const Card = memo(function Card({
  draggableId,
  index,
  refViewPort,
  dataCy = 'draggable-card',
  ...props
}: ICardProps) {
  return (
    <Draggable draggableId={`${draggableId}`} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          const draggableStyle = provided.draggableProps.style;

          if (
            draggableStyle &&
            refViewPort?.current &&
            'left' in draggableStyle && // only to fix type
            'top' in draggableStyle // only to fix type
          ) {
            const { x, y } = refViewPort.current.getBoundingClientRect();
            draggableStyle.left -= x; // This will fix offset
            draggableStyle.top -= y; // This will fix offset
          }
        }
        return (
          <BaseSlot
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...props}
            data-cy={dataCy}
          />
        );
      }}
    </Draggable>
  );
});
