import { type Component } from 'grapesjs';
import { useCallback, useRef, useState } from 'react';

import { CheckBoxItem } from '@gbs-monorepo-packages/common';

import { type IFormPopoverProps } from '../../../../components/FormPopover';
import { DefaultTableLayout } from '../../Blocks/TableLayout/components/DefaultTable';
import { TableBuilder } from './components/TableBuilder';
import {
  CheckBoxGroupCustom,
  ExtraConfigsContainer,
  FormModalWide,
  InfoContainer,
  InfoText,
} from './styles';

enum TableLayoutDevice {
  MOBILE_TABLE = 'mobile',
  DESKTOP_TABLE = 'desktop',
}

enum TableLayoutOptions {
  RESPONSIVE_TABLE = 'responsive_table',
  INSERT_TEXT = 'insert_text',
}

interface IModalTableLayout
  extends Partial<Omit<IFormPopoverProps, 'children'>> {
  open: boolean;
  onClose: () => void;
  draggableArea: Component;
}

export const ModalTableLayoutPopover = ({
  open,
  onClose,
  draggableArea,
}: IModalTableLayout): JSX.Element => {
  const [deviceType, setDeviceType] = useState<TableLayoutDevice[]>([
    TableLayoutDevice.MOBILE_TABLE,
    TableLayoutDevice.DESKTOP_TABLE,
  ]);
  const [options, setOptions] = useState<TableLayoutOptions[]>([
    TableLayoutOptions.INSERT_TEXT,
  ]);
  const selectedCellsByRowRef = useRef<number[]>([]);
  const [isTableLayoutConfigWrong, setIsTableLayoutConfigWrong] =
    useState(false);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleCreateButton = useCallback(() => {
    draggableArea?.replaceWith(
      DefaultTableLayout(selectedCellsByRowRef.current, {
        initWithText: options.includes(TableLayoutOptions.INSERT_TEXT),
        responsive: options.includes(TableLayoutOptions.RESPONSIVE_TABLE),
        showOnDesktop: deviceType.includes(TableLayoutDevice.DESKTOP_TABLE),
        showOnMobile: deviceType.includes(TableLayoutDevice.MOBILE_TABLE),
      })
    );
    handleClose();
  }, [deviceType, draggableArea, handleClose, options]);

  const handleTableBuilderChange = useCallback(
    (selectedCellsByRow: number[]) => {
      selectedCellsByRowRef.current = selectedCellsByRow;
      const isInvalidConfig =
        !selectedCellsByRow.length ||
        selectedCellsByRow.some(
          (numCellsSelectedByRow) => numCellsSelectedByRow <= 0
        );
      setIsTableLayoutConfigWrong(isInvalidConfig);
    },
    []
  );

  const disabled = !deviceType.length || isTableLayoutConfigWrong;

  return (
    <FormModalWide
      dataCy="modal-upload-component"
      mainText="Create Table"
      biggerModal={true}
      onOpenChange={handleClose}
      open={open}
      zIndex={10}
      acceptText="Create Table"
      onAccept={handleCreateButton}
      onDecline={handleClose}
      disabled={disabled}
    >
      <InfoContainer>
        <InfoText>CTRL + Click to select columns in a row.</InfoText>
        <InfoText>CTRL + SHIFT + Click to delete row.</InfoText>
      </InfoContainer>
      <TableBuilder onChange={handleTableBuilderChange} />
      <ExtraConfigsContainer>
        <CheckBoxGroupCustom
          type="multiple"
          value={deviceType}
          onValueChange={(value: TableLayoutDevice[]) => {
            if (value.length) setDeviceType(value);
          }}
        >
          <CheckBoxItem
            dataCy={TableLayoutDevice.MOBILE_TABLE + '-item'}
            id="mobile-table-item"
            value={TableLayoutDevice.MOBILE_TABLE}
          >
            Mobile Table
          </CheckBoxItem>
          <CheckBoxItem
            dataCy={TableLayoutDevice.DESKTOP_TABLE + '-item'}
            id="desktop-table-item"
            value={TableLayoutDevice.DESKTOP_TABLE}
          >
            Desktop Table
          </CheckBoxItem>
        </CheckBoxGroupCustom>
        <CheckBoxGroupCustom
          type="multiple"
          value={options}
          onValueChange={(value: TableLayoutOptions[]) => {
            setOptions(value);
          }}
        >
          <CheckBoxItem
            dataCy={TableLayoutOptions.RESPONSIVE_TABLE + '-item'}
            id="responsive-table"
            value={TableLayoutOptions.RESPONSIVE_TABLE}
          >
            Responsive Table
          </CheckBoxItem>
          <CheckBoxItem
            dataCy={TableLayoutOptions.INSERT_TEXT + '-item'}
            id="insert-text"
            value={TableLayoutOptions.INSERT_TEXT}
          >
            Insert Text
          </CheckBoxItem>
        </CheckBoxGroupCustom>
      </ExtraConfigsContainer>
    </FormModalWide>
  );
};
