import { type IComponentProperties } from '../../../../interfaces';
import {
  DefaultTableLayoutRow,
  type IDefaultTableLayoutRowOptions,
} from '../DefaultTableRow';

export interface IDefaultTableLayoutContentOptions
  extends IDefaultTableLayoutRowOptions {}

export const DefaultTableLayoutContent = (
  cellsByRow: number[],
  options: IDefaultTableLayoutContentOptions = {}
): IComponentProperties[] => {
  return Array.from(cellsByRow, (cells) =>
    DefaultTableLayoutRow(cells, options)
  );
};
