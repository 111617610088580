import { HiOutlineMenu, HiOutlineViewGrid } from 'react-icons/hi';
import {
  MdCancel,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdContentCopy,
  MdDeleteOutline,
  MdHistory,
  MdKeyboardArrowDown,
  MdOutlineAddLink,
  MdOutlineFileDownload,
  MdOutlineImage,
  MdOutlineLink,
  MdOutlineModeEditOutline,
  MdOutlineVisibility,
  MdWebStories,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import {
  BaseDropdownItem,
  BaseLoading,
  BaseTriggerDropdown,
  Button,
  LazyImage,
} from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.lightGray50};
  margin: 0;
  padding: 1.875rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoCourseContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoCourseText = styled.p`
  color: ${colors.textLight};
  font-size: 1rem;
  font-weight: 400;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.white300};
  padding: 1rem;
  border-radius: ${radii.xl};
`;

export const LoadingList = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;

  @media screen and (max-width: 500px) {
    & {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0.5rem 0;

  ${scrollbar};
`;

export const CourseActions = styled.div`
  align-items: center;
  background-color: ${colors.white300};
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
`;

export const ActionsButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
`;

export const ActionButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.5rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.5rem;

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }
`;

export const DropdownActionButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const TriggerDropdownAction = styled(BaseTriggerDropdown)`
  background-color: ${colors.white300};

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }
`;

export const DropdownActionSeparator = styled.div`
  border-bottom: 1px solid ${colors.divider};
  margin-top: 0.375rem;
`;

export const DropdownActionItem = styled(BaseDropdownItem)`
  font-size: 1rem;
  font-weight: 400;

  border-radius: ${radii.md};
  color: ${colors.labelText};
  cursor: pointer;
  height: 1.5rem;
  line-height: 1.125rem;
  padding-left: 25;
  padding: 5px;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const DropdownActionItemAsLink = styled(Link)`
  color: inherit;
  display: flex;
  flex-grow: 1;
  text-decoration: none;
`;

export const iconStyles = css`
  height: 1.75rem;
  width: 1.75rem;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  ${iconStyles}
`;

export const DownloadIcon = styled(MdOutlineFileDownload)`
  ${iconStyles}
`;

export const DuplicateIcon = styled(MdContentCopy)`
  ${iconStyles}
`;

export const ImageIcon = styled(MdOutlineImage)`
  ${iconStyles}
`;

export const PreviewIcon = styled(MdOutlineVisibility)`
  ${iconStyles}
`;

export const HistoryIcon = styled(MdHistory)`
  ${iconStyles}
`;

export const GenerateGroupTemplateIcon = styled(MdWebStories)`
  ${iconStyles}
`;

export const MainButtonCustom = styled(Button)`
  border-radius: ${radii.full};
  color: ${colors.button};
  padding: 0.25rem;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

export const MainButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & ${MainButtonCustom}:last-child {
    margin-left: 0.25rem;
  }
`;

export const EditIcon = styled(MdOutlineModeEditOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const ConcludeIcon = styled(MdCheckCircleOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const ConcludedIcon = styled(MdCheckCircle)`
  margin-left: 0.25rem;
  color: green;
`;

export const DisabledIcon = styled(MdCancel)`
  margin-left: 0.25rem;
  color: red;
`;

export const LinkIcon = styled(MdOutlineLink)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const AddLinkIcon = styled(MdOutlineAddLink)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const ButtonAnchor = styled(Link)``;

export const OnDesktopView = styled.div`
  display: none;

  @media screen and (min-width: 1000px) {
    & {
      display: contents;
    }
  }
`;

export const OnMobileView = styled.div`
  display: none;

  @media screen and (max-width: 1000px) {
    & {
      display: contents;
    }
  }
`;

interface ICourseCard {
  disabled?: boolean;
}

export const CourseCard = styled.div<ICourseCard>`
  all: unset;
  background-color: ${colors.white300};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  cursor: ${({ disabled = false }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  min-height: 4.625rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    filter: brightness(0.9);
  }

  & > div + div {
    margin-left: 1rem;
  }

  &:not(:hover) ${OnDesktopView} {
    display: none;
  }

  @media screen and (max-width: 500px) {
    & {
      border: 1px solid ${colors.divider};
      flex-direction: column;
      margin: 0.25rem auto;
      & > div + div {
        margin-left: 0;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  justify-content: center;
  width: 6rem;
`;

export const LazyImageCustom = styled(LazyImage)`
  max-height: 3.25rem;
`;

export const LazyImageGrid = styled(LazyImage)`
  max-height: 100%;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const CourseName = styled.h3`
  color: ${colors.textLight};
  font-size: 1.125rem;
  font-weight: 400;
  width: 78%;
  max-width: 78%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`;

export const Date = styled.p`
  color: ${colors.textLight};
  font-size: 1rem;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  max-width: 10%;
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
`;

export const ModalText = styled.p`
  padding: 0.5rem;
`;

export const ViewURLInput = styled.input`
  border-radius: ${radii.px};
  border: 1px ${colors.lightGray200} solid;
  flex: 1;
  height: 3rem;
  padding-left: 0.5rem;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const ViewURLInputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  width: 100%;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0.875rem;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const GridListOptions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

interface IOptionProps {
  readonly color: string;
}

export const Option = styled.button<IOptionProps>`
  padding: 3px;
  margin: auto 0.5rem;
  background: transparent;
  border: none;
  color: ${({ color }) => color};
  border-radius: ${radii.sm};

  &:hover {
    color: ${colors.button};
  }
`;

export const ListIcon = styled(HiOutlineMenu)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const GridIcon = styled(HiOutlineViewGrid)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const DropdownItem = styled(BaseDropdownItem)`
  all: unset;
  border-radius: ${radii.sm};
  color: ${colors.labelText};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1.125rem;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  border: 0.5px solid ${colors.lightGray200};

  &:not([data-disabled]):hover,
  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }

  &[data-disabled] {
    opacity: 0.8;
  }

  & p {
    margin-top: 0.375rem;
  }
`;

export const DropdownItemGrid = styled(BaseDropdownItem)`
  font-size: 1rem;
  border-radius: ${radii.sm};
  color: ${colors.text};
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 480px) and (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const GridItem = styled.div`
  text-align: center;
  width: 100%;
  margin: 0.5rem auto;
  position: relative;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const CourseCardGrid = styled.div<ICourseCard>`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.textLight};
  display: block;
  height: 100%;
  cursor: ${({ disabled = false }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ImageContainerGrid = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  height: 208px;
  width: 100%;
  justify-content: center;
  padding: 0.5rem;
  clear: both;
  border-bottom: 1px solid ${colors.lightGray50};
  margin-bottom: 1rem;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0rem 1rem 1rem 1rem;

  position: relative;
  min-height: 4rem;
`;

const loadingHide = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

interface ILoadingProps {
  isLoading: boolean;
}

export const Loading = styled.img<ILoadingProps>`
  height: 3rem;
  opacity: 0;
  top: calc(50% - 3rem);
  width: 3rem;
  ${(props) =>
    props.isLoading
      ? css`
          opacity: 1;
        `
      : css`
          @media (prefers-reduced-motion: no-preference) {
            animation-duration: 600ms;
            animation-iteration-count: 1;
            animation-name: ${loadingHide};
            animation-timing-function: ease-out;
          }
        `}
`;
