import { tableLayoutRowType } from '../..';
import { type IComponentProperties } from '../../../../interfaces';
import {
  DefaultTableLayoutCell,
  type IDefaultTableLayoutCellProps,
} from '../DefaultTableCell';
import TableLayoutRowStyle from './styles.css?inline';

export const tableLayoutRowClass = 'table-layout-row';

export interface IDefaultTableLayoutRowOptions
  extends IDefaultTableLayoutCellProps {}

export const DefaultTableLayoutRow = (
  cells: number,
  options: IDefaultTableLayoutRowOptions = {}
): IComponentProperties => ({
  type: tableLayoutRowType,
  attributes: {
    class: tableLayoutRowClass,
  },
  components: Array.from({ length: cells }, () =>
    DefaultTableLayoutCell(options)
  ),
  styles: TableLayoutRowStyle,
});
