import { z } from 'zod';

import { strongPasswordValidRegex } from '@gbs-monorepo-packages/auth';

export const profileSettingSchema = z.object({
  id: z.number(),
  clientId: z.number(),
  email: z.string().email({ message: 'Email must be a valid email' }).trim(),
  firstName: z.string().trim().min(3, {
    message: 'First name must be at least 3 characters',
  }),
  lastName: z
    .string()
    .trim()
    .min(1, { message: 'Last name is required' })
    .min(3, {
      message: 'Last name must be at least 3 characters',
    }),
  roles: z.array(z.string()),
  status: z.string(),
  permissions: z.array(z.string()).optional(),
  currentRole: z.array(z.string()).optional(),
});
export type ProfileSettingSchema = z.infer<typeof profileSettingSchema>;

export const passwordSchema = z.object({
  currentPassword: z
    .string()
    .trim()
    .min(1, { message: 'Current password is required' }),
  newPassword: z
    .string()
    .trim()
    .min(3, { message: 'New password must contain at least 3 characters.' })
    .min(1, { message: 'New password is required' })
    .refine((value) => !/\s/.test(value), {
      message: 'New password cannot contain whitespace',
    })
    .refine((value) => strongPasswordValidRegex.test(value), {
      message:
        'New password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character',
    }),
  confirmPassword: z
    .string()
    .trim()
    .min(1, { message: 'Confirm password is required' }),
});

export type PasswordSchema = z.infer<typeof passwordSchema>;
