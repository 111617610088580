import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { App } from './App';
import {
  BASE_URL,
  CLARITY_KEY,
  EDGE_URL,
  GOOGLE_TAG_MANAGER,
  IS_PROD,
  SENTRY_DSN,
} from './constants/Env';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', BASE_URL, EDGE_URL],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions
  // Session Replay
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (IS_PROD) {
  const tagManagerArgs: { gtmId: string } = {
    gtmId: GOOGLE_TAG_MANAGER,
  };
  (TagManager as { initialize: (args: { gtmId: string }) => void }).initialize(
    tagManagerArgs
  );
  clarity.init(CLARITY_KEY);
}

// to works in client, is related to Vite
window.global = window;
// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
if (!window.Buffer) {
  void import('buffer').then(({ Buffer }) => {
    window.Buffer = Buffer;
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);
