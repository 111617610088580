import { Route, Routes } from 'react-router-dom';

import { SUBDOMAIN_COURSE_VIEWER } from './constants/RoutePaths';
import { CourseViewer } from './pages/CourseViewer';
import { getRouteFrom } from './utils/getRoutes';

export function RouterSubdomain(): JSX.Element {
  return (
    <Routes>
      <Route
        path={getRouteFrom(SUBDOMAIN_COURSE_VIEWER)}
        element={<CourseViewer />}
      />
      <Route path="*" element={<h1>Page Not Found: 404</h1>} />
    </Routes>
  );
}
