import { produce } from 'immer';
import { type MouseEvent, useCallback, useEffect, useState } from 'react';

import { Cell, LayoutConfigContainer, Row } from './styles';

const MAX_ROW = 6;
const MAX_COLUMN = 5;

const CELLS_BY_LINE: string[][] = Array.from(
  { length: MAX_ROW },
  (_, rowIndex) =>
    Array.from(
      { length: MAX_COLUMN },
      (_, columnIndex) => `ROW_${rowIndex}_COLUMN_${columnIndex}`
    )
);
const SELECTED_CELLS_BY_LINE: number[] = [];

interface ITableBuilderProps {
  disabled?: boolean;
  onChange?: (selectedCellsByRow: number[]) => void;
}

export const TableBuilder = ({
  disabled = false,
  onChange,
}: ITableBuilderProps): JSX.Element => {
  const [selectedCellsByRow, setSelectedCellsByRow] = useState<number[]>(
    SELECTED_CELLS_BY_LINE
  );

  const deleteRow = useCallback((rowIndex: number) => {
    setSelectedCellsByRow(
      produce((draft) => {
        draft.splice(rowIndex, 1);
      })
    );
  }, []);

  const setColumnsInRow = useCallback(
    (rowIndex: number, numColumns: number) => {
      setSelectedCellsByRow(
        produce((draft) => {
          const rowsDiff = rowIndex + 1 - draft.length;
          if (rowsDiff > 0) {
            draft.splice(
              draft.length,
              0,
              ...Array.from({ length: rowsDiff }, () => numColumns)
            );
            return;
          }
          draft[rowIndex] = numColumns;
        })
      );
    },
    []
  );

  const setColumnFromFirstRow = useCallback(
    (rowIndex: number, numColumns: number) => {
      setSelectedCellsByRow(
        produce((draft) => {
          const filled = draft.fill(numColumns, 0, rowIndex + 1);
          const rowsDiff = rowIndex + 1 - filled.length;
          if (rowsDiff > 0) {
            draft.splice(
              filled.length,
              0,
              ...Array.from({ length: rowsDiff }, () => numColumns)
            );
          } else if (rowsDiff < 0) {
            draft.splice(rowIndex + 1, -rowsDiff);
          }
          return filled;
        })
      );
    },
    []
  );

  const handleCellClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, rowIndex: number, cellIndex: number) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.ctrlKey) {
        if (e.shiftKey) {
          deleteRow(rowIndex);
          return;
        }
        setColumnsInRow(rowIndex, cellIndex + 1);
        return;
      }
      setColumnFromFirstRow(rowIndex, cellIndex + 1);
    },
    [deleteRow, setColumnFromFirstRow, setColumnsInRow]
  );

  useEffect(() => {
    onChange?.(selectedCellsByRow);
  }, [onChange, selectedCellsByRow]);

  return (
    <LayoutConfigContainer>
      {CELLS_BY_LINE.map((cells, rowIndex) => {
        return (
          <Row key={rowIndex} data-cy={rowIndex}>
            {cells.map((cellKey, cellIndex) => {
              return (
                <Cell
                  $isSelected={selectedCellsByRow[rowIndex] > cellIndex}
                  data-cy={cellKey}
                  disabled={disabled}
                  key={cellKey}
                  onClick={(e) => {
                    handleCellClick(e, rowIndex, cellIndex);
                  }}
                  type="button"
                />
              );
            })}
          </Row>
        );
      })}
    </LayoutConfigContainer>
  );
};
