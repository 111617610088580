import { type ReactNode, createContext, useCallback, useState } from 'react';

import * as CoursesService from '../services/courses';
import type * as TemplateService from '../services/templates';
import Logger from '../utils/logger';

export interface ICoursesContextData {
  saveCourse: (course?: CoursesService.ICourseDTO | null) => Promise<void>;
  selectCourseByID: (
    courseId: number
  ) => Promise<CoursesService.ICourseDTO | null>;
  selectedCourse: CoursesService.ICourseDTO | null;
  courseSettings: CoursesService.ICourseSettingsProps | null;
  selectedTemplate: TemplateService.ITemplateDTO | null;
  updateCourse: (course: CoursesService.ICourseDTO | null) => void;
  updateCourseSettings: (
    course: CoursesService.ICourseSettingsProps | null
  ) => void;
  updateTemplate: (template: TemplateService.ITemplateDTO | null) => void;
}

interface ICoursesProps {
  children: ReactNode;
}

export const CoursesContext = createContext<ICoursesContextData>(
  {} as ICoursesContextData
);

export const CoursesProvider = ({ children }: ICoursesProps): JSX.Element => {
  const [selectedCourse, setSelectedCourse] =
    useState<CoursesService.ICourseDTO | null>(null);

  const [courseSettings, setCourseSettings] =
    useState<CoursesService.ICourseSettingsProps | null>(null);

  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateService.ITemplateDTO | null>(null);
  const saveCourse = useCallback(
    async (course: CoursesService.ICourseDTO | null = selectedCourse) => {
      if (!course) {
        return;
      }
      const { viewUrl, passcodeProtected, passcode, status } =
        courseSettings ?? {};

      course.title = course.title.trim();
      course.viewURL = viewUrl?.trim() ?? null;
      course.status = status?.trim() ?? 'In Progress';
      course.passcodeProtected = passcodeProtected ?? false;
      course.passcode = passcode ?? '';

      const result = await CoursesService.updateCourse(course);

      setSelectedCourse(result);
    },
    [selectedCourse, courseSettings]
  );

  const selectCourseByID = useCallback(
    async (courseId: number): Promise<CoursesService.ICourseDTO | null> => {
      try {
        const courseIdFromPath = Number(courseId);
        const currentTitle = selectedCourse?.title;

        const result = await CoursesService.getCoursesById({
          id: courseIdFromPath,
        });

        const editedTitle = currentTitle === result.title ? null : currentTitle;
        if (editedTitle) {
          result.title = editedTitle;
        }

        setSelectedCourse(result);
        setCourseSettings({
          viewUrl: result?.viewURL ?? '',
          status: result?.status ?? 'In Progress',
          passcodeProtected: result?.passcodeProtected ?? false,
          passcode: result?.passcodeProtected ? result?.passcode : '',
        });

        return result;
      } catch (error) {
        Logger.debug('error: ', error);
        return null;
      }
    },
    [selectedCourse]
  );

  const updateCourse = useCallback(
    (course: CoursesService.ICourseDTO | null) => {
      setSelectedCourse(course);
    },
    []
  );

  const updateCourseSettings = useCallback(
    (courseSettings: CoursesService.ICourseSettingsProps | null) => {
      setCourseSettings(courseSettings);
    },
    []
  );

  const updateTemplate = useCallback(
    (template: TemplateService.ITemplateDTO | null) => {
      setSelectedTemplate(template);
    },
    []
  );

  return (
    <CoursesContext.Provider
      value={{
        saveCourse,
        selectCourseByID,
        updateCourse,
        updateCourseSettings,
        updateTemplate,
        selectedCourse,
        courseSettings,
        selectedTemplate,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
