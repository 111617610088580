import { type CSSProperties } from 'react';
import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { SelectFilter } from '../../link_common_components/SelectFilter';
import { type ICompanyDTO } from '../../services/companies';

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

const selectFilterContentCustom: CSSProperties = {
  // overflow: 'auto !important',
  // height: '100%',
  maxHeight: '90vh',
  // width: '100%',
  position: 'absolute',
  left: '12px',
};

export const SelectFilterCustom = styled(SelectFilter<ICompanyDTO>).attrs(
  ({ customContentStyle = selectFilterContentCustom }) => ({
    customContentStyle,
  })
)``;
