import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 0 0 1.5rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.white300};
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & button + button {
    margin-left: 1rem;
  }
`;
