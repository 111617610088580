import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';
import TwoColumnsResponsiveStyle from './styles.css?inline';

export const twoColumnsResponsiveId = 'two-columns-responsive';

const TwoColumnsResponsivePlugin = (editor: Editor): void => {
  const TwoColumnsResponsiveContent: IBlockContent = {
    attributes: { class: 'column-gbs-2' },
    content: '',
    editable: true,
    styles: TwoColumnsResponsiveStyle,
    tagName: 'div',
  };

  const CenterContainer: IBlockContent = {
    components: [TwoColumnsResponsiveContent, TwoColumnsResponsiveContent],
    editable: true,
    attributes: { class: 'responsive-two-columns' },
    tagName: 'div',
  };

  editor.BlockManager.add(twoColumnsResponsiveId, {
    category: 'Responsive',
    content: CenterContainer,
    label: '2 Columns Responsive',
    media:
      '<svg viewBox="0 0 23 24"> <path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path> </svg>',
    select: true,
  });
};

export default TwoColumnsResponsivePlugin;
