import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const TextContainer = styled.div`
  margin: 1rem;
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;
