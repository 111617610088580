import styled from 'styled-components';

import { BaseLoading, BaseModal } from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const BaseModalCustom = styled(BaseModal)`
  height: 90vh;
`;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${colors.gray200};
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  & button + button {
    margin-left: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 0.875rem;
  text-align: center;
  color: ${colors.gray200};
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  max-height: 5rem;
  overflow-y: auto;

  ${scrollbar}
`;

export const ErrorMessage = styled.p`
  border: ${radii.xs};
  color: ${colors.red300};
  font-size: 0.875rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const IFrame = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
  margin: 1rem auto;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ImageContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
`;
