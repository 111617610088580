import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';

export const mailToLinkId = 'mailto-link';
export const mailToLinkType = 'mailto-link';
export const mailToLinkContainerType = `${mailToLinkType}-container`;

const MailtoBlockPlugin = (editor: Editor): void => {
  editor.DomComponents.addType(mailToLinkType, {
    extend: 'link',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value === mailToLinkType,
    model: {
      defaults: {
        attributes: {
          href: 'mailto:',
          class: 'mailto-link',
          'custom-type': mailToLinkType,
        },
        tagName: 'a',
        traits: [
          {
            name: 'email',
            label: 'Email',
            type: 'text',
            changeProp: true,
          },
          {
            name: 'subject',
            label: 'Subject',
            type: 'text',
            changeProp: true,
          },
          {
            name: 'body',
            label: 'Body',
            type: 'text',
            changeProp: true,
          },
        ],
        name: 'Mailto Link',
      },
    },
  });

  editor.DomComponents.addType(mailToLinkContainerType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value ===
      mailToLinkContainerType,
    model: {
      defaults: {
        attributes: {
          'custom-type': mailToLinkContainerType,
        },
        tagName: 'div',
        name: 'Mailto Link Container',
      },
    },
  });

  const MailtoLinkContent: IBlockContent = {
    attributes: { class: 'mailto-link' },
    content: 'Mailto Link',
    editable: true,
    type: mailToLinkType,
  };

  const CenterContainer: IBlockContent = {
    components: MailtoLinkContent,
    editable: true,
    style: { 'text-align': 'center', width: '100%' },
    type: mailToLinkContainerType,
  };

  editor.BlockManager.add(mailToLinkId, {
    category: 'Basic',
    content: CenterContainer,
    label: 'Mailto Link',
    media:
      '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 5H2.5C1.673 5 1 5.673 1 6.5V17.5C1 18.327 1.673 19 2.5 19H21.5C22.327 19 23 18.327 23 17.5V6.5C23 5.673 22.327 5 21.5 5ZM2.5 6.5H21.5L12.438 13.477C12.088 13.739 11.612 13.739 11.262 13.477L2.5 6.5ZM21.5 17.5H2.5V8.476L11.262 15.453C11.612 15.715 12.088 15.715 12.438 15.453L21.5 8.476V17.5Z" fill="currentColor"/></svg>',
    select: true,
  });

  // Listen for changes to the email, subject, or body traits and update the mailto link accordingly
  editor.on('component:update', (model) => {
    const emailChanged = model.changedAttributes().email;
    const subjectChanged = model.changedAttributes().subject;
    const bodyChanged = model.changedAttributes().body;

    if (
      emailChanged !== undefined ||
      subjectChanged !== undefined ||
      bodyChanged !== undefined
    ) {
      const el = model.view.el;
      const email: string = model
        .get('traits')
        .where({ name: 'email' })[0]
        .get('value');
      const subject = model
        .get('traits')
        .where({ name: 'subject' })[0]
        .get('value');
      const body = model.get('traits').where({ name: 'body' })[0].get('value');
      const href = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      el.setAttribute('href', href);

      const attributes = model.get('attributes');
      attributes.href = href;
      model.set('attributes', attributes);
    }
  });

  editor.on('component:selected', (model) => {
    const modelAttributes = model.get('attributes');
    const customType = modelAttributes['custom-type'];

    if (customType === mailToLinkType) {
      const href = modelAttributes.href;

      let subject = '';
      let body = '';

      const [email, params] = href.split('?');

      if (params !== undefined) {
        const paramsArr = params.split('&');
        subject = decodeURIComponent(paramsArr[0]?.split('=')[1]);
        body = decodeURIComponent(paramsArr[1]?.split('=')[1]);

        // Set the traits for the email address, subject, and body
        model.set('email', email.replace('mailto:', ''));
        model.set('subject', subject);
        model.set('body', body);
      }
    }
  });
};

export default MailtoBlockPlugin;
