import styled from 'styled-components';

import { CheckBoxGroup } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const TitleText = styled.p`
  margin-bottom: 1rem;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  & > * {
    margin-right: 1rem;
  }
`;

export const CheckBoxGroupCustom = styled(CheckBoxGroup)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  div + div {
    margin-top: 0.125rem;
  }
`;

export const DroppableContainer = styled.div``;
