import { globalStyleDefault } from '../constants/GlobalStyles';
import { type IGlobalStyleDTO } from '../services/courses';

export const generateNegativeTimestamp = (): number => {
  const tempId = new Date().getTime();
  return tempId < 0 ? tempId : -tempId;
};

export const generateCssFromGlobalStyle = (
  globalStyle: IGlobalStyleDTO
): string => {
  let css = `
  body {
    color: ${
      globalStyle.paragraphFontColor ?? globalStyleDefault.paragraphFontColor
    };
    font-size: ${String(
      globalStyle.paragraphFontSize ?? globalStyleDefault.paragraphFontSize
    )}${String(
    globalStyle.paragraphFontSizeUnit ??
      globalStyleDefault.paragraphFontSizeUnit
  )};
  }
  .heading {
    color: ${
      globalStyle.headlineFontColor ?? globalStyleDefault.headlineFontColor
    };
    font-size: ${String(
      globalStyle.headlineFontSize ?? globalStyleDefault.headlineFontSize
    )}${String(
    globalStyle.headlineFontSizeUnit ?? globalStyleDefault.headlineFontSizeUnit
  )};
  }
  `;

  if (globalStyle?.paragraphFont?.family) {
    css += `
    body {
      font-family: ${globalStyle.paragraphFont.family}, ${globalStyle.paragraphFont.category};
    }
    `;
  }

  if (globalStyle?.headlineFont?.family) {
    css += `
    .heading {
      font-family: ${globalStyle.headlineFont.family}, ${globalStyle.headlineFont.category};
    }
    `;
  }

  const buttonColor = globalStyle.buttonColor ?? globalStyleDefault.buttonColor;
  css += `
.mailto-link, .link-button, .link-document {
background-color: ${buttonColor};
border: 1px solid ${buttonColor};
color: ${globalStyle.buttonFontColor ?? globalStyleDefault.buttonFontColor};
}
.mailto-link:hover, .link-button:hover, .link-document:hover {
background-color: ${buttonColor};
}
.outline-button {
background-color: transparent;
color: ${buttonColor};
border-width: 2px;
}
.outline-button:hover {
background-color: transparent;
}
`;

  const primaryColor =
    globalStyle.primaryColor ??
    globalStyle.buttonColor ??
    globalStyleDefault.primaryColor;

  css += `
.primary-cl {
color: ${primaryColor};
}
.primary-bg {
background-color: ${primaryColor};
}
.secondary-cl {
color: ${globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor};
}
.secondary-bg {
background-color: ${
    globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor
  };
}
.color-filter {
filter: hue-rotate(${globalStyle.hueRotate ?? globalStyleDefault.hueRotate}deg);
}
`;

  if (globalStyle.pageWidth !== null) {
    const pageWidthAux =
      String(globalStyle.pageWidth) + (globalStyle.pageWidthUnit ?? '');
    css += `.content-center {
padding: 0 calc((100% - ${pageWidthAux})/2);
}`;
  }

  return css;
};
