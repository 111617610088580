import { mobileOnlyColumnClass } from '../..';
import { type IComponentProperties } from '../../../../interfaces';
import MobileOnlyColumnStyle from './styles.css?inline';

export const DefaultMobileOnlyColumn = (
  components: IComponentProperties[] = []
): IComponentProperties => ({
  components,
  attributes: { class: mobileOnlyColumnClass },
  styles: MobileOnlyColumnStyle,
  tagName: 'div',
});
