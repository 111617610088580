import { type MouseEvent, type ReactNode, forwardRef } from 'react';
import { generatePath } from 'react-router-dom';

import {
  BaseTooltip,
  type ICheckBoxCardProps,
} from '@gbs-monorepo-packages/common';

import { TEMPLATE_VIEW } from '../../../../constants/RoutePaths';
import { type ITemplatePageDTO } from '../../../../services/templates';
import { getRouteFrom } from '../../../../utils/getRoutes';
import { type ITemplatePage } from '../AddCourseWithTemplateModal';
import {
  ActionButton,
  CheckBoxCardCustom,
  PreviewIcon,
  TemplateTitle,
} from './styles';

export interface IData extends ITemplatePageDTO {
  sourceIndex?: number;
}

export interface ITemplateCardProps
  extends Omit<ICheckBoxCardProps, 'children' | 'value'> {
  data: ITemplatePage;
  dataCy?: string;
  value?: string;
  children?: ReactNode;
}

export const TemplateCard = forwardRef<HTMLButtonElement, ITemplateCardProps>(
  // eslint-disable-next-line no-restricted-syntax
  function TemplateCard(
    {
      children,
      data: { id, title },
      dataCy = title,
      value = `${id}`,
      ...props
    },
    ref
  ): JSX.Element {
    const handlePreviewData = (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
    };

    return (
      <CheckBoxCardCustom
        {...props}
        ref={ref}
        dataCy={dataCy}
        isNotButton
        value={value}
      >
        <TemplateTitle data-cy="item-text">{title}</TemplateTitle>
        <BaseTooltip message="Preview">
          <ActionButton
            data-cy="actionButton"
            target="_blank"
            to={generatePath(getRouteFrom(TEMPLATE_VIEW), {
              templateId: id,
            })}
            onClick={(e) => {
              handlePreviewData(e);
            }}
          >
            <PreviewIcon />
          </ActionButton>
        </BaseTooltip>
        {children}
      </CheckBoxCardCustom>
    );
  }
);
