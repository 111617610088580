import { type ResizerOptions } from 'grapesjs';

import {
  cmdAddNewBulletItem,
  cmdBreakWithNewLine,
  cmdListGoToLeft,
  cmdListGoToRight,
} from '../components/GrapesJS/Blocks/BulletPoint';
import {
  tableLayoutCellType,
  tableLayoutRowType,
  tableLayoutType,
} from '../components/GrapesJS/Blocks/TableLayout';
import { cmdDeleteOnlyComponent } from '../components/GrapesJS/Customs/DeleteOnlyComponent';
import { cmdOpenSearchComponents } from '../components/GrapesJS/Customs/SearchComponents';
import { cmdSelectChildren } from '../components/GrapesJS/Customs/SelectChildren';

export const translateCommandLabel = new Map([
  [cmdSelectChildren, 'Select children'],
  [cmdDeleteOnlyComponent, 'Delete only component'],
  [cmdOpenSearchComponents, 'Search components'],
  ['core:undo', 'Undo'],
  ['core:redo', 'Redo'],
  ['core:copy', 'Copy'],
  ['core:paste', 'Paste'],
  ['core:component-next', 'Component next'],
  ['core:component-prev', 'Component prev'],
  ['core:component-enter', 'Component enter'],
  ['core:component-exit', 'Component exit'],
  ['core:component-delete', 'Component delete'],
  [cmdAddNewBulletItem, 'Bullet: Create a new list item'],
  [cmdListGoToRight, ' Bullet: Indent to right'],
  [cmdListGoToLeft, 'Bullet: Outdent to left'],
  [cmdBreakWithNewLine, 'Bullet: Break with new line'],
]);

export const smScreens = {
  height: '568px',
  mediaHeight: '768px',
  mediaWidth: '768px', // last-value: 575px
  width: '320px',
} as const;

export const mdScreens = {
  mediaWidth: '768px', // last-value: 768px
  width: '768px',
} as const;

export const CELL_TABLE_LAYOUT_SPOT_TYPE = 'cell-table-layout-spot-type';
export const ROW_TABLE_LAYOUT_SPOT_TYPE = 'row-table-layout-spot-type';
export const TABLE_LAYOUT_WRAPPER_SPOT_TYPE = 'table-layout-wrapper-spot-type';
export const SPOTS_TYPES = [
  CELL_TABLE_LAYOUT_SPOT_TYPE,
  ROW_TABLE_LAYOUT_SPOT_TYPE,
  TABLE_LAYOUT_WRAPPER_SPOT_TYPE,
];

export const CELL_TABLE_LAYOUT_TYPE = [tableLayoutCellType];
export const ROW_TABLE_LAYOUT_TYPE = [tableLayoutRowType];
export const TABLE_LAYOUT_TYPE = [tableLayoutType];

export const RESIZE_CELL_MIN_DIM = 0;
export const DEFAULT_CELL_DIM = 100;
export const RESIZE_KEY_DIMENSION = 'flex-grow';

export const defaultResizeCellOptions = {
  bc: false,
  bl: false,
  br: false,
  cl: false,
  cr: true,
  currentUnit: false,
  minDim: RESIZE_CELL_MIN_DIM,
  tc: false,
  tl: false,
  tr: false,
  unitWidth: '',
  keyWidth: RESIZE_KEY_DIMENSION,
} satisfies ResizerOptions;
