import { type CallbackOptions, type Component, type Editor } from 'grapesjs';

import { RESIZE_KEY_DIMENSION } from '../../../../../../constants/grapes';
import {
  harmonizeRowCellSizes,
  runCommand,
} from '../../../../../../utils/grapes';

export const cmdResizeCellTableLayout = 'resize-table-cell';

export interface IResizeCellCustomConfig {
  isIsolated?: boolean;
  lastDim?: number;
}

export interface IResizeCellTableLayoutConfig
  extends Pick<CallbackOptions, 'resizer'>,
    IResizeCellCustomConfig {}

export const handlerResizeCellTableLayout = (
  editor: Editor,
  config?: Omit<IResizeCellTableLayoutConfig, 'editor'>
): void => {
  runCommand(cmdResizeCellTableLayout, editor, config);
};

export const resizeCellTableLayout = (
  selectedComponent: Component,
  { resizer }: IResizeCellTableLayoutConfig
): void => {
  const lastDim = resizer.startDim?.w ?? 0;
  const newCellDim = resizer.rectDim?.w ?? 0;
  const offset = newCellDim - lastDim;
  if (offset === 0) {
    return;
  }

  const selectedRow = selectedComponent.parent();

  if (!selectedRow) {
    throw new Error('No row found');
  }

  const siblingsCells = selectedRow.components();

  const sumFlexGrow = siblingsCells.reduce((acc, child) => {
    return (
      acc +
      (selectedComponent === child
        ? newCellDim
        : Number(child.getStyle(RESIZE_KEY_DIMENSION)))
    );
  }, 0);
  const oldSumFlexGrow = sumFlexGrow - offset;
  const selectedComponentIndex = selectedComponent.index();

  const rightButton = resizer.handlerAttr === 'cr';

  let adjustableCutPoint: number[] = [];
  const siblingsAnchor = rightButton
    ? siblingsCells.slice(0, selectedComponentIndex)
    : siblingsCells.slice(selectedComponentIndex + 1);

  const isIsolated = resizer.keys?.ctrl ?? false;
  const mustHarmonize = resizer.keys?.shift ?? false;

  switch (true) {
    case rightButton && isIsolated: {
      siblingsAnchor.push(...siblingsCells.slice(selectedComponentIndex + 2));
      // only the next sibling
      adjustableCutPoint = [
        selectedComponentIndex + 1,
        selectedComponentIndex + 2,
      ];
      break;
    }
    case rightButton && !isIsolated:
      // all next sibling
      adjustableCutPoint = [selectedComponentIndex + 1];
      break;
    case !rightButton && isIsolated:
      siblingsAnchor.push(
        ...siblingsCells.slice(0, selectedComponentIndex - 1)
      );
      // only the previous sibling
      adjustableCutPoint = [selectedComponentIndex - 1, selectedComponentIndex];
      break;
    case !rightButton && !isIsolated:
      // all previous sibling
      adjustableCutPoint = [0, selectedComponentIndex];
      break;
  }

  if (!siblingsAnchor.length) {
    if (mustHarmonize) harmonizeRowCellSizes(selectedRow);
    return;
  }

  const siblingsAdjustable = siblingsCells.slice(...adjustableCutPoint);
  if (!siblingsAdjustable.length) {
    if (mustHarmonize) harmonizeRowCellSizes(selectedRow);
    return;
  }

  const offsetBehind = sumFlexGrow / oldSumFlexGrow;
  let offsetAhead = 0;

  for (const sibling of siblingsAnchor) {
    const siblingDim = Number(sibling.getStyle(RESIZE_KEY_DIMENSION));
    let newSiblingDim = siblingDim * offsetBehind;

    if (newSiblingDim < 0) {
      newSiblingDim = 0;
      continue;
    }
    offsetAhead += newSiblingDim - siblingDim;
    sibling.setStyle({
      [RESIZE_KEY_DIMENSION]: newSiblingDim.toString(),
    });
  }

  offsetAhead /= siblingsAdjustable.length;

  for (const sibling of siblingsAdjustable) {
    const siblingDim = Number(sibling.getStyle(RESIZE_KEY_DIMENSION));
    const newSiblingDim = siblingDim - offsetAhead;

    if (newSiblingDim < 0) {
      continue;
    }
    sibling.setStyle({
      [RESIZE_KEY_DIMENSION]: newSiblingDim.toString(),
    });
  }

  if (mustHarmonize) harmonizeRowCellSizes(selectedRow);
};

export const addResizeCellTableLayoutCommand = ({ Commands }: Editor): void => {
  Commands.add(cmdResizeCellTableLayout, {
    // eslint-disable-next-line no-restricted-syntax
    run(editor, _sender, config: IResizeCellTableLayoutConfig) {
      const selectedComponent = editor.getSelected() ?? null;
      if (!selectedComponent) return;
      resizeCellTableLayout(selectedComponent, { ...config });
    },
  });
};
