import type { Component, Editor, ToolbarButtonProps } from 'grapesjs';

import { canvasIsFocussed } from '../../../../utils/grapes';

export const addBaseButtonToolbar = (
  component: Component
): ToolbarButtonProps[] => {
  const baseToolbarButtons: ToolbarButtonProps[] = [];
  const { em: editorModel } = component;
  const prefix = editorModel?.getConfig()?.stylePrefix ?? 'gjs-';

  Boolean(component.collection) &&
    baseToolbarButtons.push({
      id: 'tlb-select-parent',
      attributes: { title: 'Select parent' },
      command: (editor: Editor) =>
        editor.runCommand('core:component-exit', {
          force: 1,
        }),
      label:
        '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" /></svg>',
    });
  Boolean(component.get('draggable')) &&
    baseToolbarButtons.push({
      id: 'tlb-move',
      attributes: {
        class: `${prefix}no-touch-actions`,
        draggable: true,
        title: 'Move',
      },
      command: 'tlb-move',
      label:
        '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z"/></svg>',
    });
  Boolean(component.get('copyable')) &&
    baseToolbarButtons.push({
      id: 'tlb-clone',
      attributes: { title: 'Duplicate' },
      command: 'tlb-clone',
      label:
        '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg>',
    });
  Boolean(component.get('removable')) &&
    baseToolbarButtons.push({
      id: 'tlb-delete',
      attributes: { title: 'Delete with content' },
      command: 'tlb-delete',
      label:
        '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>',
    });
  return baseToolbarButtons;
};

export const PreventDefaultCommandError = (editor: Editor): void => {
  editor.on('run:core:component-exit:before', (options) => {
    if (!canvasIsFocussed(editor)) {
      options.abort = true;
    }
  });
};

const getVideoId = (url: string): string => {
  const urlSplitted = url.split('/');
  return urlSplitted[urlSplitted.length - 1];
};

export const updateSrcVideoAttribute = (editor: Editor): void => {
  const videoComponents = editor.getWrapper()?.findType('video');
  if (videoComponents) {
    videoComponents.forEach((videoComponent) => {
      if (videoComponent.attributes.attributes?.src !== undefined) {
        const videoUrl = String(videoComponent.attributes.attributes?.src);
        videoComponent.attributes.src = videoUrl;
        videoComponent.attributes.videoId = getVideoId(videoUrl);
        videoComponent.removeAttributes(['src']);
      }
    });
  }
};
