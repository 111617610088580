import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 0 0 2rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & button + button {
    margin-left: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 0.875rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: 0.5rem 1rem 1rem 1rem;
`;

export const TextLimit = styled.p`
  font-size: 0.77rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
`;

export const TextLimitContainer = styled.div`
  display: flex;
`;
