import { type FormEvent, type FormEventHandler, type ReactNode } from 'react';

import {
  BasePopover,
  Button,
  type IBasePopoverProps,
} from '@gbs-monorepo-packages/common';

import {
  AcceptIcon,
  ButtonsContainer,
  IconContainer,
  TextContent,
  Title,
} from './styles';

export interface IFormPopoverProps extends Partial<IBasePopoverProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  onAccept?: FormEventHandler<HTMLFormElement>;
  trigger: ReactNode;
}

export const FormPopover = ({
  acceptText,
  children,
  dataCy = 'form-popover',
  disabled = false,
  loading = false,
  mainText,
  onAccept,
  ...props
}: IFormPopoverProps): JSX.Element => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAccept?.(e);
  };

  return (
    <BasePopover dataCy={dataCy} {...props}>
      <TextContent data-cy="text-container">
        <Title data-cy={mainText}>{mainText}</Title>
      </TextContent>
      <form onSubmit={handleSubmit}>
        {children}

        <ButtonsContainer>
          {onAccept && (
            <Button
              dataCy="button-add"
              disabled={disabled}
              loading={loading}
              noPaddingRight={!!acceptText}
              type="submit"
            >
              {acceptText}
              <IconContainer>
                <AcceptIcon />
              </IconContainer>
            </Button>
          )}
        </ButtonsContainer>
      </form>
    </BasePopover>
  );
};
