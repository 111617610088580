import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import { HighUserRoles } from '@gbs-monorepo-packages/common';

import { COURSES, DASHBOARD, HOME, LOGIN } from '../../constants/RoutePaths';
import { useCan } from '../../hooks/useCan';
import { useCompanies } from '../../hooks/useCompanies';
import { getRouteFrom } from '../../utils/getRoutes';
import { Container, Loading } from './styles';

export const Redirect = (): JSX.Element => {
  const navigate = useNavigate();
  const userCanSeeDashboard = useCan({ roles: HighUserRoles });
  const { selectCompanyByID } = useCompanies();
  const { user } = useAuth();

  useEffect(() => {
    const checkWhereToGo = () => {
      switch (userCanSeeDashboard) {
        case true:
          navigate(getRouteFrom(DASHBOARD));
          break;

        case null:
          navigate(getRouteFrom(HOME));
          break;

        default:
          if (user?.companyId == null) {
            navigate(getRouteFrom(LOGIN));
            return;
          }
          void selectCompanyByID(String(user?.companyId))
            .then(() => {
              navigate(
                generatePath(getRouteFrom(COURSES), {
                  companyId: String(user?.companyId),
                })
              );
            })
            .catch(() => {
              navigate(getRouteFrom(LOGIN));
            });
          break;
      }
    };

    checkWhereToGo();
  }, []);

  return (
    <Container data-cy="loading-container">
      <Loading />
    </Container>
  );
};
