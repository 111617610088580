import {
  type FormEvent,
  type FormEventHandler,
  type MouseEventHandler,
} from 'react';

import {
  BaseModal,
  Button,
  type IBaseModalProps,
} from '@gbs-monorepo-packages/common';

import { ButtonsContainer, Form, TextContent, Title } from './styles';

export interface IFormPaginationModalProps extends Partial<IBaseModalProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  vhHeight?: string;
  onAccept?: FormEventHandler<HTMLFormElement>;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const FormPaginationModal = ({
  acceptText,
  children,
  dataCy = 'form-modal',
  declineText,
  disabled = false,
  loading = false,
  mainText,
  vhHeight,
  onAccept,
  onDecline,
  ...props
}: IFormPaginationModalProps): JSX.Element => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAccept?.(e);
  };

  return (
    <BaseModal loading={loading} dataCy={dataCy} {...props} vhHeight={vhHeight}>
      <TextContent data-cy="text-container">
        <Title data-cy={mainText}>{mainText}</Title>
      </TextContent>
      <Form onSubmit={handleSubmit}>
        {children}

        <ButtonsContainer>
          {declineText && (
            <Button
              type="button"
              dataCy="button-cancel"
              styleType="outline"
              onClick={onDecline}
              loading={loading}
              disabled={loading}
            >
              {declineText}
            </Button>
          )}
          {acceptText && (
            <Button
              type="submit"
              dataCy="button-add"
              loading={loading}
              disabled={disabled}
            >
              {acceptText}
            </Button>
          )}
        </ButtonsContainer>
      </Form>
    </BaseModal>
  );
};
