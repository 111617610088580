import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BaseLoading, Button } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 1.875rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: ${colors.white300};
  border-radius: ${radii.lg};
  border: 1px solid ${colors.inputBorderLight};
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }

  @media (max-width: 337px) {
    width: 100%;
  }
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: ${radii.xl};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const CompanyCard = styled.div`
  background-color: ${colors.white300};
  border-radius: ${radii.md};
  box-shadow: ${colors.blackTranslucent250} 0px 2px 8px;
  margin: 0.875rem 0;
  scroll-snap-align: start;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const CompanyName = styled.div`
  color: ${colors.gray500};
  font-size: 22px;
  font-weight: 500;
  padding: 20px;
`;

export const ButtonUrl = styled(Link)`
  align-items: center;
  border-radius: ${radii.xs};
  color: ${colors.text};
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: rgba(37, 40, 45, 0.04);
  }
`;

export const ButtonDelete = styled(Button)`
  color: #b1b1b1;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: -2px;
`;

export const ButtonsContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  padding: 0 0.5rem 0.5rem;
  flex-wrap: wrap;
`;
