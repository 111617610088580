import pathNode from 'path-browserify';
export interface IRoutePath {
  path: string;
  prefixPath: string;
}

export const getRouteFrom = ({ prefixPath, path }: IRoutePath): string =>
  pathNode.join(prefixPath, path);

export const getRoutesFrom = (routePath: IRoutePath[]): string[] => {
  return routePath.map(({ prefixPath, path }) =>
    pathNode.join(prefixPath, path)
  );
};
