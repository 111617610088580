import { Text, TextContainer } from './styles';

export interface ICenteredTextProps {
  dataCy?: string;
  message?: string;
}

export function CenteredText({
  dataCy = 'select-field',
  message = 'Text message',
}: ICenteredTextProps): JSX.Element {
  return (
    <TextContainer data-cy={dataCy}>
      <Text>{message}</Text>
    </TextContainer>
  );
}
