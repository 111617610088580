import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../../interfaces';
import { DefaultMobileOnlyColumn } from './components/DefaultMobileOnlyColumn';

export const mobileOnlyColumnId = 'mobile-only-column';
export const mobileOnlyColumnClass = 'mobile-only-column';

const MobileOnlyColumnPlugin = (editor: Editor): void => {
  const MobileOnlyColumnContent: IBlockContent = {
    attributes: { class: '' },
    content: '',
    editable: true,
    tagName: 'div',
  };

  const CenterContainer: IBlockContent = {
    ...DefaultMobileOnlyColumn([MobileOnlyColumnContent]),
    editable: true,
  };

  editor.BlockManager.add(mobileOnlyColumnId, {
    category: 'Responsive',
    content: CenterContainer,
    label: 'Mobile Only Column',
    media:
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" version="1.1"> <g id="surface1"> <path style=" stroke:none;fill-rule:nonzero;fill:currentcolor;fill-opacity:1;" d="M 4.332031 43.332031 L 47.667969 43.332031 L 47.667969 8.667969 L 4.332031 8.667969 Z M 2.167969 43.332031 L 2.167969 8.667969 C 2.167969 7.46875 3.136719 6.5 4.332031 6.5 L 47.667969 6.5 C 48.863281 6.5 49.832031 7.46875 49.832031 8.667969 L 49.832031 43.332031 C 49.832031 44.53125 48.863281 45.5 47.667969 45.5 L 4.332031 45.5 C 3.136719 45.5 2.167969 44.53125 2.167969 43.332031 Z M 2.167969 43.332031 "/> <path style=" stroke:none;fill-rule:nonzero;fill:currentcolor;fill-opacity:1;" d="M 37.289062 19.914062 L 50.4375 19.914062 C 51.125 19.914062 51.683594 20.25 51.683594 20.667969 L 51.683594 47.851562 C 51.683594 48.269531 51.125 48.605469 50.4375 48.605469 L 37.289062 48.605469 C 36.601562 48.605469 36.046875 48.269531 36.046875 47.851562 L 36.046875 20.667969 C 36.046875 20.25 36.601562 19.914062 37.289062 19.914062 Z M 37.289062 19.914062 "/> <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 38.050781 20.960938 L 49.820312 20.960938 C 50.4375 20.960938 50.933594 21.265625 50.933594 21.648438 L 50.933594 46.464844 C 50.933594 46.84375 50.4375 47.152344 49.820312 47.152344 L 38.050781 47.152344 C 37.433594 47.152344 36.9375 46.84375 36.9375 46.464844 L 36.9375 21.648438 C 36.9375 21.265625 37.433594 20.960938 38.050781 20.960938 Z M 38.050781 20.960938 "/> </g> </svg>',
    select: true,
  });
};

export default MobileOnlyColumnPlugin;
