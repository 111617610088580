import {
  MdAdd,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md';
import styled, { css } from 'styled-components';

import { BaseLoading } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
`;

export const MainContainer = styled.main`
  display: flex;
  flex: 1;
`;

export const PagesSection = styled.section`
  align-items: center;
  background-color: ${colors.gray100};
  border-radius: ${radii.sm};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0.75rem 0.375rem;
  padding: 0.5rem 0.75rem;
`;

export const PagesSectionHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  width: 100%;
`;

export const PagesSectionHeaderText = styled.h3`
  color: ${colors.textLight};
  font-size: 1.125rem;
  font-weight: 400;
`;

export const AddPageButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  margin-left: 0.25rem;
  padding: 0.375rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const AddIcon = styled(MdAdd)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SearchPageInput = styled.input`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  width: 100%;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
`;

export const CardContainer = styled.div`
  & > a + a {
    margin-top: 0.5rem;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-basis: 50vh;
  flex-direction: column;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    // FIXME: replace radius by constants

    border-radius: 10px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
  &:not(:hover) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
  }
`;

export const LoadingCoursePagesCardsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 12.5rem;
`;

export const LoadingCoursePagesCards = styled(BaseLoading)`
  height: 2rem;
  width: 2rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 8vw;
  max-width: 8vw;
  white-space: nowrap;
`;

export const CardName = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardType = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardExtraContent = styled.div`
  display: flex;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 0.5rem;
  width: 2.75rem;

  & > button:hover {
    background-color: ${colors.backgroundHoverLight};
  }
`;

export const CheckContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  width: 2.75rem;
`;

export const MainContent = styled.div`
  height: 100%;
  width: 100%;
  min-width: 1000px;
  overflow: hidden;
`;

export const LoadingCoursePagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
`;

export const LoadingCoursePages = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const NoPagesContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const NoPagesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const LabelCheckBox = styled.label`
  color: ${colors.labelText};
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: hidden;
  padding: 1px 6px;
  user-select: none;
`;

export const FieldsetCheckBox = styled.fieldset`
  all: unset;
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  min-height: 1.5rem;
`;

export const Label = styled.label`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const LockButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.labelText};
  display: inline-flex;
  font-size: 1rem;
  height: 3.25rem;
  justify-content: center;
  width: 2.75rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;

export const GlobalTemplateCheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.25rem;
`;

export const GlobalTemplateCheckboxLabel = styled.label`
  margin: 0 0.5rem;
`;
