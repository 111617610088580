import { type CanvasSpot, type Editor } from 'grapesjs';
import { type MouseEvent } from 'react';

import { useSpot } from '../hooks';
import {
  AddIcon,
  SpotAddRowAboveButtonContainer,
  SpotAddRowBelowButtonContainer,
} from './styles';

export interface ITableRowSpotProps {
  editor?: Editor;
  spot?: CanvasSpot;
}

export const TableRowSpot = (_props: ITableRowSpotProps): JSX.Element => {
  const { appendRow } = useSpot();

  const addRowAbove = ({ ctrlKey }: MouseEvent): void => {
    appendRow({ addBefore: true, isolateAction: ctrlKey });
  };

  const addRowBelow = ({ ctrlKey }: MouseEvent): void => {
    appendRow({ addBefore: false, isolateAction: ctrlKey });
  };

  return (
    <>
      <SpotAddRowAboveButtonContainer
        data-cy="add-row-above-button"
        onClick={addRowAbove}
      >
        <AddIcon />
      </SpotAddRowAboveButtonContainer>
      <SpotAddRowBelowButtonContainer
        data-cy="add-row-below-button"
        onClick={addRowBelow}
      >
        <AddIcon />
      </SpotAddRowBelowButtonContainer>
    </>
  );
};
