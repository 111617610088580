import styled from 'styled-components';

export const DefaultContentEditable = styled.input`
  all: unset;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
`;
