import { MdAdd } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

const SpotButtonStyle = css`
  background-color: transparent;
  border: none;
  border-radius: ${radii.px};
  color: transparent;
  cursor: pointer;
  display: flex;
  padding: 2px 4px;
  pointer-events: auto;
  position: absolute;

  &::before {
    content: '';
    background-color: ${colors.grapesHighlight};
    border-radius: ${radii.full};
    height: 0.5rem;
    left: 50%;
    position: absolute;
    top: 50%;
    translate: -50% -50%;
    width: 0.5rem;
  }

  &:hover {
    background-color: ${colors.grapesHighlight};
    color: ${colors.white300};

    &::before {
      display: none;
    }
  }
`;

export const SpotAddRowAboveButtonContainer = styled.span`
  left: 50%;
  top: -0.75rem;
  translate: -50% -50%;

  ${SpotButtonStyle};
`;

export const SpotAddRowBelowButtonContainer = styled.span`
  left: 50%;
  bottom: -0.75rem;
  translate: -50% 50%;

  ${SpotButtonStyle};
`;

interface ISpotAddCellProps {
  $targetIsEmpty: boolean;
}

export const SpotAddCellButtonContainer = styled.span<ISpotAddCellProps>`
  left: 50%;
  top: 50%;
  translate: -50% -50%;

  ${SpotButtonStyle};

  ${({ $targetIsEmpty = false }) =>
    !$targetIsEmpty &&
    css`
      display: none;
    `};
`;

export const AddIcon = styled(MdAdd)`
  height: 0.875rem;
  width: 0.875rem;
`;
