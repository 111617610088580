import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type ColorResult } from 'react-color';

import { useCourse } from '../../hooks/useCourse';
import {
  ColorPickerContainer,
  ColorPickerIcon,
  ColorPreview,
  CustomChromePicker,
  ErrorMessage,
  ErrorMessageContainer,
  Fieldset,
  Label,
  SelectDataCustom,
  Square,
} from './styles';

interface IColorPickerSelectDataProps {
  color: string;
  customInput?: (handleColorChange: (hex: string) => void) => ReactNode;
  customPreview?: ReactNode;
  dataCy: string;
  errorMessage?: string;
  label: string;
  onChange: (color: string, colorKey: string | null) => void;
  colorKey?: string | null;
}

export const ColorPickerSelectData = ({
  color,
  customInput,
  customPreview,
  dataCy,
  errorMessage,
  label,
  onChange,
  colorKey = null,
}: IColorPickerSelectDataProps): JSX.Element => {
  const { selectedCourse } = useCourse();
  const [error, setError] = useState<string>('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  const [hexColorValue, setHexColorValue] = useState(color);

  const handleColorChange = useCallback(
    (newColor: ColorResult) => {
      onChange(newColor.hex, colorKey);
      setHexColorValue(newColor.hex);
    },
    [colorKey, onChange]
  );

  const handleParagraphHexChange = (hex: string) => {
    if (hex.length <= 7) {
      setHexColorValue(hex);
      if (/^#([A-Fa-f0-9]{6})$/i.test(hex)) {
        onChange(hex, colorKey);
        setError('');
      } else {
        setError('Invalid color (Example: #000000)');
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node | null)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const globalStyle = selectedCourse?.globalStyle;

  const convertColorsArrayToObject = (
    colorsArray: string[]
  ): Record<string, string> => {
    return colorsArray.reduce<Record<string, string>>((acc, color) => {
      acc[color] = color;
      return acc;
    }, {});
  };

  const colorPickerSelectDataDefault = {
    colorPicker: 'Custom Color Picker',
    ...(selectedCourse &&
      globalStyle?.templateColors?.primaryColor !== '' && {
        primaryColorTemplate: 'Template Primary Color',
        secondaryColorTemplate: 'Template Secondary Color',
        backgroundColorTemplate: 'Template Background Color',
        accentColorTemplate: 'Template Accent Color',
      }),
    ...(selectedCourse &&
      Array.isArray(globalStyle?.templateColors?.additionalColors) &&
      convertColorsArrayToObject(
        globalStyle?.templateColors?.additionalColors as string[]
      )),
  };

  const [selectDataColors, setSelectDataColors] = useState(
    colorPickerSelectDataDefault
  );

  useEffect(() => {
    if (/^#([A-Fa-f0-9]{6})$/i.test(hexColorValue)) {
      onChange(hexColorValue, colorKey);
      setError('');
      setSelectDataColors((prevSelectDataColors) => ({
        ...prevSelectDataColors,
        colorPicker: hexColorValue,
      }));
    } else {
      setError('Invalid color (Example: #000000)');
    }
  }, [hexColorValue]);

  const handleSelectDataColor = useCallback(
    (value: string) => {
      if (value.startsWith('#')) {
        setHexColorValue(value);
      } else {
        const templateKey: string = value.replace('Template', '');
        // @ts-expect-error: value is a string and exists in templateColors
        setHexColorValue(String(globalStyle?.templateColors?.[templateKey]));
      }
    },
    [globalStyle]
  );

  const colorPickerData = Object.entries(selectDataColors).map(
    ([key, value]) => {
      let color;
      if (key.startsWith('#')) {
        color = value;
      } else {
        const templateKey = key.replace('Template', '');
        color = String(
          // @ts-expect-error: templateKey is a string and exists in templateColors
          globalStyle?.templateColors?.[templateKey] ?? hexColorValue
        );
      }
  
      const isSelected = (color === hexColorValue) && !value.includes('Template');
      
      return {
        key,
        value: (
          <>
            {!isSelected && <Square color={color} />} 
            {value}
          </>
        ),
        color,
      };
    }
  );

  return (
    <>
      {showColorPicker && (
        <div data-cy={`${dataCy}-picker-container`} ref={colorPickerRef}>
          <CustomChromePicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChange}
            disableAlpha
          />
        </div>
      )}
      <ColorPickerContainer>
        {customPreview ?? <ColorPreview style={{ backgroundColor: color }} />}

        <Fieldset
          data-cy={`field-set`}
          filled={!!color}
          isInvalid={!!(error || errorMessage)}
        >
          {customInput
            ? customInput(handleParagraphHexChange)
            : selectDataColors.colorPicker && (
                <SelectDataCustom
                  data={colorPickerData}
                  data-cy={`${dataCy}-input`}
                  onValueChange={handleSelectDataColor}
                  value={'colorPicker'}
                  zIndex={10}
                  required
                  name="colorPicker"
                />
              )}

          <Label data-cy={`${dataCy}-label`}>{label}</Label>
          <ColorPickerIcon
            onClick={() => {
              setShowColorPicker(!showColorPicker);
            }}
          />
        </Fieldset>
      </ColorPickerContainer>
      {(error || errorMessage) && (
        <ErrorMessageContainer>
          <ErrorMessage data-cy={error || errorMessage}>
            {error || errorMessage}
          </ErrorMessage>
        </ErrorMessageContainer>
      )}
    </>
  );
};
