import styled from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const LayoutConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 30vh;
  overflow: auto;
  padding: 0.625rem;

  ${scrollbar}
`;

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.625rem;
`;

interface ICellProps {
  $isSelected: boolean;
}

export const Cell = styled.button<ICellProps>`
  all: unset;
  background-color: ${({ $isSelected }) =>
    $isSelected ? colors.lightGray300 : colors.lightGray100};
  border-radius: ${radii.px};
  flex-grow: 1;

  &:hover {
    filter: brightness(0.9);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
