import type { Component, Editor, ToolbarButtonProps } from 'grapesjs';

import {
  canvasIsFocussed,
  runCommandIfCanvasFocussed,
  stopPropagationFirstEvent,
} from '../../../../utils/grapes';

export const idSelectChildren = 'tlb-select-children';
export const cmdSelectChildren = 'select-children';
const shortcutSelectChildren = '⌘+shift+d, ctrl+shift+d';

export const handlerSelectChildren = (editor: Editor): void => {
  runCommandIfCanvasFocussed(cmdSelectChildren, editor);
};

export const addSelectChildrenButtonToolbar = (
  component: Component
): ToolbarButtonProps[] => {
  const children = component.components();
  if (
    children.length &&
    children.models.some((comp) => comp.get('selectable'))
  ) {
    return [
      {
        id: idSelectChildren,
        label:
          '<svg viewBox="0 0 24 24"><path fill="currentColor" d="m 11,4.16 h 2 v 12 l 5.5,-5.5 1.42,1.42 L 12,20 4.08,12.08 5.5,10.66 11,16.16 Z" /></svg>',
        command: handlerSelectChildren,
        attributes: { title: 'Select children' },
      },
    ];
  }
  return [];
};

export const SelectChildrenPlugin = (editor: Editor): void => {
  const { Commands, Keymaps } = editor;
  Keymaps.add(cmdSelectChildren, shortcutSelectChildren, (editor, ...args) => {
    stopPropagationFirstEvent(args);

    handlerSelectChildren(editor);
  });

  Commands.add(cmdSelectChildren, {
    run(editor) {
      if (!canvasIsFocussed(editor)) return;
      const toSelect: Component[] = [];

      editor.getSelectedAll().forEach((selectedComponent) => {
        const children = selectedComponent.components();
        if (!children.length) return;

        for (const child of children.models) {
          if (child.get('selectable') ?? false) {
            toSelect.push(child);
          }
        }
      });

      toSelect.length && editor.select(toSelect);
    },
  });
};
