import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { type ICoursePageDTO } from '../../services/coursePages';
import {
  CloseButtonContainer,
  CloseIcon,
  CloseIconContainer,
  DialogContent,
  DialogOverlay,
  MainContent,
  SearchContent,
  SearchCourseHeader,
  SearchCourseInput,
  SearchCourseInputContainer,
  SearchIcon,
  SearchIconContainer,
  SearchItem,
  SearchItemContent,
  SearchItemContentContainer,
  SearchItemTitle,
  SearchItemTitleContainer,
  SearchResultsLabel,
  SearchResultsLabelContainer,
  SearchResultsList,
} from './styles';

export interface ISearchCourseModalProps {
  dataCy?: string;
  hiddenCloseButton?: boolean;
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  requestClose: () => void;
  trigger?: ReactNode;
  coursePages: ICoursePageDTO[];
  moveToPage: (pageNumber: number) => void;
}

interface ISearchResult {
  page: ICoursePageDTO;
  result: string;
}

export const SearchCourseModal = ({
  dataCy = 'search-course-modal',
  hiddenCloseButton = false,
  isOpen,
  onOpenChange,
  requestClose,
  trigger,
  coursePages = [],
  moveToPage,
}: ISearchCourseModalProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [displaySearchValue, setDisplaySearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);

  const handlerOnChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(escapeRegex(e.target.value));
    setDisplaySearchValue(e.target.value);
  };

  const escapeRegex = (string: string): string => {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const searchCoursePages = useCallback(
    (searchValue: string): void => {
      const resultPages: ISearchResult[] = [];
      coursePages.forEach((page) => {
        const strippedPage: string = page.htmlContent.replace(
          /(<([^>]+)>)/gi,
          ' '
        );
        const resultFound: number = strippedPage
          .toLowerCase()
          .search(searchValue.toLowerCase());

        if (resultFound !== -1) {
          let resultStart = 1;
          let resultEnd = 1;

          if (resultFound - 30 < 0) {
            resultStart = 1;
          } else {
            resultStart = resultFound - 30;
          }

          if (resultFound + 30 > strippedPage.length - 1) {
            resultEnd = strippedPage.length - 1;
          } else {
            resultEnd = resultFound + 30;
          }

          resultPages[page.id] = {
            page,
            result: strippedPage.substring(resultStart, resultEnd + 1),
          };
        }
      });

      setSearchResults(resultPages);
    },
    [coursePages]
  );

  useEffect(() => {
    if (displaySearchValue !== '') {
      searchCoursePages(searchValue);
    } else {
      clearSearchResults();
    }
  }, [displaySearchValue, searchCoursePages, searchValue]);

  const clearSearchResults = (): void => {
    setSearchResults([]);
    setSearchValue('');
    setDisplaySearchValue('');
  };

  return (
    <DialogPrimitive.Root
      data-cy="dialog-primitiveRoot"
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <DialogPrimitive.Trigger data-cy={trigger} asChild>
        {trigger}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal data-cy="dialog-primitivePortal">
        <DialogOverlay data-cy="overlay-search-course-modal" />
        <DialogContent data-cy={dataCy}>
          <MainContent data-cy="main-content">
            <SearchCourseHeader data-cy="search-courseHeader">
              <DialogPrimitive.Close
                data-cy="dialog-primitiveClose"
                asChild
                onClick={() => {
                  requestClose();
                  clearSearchResults();
                }}
              >
                {!hiddenCloseButton && (
                  <CloseButtonContainer data-cy="close-buttonContainer">
                    <CloseIconContainer
                      aria-label="Close"
                      data-cy="button-closeModal"
                    >
                      <CloseIcon />
                    </CloseIconContainer>
                  </CloseButtonContainer>
                )}
              </DialogPrimitive.Close>
              <SearchCourseInputContainer data-cy="searchCourse-input-container">
                <SearchIconContainer data-cy="searchIcon-container">
                  <SearchIcon />
                </SearchIconContainer>
                <SearchCourseInput
                  data-cy="input-searchResults"
                  placeholder="Search..."
                  value={displaySearchValue}
                  onChange={handlerOnChangeSearchValue}
                />
              </SearchCourseInputContainer>
            </SearchCourseHeader>
            <SearchResultsLabelContainer data-cy="container-searchResults">
              <SearchResultsLabel data-cy="text-searchResults">
                Search Results
              </SearchResultsLabel>
            </SearchResultsLabelContainer>
            <SearchContent data-cy="search-content">
              <SearchResultsList data-cy="searchResults-list">
                {searchResults.map((searchResult) => {
                  const { result, page } = searchResult;

                  return (
                    <SearchItem
                      data-cy={page.id}
                      key={page.id}
                      onClick={() => {
                        moveToPage(page.pageNumber - 1);
                        requestClose();
                        clearSearchResults();
                      }}
                    >
                      <SearchItemTitleContainer data-cy="searchItem-title-container">
                        <SearchItemTitle data-cy={page.title}>
                          {page.title}
                        </SearchItemTitle>
                      </SearchItemTitleContainer>
                      <SearchItemContentContainer data-cy="searchItem-container">
                        <SearchItemContent data-cy={result}>
                          ... {result} ...
                        </SearchItemContent>
                      </SearchItemContentContainer>
                    </SearchItem>
                  );
                })}
              </SearchResultsList>
            </SearchContent>
          </MainContent>
        </DialogContent>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};
