import { produce } from 'immer';
import {
  type MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { generatePath } from 'react-router-dom';

import {
  ABORT_REQUEST_REASON,
  DefaultDescription,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IRequestAbortController,
  type ISelectFilterItemProps,
  type ISelectFilterProps,
  type ISelectFilterValue,
  LIMIT_PAGE,
  TemplatesType,
  assertAbortReason,
  generateAbortRequest,
  getRouteFrom,
  isAbortError,
  useToast,
} from '@gbs-monorepo-packages/common';

import { TEMPLATE_VIEW } from '../../../../constants/RoutePaths';
import {
  type ITemplatePageDTO,
  getTemplatePagesByCompanyId,
} from '../../../../services/templates';
import Logger from '../../../../utils/logger';
import {
  ActionButtonContainer,
  ItemContainer,
  Loading,
  LoadingContainer,
  PreviewIcon,
  PreviewLink,
  SelectFilterCustom,
  SelectFilterItemCustom,
} from './styles';

const NotFoundIndex = -1 as const;
const NoExtraIndex = undefined;
type IExtraIndex = typeof NoExtraIndex | typeof NotFoundIndex;

const TemplateFromScratch = TemplatesType.BUILD_SCRATCH;
const TemplateRoute = getRouteFrom(TEMPLATE_VIEW);
const LimitPerPage = LIMIT_PAGE;

export interface ISelectTemplatesProps<T extends ITemplatePageDTO>
  extends Omit<
    ISelectFilterProps<T>,
    | 'children'
    | 'data'
    | 'hasMore'
    | 'next'
    | 'onValueChange'
    | 'pageSize'
    | 'value'
  > {
  clientId: number;
  dataCy?: string;
  loading?: boolean;
  onPreviewData?: (dataItem: T) => void;
  onValueChange?: (id: string, data: T) => void;
  templateOptionsId: number;
}

export const SelectTemplates = ({
  clientId,
  dataCy = 'select-template',
  loading = false,
  onPreviewData,
  onValueChange,
  templateOptionsId,
  ...props
}: ISelectTemplatesProps<ITemplatePageDTO>): JSX.Element => {
  const { addToast } = useToast();

  const [template, setTemplate] =
    useState<ISelectFilterValue<ITemplatePageDTO> | null>(null);
  const [templates, setTemplates] = useState<ITemplatePageDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const lastRequestAbort = useRef<IRequestAbortController | null>(null);
  const search = useRef('');
  const forcedSelectedTemplateIndex = useRef<IExtraIndex>();

  const handlePreviewData = (
    e: MouseEvent<HTMLAnchorElement>,
    dataItem: ITemplatePageDTO
  ) => {
    e.stopPropagation();
    onPreviewData?.(dataItem);
  };

  useEffect(() => {
    let mount = true;

    const fetchTemplates = async () => {
      setLoadingTemplates(true);
      try {
        const { data, meta } = await getTemplatePagesByCompanyId({
          clientId,
          filter: '',
          limit: LimitPerPage,
          page: INIT_PAGE,
          templateOptionsId,
        });
        if (mount) {
          setTemplate(null);
          forcedSelectedTemplateIndex.current = NoExtraIndex;
          setTemplates(data);
          setPaginationMeta(meta);
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting templates',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'fetch-templates-error-toast',
          duration: 3000,
        });
      } finally {
        setTimeout(() => {
          mount && setLoadingTemplates(false);
        }, 200);
      }
    };

    if (clientId && templateOptionsId !== TemplateFromScratch) {
      void fetchTemplates();
    }

    return () => {
      mount = false;
    };
  }, [templateOptionsId, clientId, addToast]);

  const handleValueChange = useCallback(
    (newValue: ISelectFilterValue<ITemplatePageDTO>) => {
      setTemplate(newValue);
      onValueChange?.(newValue.id.toString(), newValue);
    },
    [onValueChange]
  );

  const searchTemplates = useCallback(
    async (newFilter = false) => {
      let pageAux = 0;
      if (newFilter) {
        lastRequestAbort.current?.abort(ABORT_REQUEST_REASON);
      } else {
        if (lastRequestAbort.current?.avoidConcurrency ?? false) {
          Logger.info(
            'Skip new page request, because there is a new search request'
          );
          return;
        }
        pageAux = paginationMeta?.page ?? 0;
      }

      const newRequestAbort = generateAbortRequest(newFilter);
      lastRequestAbort.current = newRequestAbort;

      try {
        const { data: newPageData, meta } = await getTemplatePagesByCompanyId(
          {
            clientId,
            templateOptionsId,
            page: pageAux + 1,
            limit: LimitPerPage,
            filter: search.current ?? '',
          },
          {
            signal: newRequestAbort.signal,
          }
        );
        if (newFilter) {
          let addedExtraTemplate: IExtraIndex = NoExtraIndex;
          if (template?.id !== undefined) {
            const indexTemplate = newPageData.findIndex(
              ({ id }) => id === template.id
            );

            let newIndex = indexTemplate;
            if (indexTemplate === NotFoundIndex) {
              newPageData.push(template);
              addedExtraTemplate = NotFoundIndex;
              newIndex = newPageData.length - 1;
            }

            setTemplate(
              produce((draft) => {
                if (draft) draft.index = newIndex;
              })
            );
          }

          forcedSelectedTemplateIndex.current = addedExtraTemplate;
          setTemplates(newPageData);
        } else {
          const extraIndex = forcedSelectedTemplateIndex.current;
          if (extraIndex !== NoExtraIndex && template?.id !== undefined) {
            const index = newPageData.findIndex(({ id }) => id === template.id);

            let offsetSelection = newPageData.length;
            let deleteExtra = 0;
            if (index !== NotFoundIndex) {
              deleteExtra = 1;
              forcedSelectedTemplateIndex.current = NoExtraIndex;
              offsetSelection = index;
            }
            setTemplates(
              produce((draft) => {
                draft.splice(extraIndex, deleteExtra, ...newPageData);
              })
            );
            setTemplate(
              produce((draft) => {
                if (draft) {
                  draft.index = offsetSelection + (draft.index ?? 0);
                }
              })
            );
          } else {
            setTemplates(
              produce((draft) => {
                draft.push(...newPageData);
              })
            );
          }
        }
        setPaginationMeta(meta);
      } catch (error) {
        if (
          isAbortError(error) &&
          assertAbortReason(newRequestAbort, ABORT_REQUEST_REASON)
        ) {
          Logger.info(
            'Request was aborted, because there is a new search request'
          );
          return;
        }
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting templates',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'search-templates-error-toast',
          duration: 3000,
        });
      } finally {
        if (lastRequestAbort.current === newRequestAbort) {
          lastRequestAbort.current = null;
        }
        setLoadingTemplates(false);
      }
    },
    [addToast, clientId, paginationMeta?.page, template, templateOptionsId]
  );

  const handleSearch = useCallback(
    async (newSearch: string) => {
      search.current = newSearch;
      await searchTemplates(true);
    },
    [searchTemplates]
  );

  const loadingAux = loading || loadingTemplates;

  return loadingAux ? (
    <LoadingContainer data-cy="loading-templates-container">
      <Loading dataCy="loading-templates" />
    </LoadingContainer>
  ) : (
    <SelectFilterCustom
      placeholder="Select a template"
      searchPlaceholder="Search template"
      {...props}
      attribute="title"
      data={templates}
      dataCy={dataCy}
      hasMore={templates.length < (paginationMeta?.total ?? 0)}
      itemHeight={44}
      next={searchTemplates}
      onSearch={handleSearch}
      onValueChange={handleValueChange}
      pageSize={LimitPerPage}
      value={template}
    >
      {({ data, dataCy, text, value }) => (
        <SelectTemplateItem
          dataCy={dataCy}
          disabled={loading}
          text={text}
          value={value}
        >
          <PreviewLink
            data-cy="preview-link"
            onClick={(e) => {
              handlePreviewData(e, { ...data, title: text });
            }}
            target="_blank"
            title="Preview"
            to={generatePath(TemplateRoute, { templateId: value })}
          >
            <PreviewIcon />
          </PreviewLink>
        </SelectTemplateItem>
      )}
    </SelectFilterCustom>
  );
};

export interface ISelectTemplateItemProps
  extends Omit<ISelectFilterItemProps, 'children'> {
  children?: JSX.Element;
}

export const SelectTemplateItem = forwardRef<
  HTMLDivElement,
  ISelectTemplateItemProps
>(
  // eslint-disable-next-line no-restricted-syntax
  function SelectTemplateItem(
    { children, className, dataCy = 'select-template-item', style, ...props },
    ref
  ): JSX.Element {
    return (
      <ItemContainer
        className={className}
        data-cy={`${dataCy}-card`}
        style={style}
      >
        <SelectFilterItemCustom dataCy={dataCy} ref={ref} {...props} />
        <ActionButtonContainer data-cy="container-action-button">
          {children}
        </ActionButtonContainer>
      </ItemContainer>
    );
  }
);
