import { type ChangeEventHandler, type InputHTMLAttributes } from 'react';

import { Fieldset, InputSearch } from './styles';
export interface IFilterPaginationProps
  extends InputHTMLAttributes<HTMLInputElement> {
  search?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}
export const FilterPagination = ({
  search = '',
  placeholder = 'Search',
  onChange,
  disabled = false,
  ...props
}: IFilterPaginationProps): JSX.Element => {
  return (
    <Fieldset>
      <InputSearch
        placeholder={placeholder}
        value={search}
        type="text"
        onChange={onChange}
        data-cy={placeholder}
        disabled={disabled}
        {...props}
      />
    </Fieldset>
  );
};
