import { type Editor } from 'grapesjs';

import { SpotProvider } from './contexts';
import { TableCellSpot } from './TableCell';
import { TableRowSpot } from './TableRow';
import { TargetSpot } from './TargetSpot';

export interface ISpotHandlerProps {
  editor?: Editor;
}

export const SpotHandler = ({ editor }: ISpotHandlerProps): JSX.Element =>
  editor ? (
    <SpotProvider editor={editor}>
      {({ cellSpot, rowSpot, tableSpot }) => (
        <div
          id={`${String(tableSpot?.id)}-${String(cellSpot?.id)}`}
          data-cy="canvas-spots"
        >
          {cellSpot && (
            <TargetSpot
              dataCy="target-cell-spot"
              editor={editor}
              spot={cellSpot}
            >
              <TableCellSpot />
            </TargetSpot>
          )}
          {rowSpot && (
            <TargetSpot dataCy="target-row-spot" editor={editor} spot={rowSpot}>
              <TableRowSpot spot={rowSpot} />
            </TargetSpot>
          )}
        </div>
      )}
    </SpotProvider>
  ) : (
    <></>
  );
