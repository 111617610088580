import styled, { css } from 'styled-components';

import { FormModal } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const FormModalCustom = styled(FormModal)``;

export const Label = styled.label`
  background-color: ${colors.white300};
  color: ${colors.text};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  -webkit-box-align: center;
  align-items: center;
  background-color: ${colors.white300};
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  border-radius: ${radii.px} !important;
  &:focus-within {
    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }
  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};
          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};
          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  width: 100%;
  appearance: none;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  color: ${colors.text};
  -webkit-box-flex: 1;
  flex-grow: 1;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
`;

export const ErrorMessage = styled.p`
  font-size: 0.7rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const ContainerWithError = styled.div``;

export const RadioGroup = styled.div`
  margin-bottom: 10px;
`;

export const RadioLabel = styled.label`
  margin-left: 5px;
`;

export const RadioInput = styled.input`
  margin-right: 10px;
`;
