import { MdAdd } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const SpotResizeRightButtonContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 100%;
  translate: 50% -50%;
`;

const SpotAddColumnButtonStyle = css`
  background-color: transparent;
  border: none;
  border-radius: ${radii.px};
  color: transparent;
  cursor: pointer;
  display: flex;
  padding: 2px 4px;
  pointer-events: auto;
  position: absolute;

  &::before {
    content: '';
    background-color: ${colors.grapesHighlight};
    border-radius: ${radii.full};
    height: 0.5rem;
    left: 50%;
    position: absolute;
    top: 50%;
    translate: -50% -50%;
    width: 0.5rem;
  }

  &:hover {
    background-color: ${colors.grapesHighlight};
    color: ${colors.white300};

    &::before {
      display: none;
    }
  }
`;

export const SpotAddColumnLeftButtonContainer = styled.span`
  right: 100%;
  top: 50%;
  translate: -50% -50%;

  ${SpotAddColumnButtonStyle};
`;

export const SpotAddColumnRightButtonContainer = styled.span`
  left: 100%;
  top: 50%;
  translate: 50% -50%;

  ${SpotAddColumnButtonStyle};
`;

export const AddIcon = styled(MdAdd)`
  height: 0.875rem;
  width: 0.875rem;
`;
