import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
} from '@gbs-monorepo-packages/common';

import { api } from './api';

const baseApiDocuments = 'api/folders';

export interface IFolderDTO {
  id: number;
  name: string;
  typeFolder: number;
  subFolders: IFolderDTO[] | null;
  createdAt: string;
  updatedAt?: string;
  accountId: number;
}

export interface IPaginationFolderDTO {
  data: IFolderDTO[];
  meta: IPaginationMetaProps;
}

export interface IFolderDataDTO {
  data: IFolderDTO;
}

export interface IGetListFoldersByClientIdProps {
  clientId: string;
  page: number;
  limit: number;
  filter: string;
  search: string;
  sort: string;
}

export interface IGetFoldersByClientIdProps {
  clientId: string;
}

export type ICreateFolderProps = Pick<IFolderDTO, 'name' | 'typeFolder'> & {
  clientId: string;
  folderRef?: number;
  userId: number;
};

export type IUpdateFolderProps = Pick<IFolderDTO, 'id' | 'name' | 'typeFolder'>;
export type IDeleteFolderProps = Pick<IFolderDTO, 'id'>;
export type IGetFolderWithId = Pick<IFolderDTO, 'id'>;

export const getListFolderByClient = async ({
  clientId,
  page,
  limit,
  filter,
  search,
  sort,
}: IGetListFoldersByClientIdProps): Promise<IPaginationFolderDTO> => {
  const result = await api
    .get<IPaginationResponseData<IFolderDTO[]>>(
      `${baseApiDocuments}/client/${clientId}/list?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}&typeFolder=${encodeURIComponent(search)}&sorted=${sort}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getListFolderById = async ({
  id,
}: IGetFolderWithId): Promise<IFolderDataDTO> => {
  const result = await api
    .get<IFolderDataDTO>(`${baseApiDocuments}/${id}`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createFolder = async ({
  typeFolder,
  name,
  clientId,
  folderRef,
  userId,
}: ICreateFolderProps): Promise<IFolderDTO> => {
  const result = await api
    .post<IResponseData<IFolderDTO>>(`${baseApiDocuments}/client/${clientId}`, {
      typeFolder,
      name,
      folderRef,
      userId,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateFolder = async ({
  id,
  name,
  typeFolder,
}: IUpdateFolderProps): Promise<IFolderDTO> => {
  const result = await api
    .patch<IResponseData<IFolderDTO>>(`${baseApiDocuments}/${id}`, {
      typeFolder,
      name,
    })
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteFolder = async ({
  id,
}: IDeleteFolderProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${baseApiDocuments}/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
