import {
  BaseFullscreenLoader,
  BaseLoaderContainer,
  LoaderContainer,
} from './styles';

export interface IFullscreenLoaderParams {
  notLoading: boolean;
  dataCy?: string;
}

export const FullscreenLoader = ({
  notLoading = false,
  dataCy = 'fullscreen-loader',
}: IFullscreenLoaderParams): JSX.Element => {
  return (
    <>
      {notLoading || (
        <LoaderContainer>
          <BaseLoaderContainer>
            <BaseFullscreenLoader
              dataCy={dataCy}
              notLoading={notLoading}
            ></BaseFullscreenLoader>
          </BaseLoaderContainer>
        </LoaderContainer>
      )}
    </>
  );
};
