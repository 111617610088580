import styled from 'styled-components';

import { CheckBoxGroup, FormModal } from '@gbs-monorepo-packages/common';
import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

export const FormModalWide = styled(FormModal)`
  max-width: 800px;
`;

export const ExtraConfigsContainer = styled.div`
  align-items: center;
  color: ${colors.button};
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  justify-content: center;

  ${scrollbar}
`;

export const CheckBoxGroupCustom = styled(CheckBoxGroup)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
`;

export const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const InfoText = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
`;
