import type { Editor } from 'grapesjs';

import { addAddCellTableLayoutCommand } from './handlers/AddCellTableLayout';
import { addAddColumnTableLayoutCommand } from './handlers/AddColumnTableLayout';
import { addAddRowTableLayoutCommand } from './handlers/AddRowTableLayout';
import { addResizeCellTableLayoutCommand } from './handlers/ResizeCellTableLayout';

export const HandlerTablePlugin = (editor: Editor): void => {
  addAddCellTableLayoutCommand(editor);
  addAddColumnTableLayoutCommand(editor);
  addAddRowTableLayoutCommand(editor);
  addResizeCellTableLayoutCommand(editor);
};
